import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AdmobService } from './admob-service';
import { AppHelper } from './app-helper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private platform: Platform,
    private helper:AppHelper,
    ) { }
  public isAuthenticated(route_path): boolean {
    if ((this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb")) {
      this.helper.validateBannerAdsDisplay(route_path)
      return true
    }
    return AppHelper.isValidRoute(route_path);
  }
}
