import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController,ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProppyService } from '../api/proppy.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-shop-cart',
  templateUrl: './modal-shop-cart.page.html',
  styleUrls: ['./modal-shop-cart.page.scss'],
})
export class ModalShopCartPage implements OnInit {

  ShoppingCartItems: Observable<any>;
  totalCart: any = 0;
  email: string;
  
  
  constructor(private modalCtrl: ModalController, 
    private router: Router,
    public rest: ProppyService, 
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    private http: HttpClient,
    public storage: Storage) { }

  ngOnInit() {
    this.mLoadCart();
  }

  mLoadCart(){
    this.storage.get('Proppy_Email').then((result) => {
      this.email = result;
      this.getShoppingCartItems(this.email);
      this.getCartSubTotal(this.email);
    });
  }

  async getCartItemTotal(){
    let email: string = await this.storage.get('Proppy_Email');
    this.http.get(this.rest.apiProductsAddToCart + '?customerEmail=' + email + '&type=total').pipe(res => res).subscribe(data => {
      this.rest.CartItems = data[0]
    });
  }

  getShoppingCartItems(email){
    this.http.get<any>(this.rest.apiProductsAddToCart + '?customerEmail=' + email).subscribe(
    data => this.ShoppingCartItems = data,
    //error => this.error = error.message
    )
  }

  getCartSubTotal(email){
    this.http.get(this.rest.apiProductsAddToCart + '?customerEmail=' + email + '&type=subtotal').pipe(res => res).subscribe(data => {
      this.totalCart = data;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  deleteCartItem(itemid: number){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }

    return this.http.delete(this.rest.apiProductsAddToCart + '?id=' + itemid, httpOptions)
      .subscribe(data => {
        this.mLoadCart();
        this.rest.CartItems--;
      }, error => {
        this.presentToast('Could not remove item!')
      });

  }

  async decreaseCartItem(product) {
    let email: string = await this.storage.get('Proppy_Email');
    let username: string = await this.storage.get('username');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }

    if (product.Qty == 1){
      this.deleteCartItem(product.ID);
    } else {
      let body = {
        Datetime: new Date().toISOString(),
        ID:product.ID,
        CustomerEmail: email,
        CustomerName: username,
        ProductName: product.ProductName,
        ProductDescription: product.ProductDescription,
        ProductCode: product.ProductCode,
        Qty: product.Qty - 1,
        PriceUSD: product.PriceUSD - (product.PriceUSD / product.Qty)
      };
    
        return this.http.put(this.rest.apiProductsAddToCart+ '/' + product.ID, JSON.stringify(body), httpOptions)
          .subscribe(data => {
            this.mLoadCart();
           }, error => {
            this.presentToast('Could not add to shopping cart!');
          });
    }
  }
 
  async increaseCartItem(product) {
    let email: string = await this.storage.get('Proppy_Email');
    let username: string = await this.storage.get('username');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    let body = {
      Datetime: new Date().toISOString(),
      ID:product.ID,
      CustomerEmail: email,
      CustomerName: username,
      ProductName: product.ProductName,
      ProductDescription: product.ProductDescription,
      ProductCode: product.ProductCode,
      Qty: product.Qty + 1,
      PriceUSD: product.PriceUSD + (product.PriceUSD / product.Qty)
    };
  
      return this.http.put(this.rest.apiProductsAddToCart+ '/' + product.ID, JSON.stringify(body), httpOptions)
        .subscribe(data => {
          this.mLoadCart();
         }, error => {
          this.presentToast('Could not add to shopping cart!');
        });
  }

  async presentToast(error) {
    const toast = await this.toastCtrl.create({
      message: error,
      duration: 3000
    });
    toast.present();
  }

 

}
