import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiService } from 'src/app/services/api.service';
import { DataFetcherService } from 'src/app/services/data-fetcher/data-fetcher.service';

@Component({
  selector: 'app-ui-favourites',
  templateUrl: './ui-favourites.component.html',
  styleUrls: ['./ui-favourites.component.scss'],
})
export class UiFavouritesComponent implements OnInit {
  @Output() eventHandlerEmitter = new EventEmitter();
  @Input() ReadOnly: boolean = false
  @Input() favCount: boolean = false
  @Input() customInputData: any = {
    'class': '', 
    'fav_id': 0, 
    'object_id': '', 
    'object_type': '', 
    'user_id': '', 
    'fav_status': 0,
    'fav_count': 0, 
    's_img': '', 
    'us_img': '', 
    'width': 0, 
    'height': 0,
    'created_by':'',
    'color':'#000'
  }
  fav_load: boolean = false;

  constructor(
    private dataFetcher: DataFetcherService,
    private alert: AlertHandlerService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
   }

  setMyStyles() {
    let styles = {
      'color': this.customInputData['color'] ? (this.customInputData['color']=='NONE'?'':this.customInputData['color']) : '#000'
    }
    if (this.customInputData['width']) styles['width'] = this.customInputData['width'] + "px"
    if (this.customInputData['height']) styles['height'] = this.customInputData['height'] + "px"
    return styles;
  }

  onFavoriteClick() {
    this.fav_load = true;
    // if(this.customInputData['user_id'] == this.customInputData['created_by']){
    //   return this.fav_load = false;
    // }
  //  else 
   if (this.customInputData.hasOwnProperty('fav_id') && parseInt(this.customInputData['fav_id']) > 0) {
      let fav = ((this.customInputData['fav_status'] || 0) == 1 ? 0 : 1);
      let params = {
        'ID': this.customInputData['fav_id'],
        'Favourite': fav
      };
      this.addAndUpdateFav(2, params);
    } else {
      let params = {
        "Favourite": 1,
        "Type": this.customInputData['object_type'],
        "ListingID": this.customInputData['object_id'],
        "ProppyUserID": this.customInputData['user_id']
      }
      this.addAndUpdateFav(1, params);
    }
  }

  addAndUpdateFav(type: number, params: any) {
    /******
     * 1 - ADD
     * 2 - Update
     ******/
    let url = (type == 1 ? ApiService.ADD_PROPERTYMARKET_USER_FAVOURITE : ApiService.UPDATE_PROPERTYMARKET_USER_FAVOURITE);
    if ((url || '').length == 0) return this.fav_load = false;
    this.dataFetcher.doPost(url, params).subscribe(res => {
      const status = res['status'] || false;
      this.customInputData['status'] = status;
      if (status) {
        if (params.hasOwnProperty('ID') && parseInt(params['ID']) > 0) {
          this.customInputData['fav_status'] = params['Favourite'];
          if (this.customInputData.hasOwnProperty('fav_count'))
            this.customInputData['fav_count'] += (Number(params['Favourite']) == 1 ? +1 : -1);
        } else {
          this.customInputData['fav_id'] = res['insertedRow']['ID'];
          this.customInputData['fav_status'] = 1;
          if (this.customInputData.hasOwnProperty('fav_count'))
            this.customInputData['fav_count'] += 1;
        }
        this.customInputData['updated_Fav_status'] = 1
        this.eventHandlerEmitter.emit(this.customInputData);
        this.fav_load = false;
        this.ref.detectChanges();
      } else {
        this.customInputData['updated_Fav_status'] = 1
        this.eventHandlerEmitter.emit(this.customInputData);
        this.fav_load = false;
        this.alert.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
      }
    }, (error) => {
      this.fav_load = false;
      this.customInputData['updated_Fav_status'] = 1
      this.customInputData['status'] = false;
      this.eventHandlerEmitter.emit(this.customInputData);
      this.alert.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
    })
  }

}

