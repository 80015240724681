import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UicalendarPageRoutingModule } from './uicalendar-routing.module';

import { UicalendarPage } from './uicalendar.page';
import {CustomPipeModule} from '../utils/custom-pipe/custom-pipe.module'
import { UILabelModule } from '../uilabel/uilabel.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UicalendarPageRoutingModule,
    CustomPipeModule,
    UILabelModule
  ],
  declarations: [UicalendarPage],
  exports:[UicalendarPage]
})
export class UicalendarPageModule {}
