import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { DateHelper } from '../utils/date-helper.service';
import { UINewControl } from '../uicontrol';
// import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { UicalendarPage } from '../uicalendar/uicalendar.page';
import { FormStyles } from '../enums';
@Component({
  selector: 'app-uidate-picker',
  templateUrl: './uidate-picker.component.html',
  styleUrls: ['./uidate-picker.component.scss'],
})
export class UidatePickerComponent implements OnInit {
  // myDpOptions: IAngularMyDpOptions = {
  //   dateFormat: 'dd/mm/yyyy',
  //   appendSelectorToBody: true,
  //   showFooterToday: true,
  //   todayTxt: 'Today',
  // }
  @Input('date_picker_model') date_picker_model: UINewControl;
  @Input('time_picker_model') time_picker_model : UINewControl;
  @Output() eventHandlerEmitter = new EventEmitter();
  @ViewChild(IonDatetime, { static: false }) popoverDatetime: IonDatetime;
  openPopOver=false
  selectedDate : any= null
  selDateNgModel : any=null
  minDate=DateHelper.getYMD()
  maxDate=new Date().getFullYear()+10;
  currentYear = 0;
  form_styles = FormStyles
  constructor(private modalController:ModalController) {
    this.popoverDatetime
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['date_picker_model']){
      setTimeout(() => {
        var date = this.date_picker_model['postParams'][this.date_picker_model['elementFormPostKey']] || this.date_picker_model['elementSelectedVal'] || ''
        if(date.length>0){
          if(this.date_picker_model['pickerPresentation']=='time'){
          this.selectedDate=date
          this.selDateNgModel = this.selectedDate
          }else{
             this.selectedDate=DateHelper.convertDMYToYMD(date)
             this.selDateNgModel = this.selectedDate
          }
        }
      }, 1000);
    }
  }

  ngOnInit() {
    const date =  new Date().getFullYear();
    this.currentYear = date;
    if(this.date_picker_model['pickerPresentation']=='time'){
      const currTime = new Date().toLocaleTimeString().split(":")
      this.selDateNgModel= `${('0' + currTime[0]).slice(-2)}:${('0' +currTime[1]).slice(-2)}`
    } else {
      var tmpDt = this.date_picker_model['postParams']?(this.date_picker_model['postParams'][this.date_picker_model['elementFormPostKey']] || this.date_picker_model['elementSelectedVal'] || ''):(this.date_picker_model['elementSelectedVal'] || '')
      this.selDateNgModel=(tmpDt.length>0)?(DateHelper.convertDMYToYMD(tmpDt)):(DateHelper.getYMD())
    }
  }

  onChangeEvent(val: any) {     
    this.selectedDate=val
    if(this.date_picker_model['pickerPresentation']=='date'){
      this.date_picker_model.elementSelectedVal=DateHelper.convertYMDToDMY(val)
    } else if(this.date_picker_model['pickerPresentation']=='month-year'){
      this.date_picker_model.elementSelectedVal=DateHelper.convertYMDToMY(val)
    } else if(this.date_picker_model['pickerPresentation']=='time'){
      this.date_picker_model.elementSelectedVal= this.selectedDate//DateHelper.convertYMDToTime(val)
    }
    this.eventHandlerEmitter.emit(this.date_picker_model);
  }


  async openCalendar() {
    if(!this.date_picker_model['isEnabled']){
      return
    }
    var tmpObj = {"view_type":1}
    if(this.date_picker_model['pickerPresentation']=='time'){
      const tm = this.date_picker_model['elementSelectedVal'] || ""
      tmpObj["view_type"] = 2
      tmpObj["sel_time"] = {"hh":"","mm":"","time":tm}
    }else{
      this.selDateNgModel =  DateHelper.convertDMYToYMD(this.date_picker_model.elementSelectedVal)
      tmpObj['date_opts']={'min_dt':this.date_picker_model['defaultMin'] || '','max_dt':(typeof this.date_picker_model['defaultMax'] === 'string'?(this.date_picker_model['defaultMax'] || ''):'')}
      tmpObj["selected_date"] = this.selDateNgModel
    }
    const modal = await this.modalController.create({
      component: UicalendarPage,
      cssClass: 'modal-transparency',
      componentProps: tmpObj
    });
    modal.onDidDismiss().then((data) => {
      const tmpData = data['data'] || {}
      if(this.date_picker_model['pickerPresentation']=='time'){
        if(tmpData['time']){
          this.selDateNgModel = tmpData['time']
          this.onChangeEvent(this.selDateNgModel)
        }
      } else {
         if(tmpData['unix_date_format']){
          this.selDateNgModel = tmpData['unix_date_format']
          this.onChangeEvent(this.selDateNgModel)
        }
      }
    })
    return await modal.present();
  }
}
