import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { DisplayColDropdownPage } from 'src/app/reusables/display-col-dropdown/display-col-dropdown.page';
import { SelectResidentsTypeDropDownPage } from 'src/app/select-residents-type-drop-down/select-residents-type-drop-down.page';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ProCustomModalComponent } from '../pro-custom-modal/pro-custom-modal.component';
import { UINewControl } from '../uicontrol';

@Component({
  selector: "app-pro-cb-dropdown",
  templateUrl: "./pro-cb-dropdown.component.html",
  styleUrls: ["./pro-cb-dropdown.component.scss"],
})
export class ProCbDropdownComponent implements OnInit {
  @Input("cb_model") cb_model: UINewControl;
  @Input("cb_value") cb_value: any;
  @Input("cb_name") cb_name: any;
  // selected_states = {}
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor(
    private cd: ChangeDetectorRef,
    private modalController: ModalController,
    private alertController:AlertController,
    private alertHandeler:AlertHandlerService
  ) {}

  ngOnInit() {}
  ngOnChanges() {
    setTimeout(() => {
      this.cd.detectChanges();
    }, 1000);
    // console.log(this.cb_model)
    // const elementPostKey = this.cb_model['elementFormPostKey'] + "_CBD";
    // this.cb_model['elementCBDVal'] = this.cb_model['postParams'][elementPostKey] || this.cb_model['elementCBDVal'] || 0;
  }

  async openPopUp() {
    await this.alertHandeler.presentLoading()
    this.alertController.dismiss();
    let comp: any = SelectResidentsTypeDropDownPage;
    var dataParams = {};
    if (this.cb_model["elementFormPostKey"].toLocaleLowerCase() == "state") {
      comp = DisplayColDropdownPage;
      dataParams = {
        displayUsables: {
          display_arr: this.cb_model.elementDataArr,
          display_key: "Name",
          value_key: "Name",
          base_label:'',
          title:'State',
          default_label:this.cb_model['elementDataDefault'],
          selected_value:this.cb_model.elementSelectedVal
        },
        modalTag: "Name",
        selectedItems: [],
        isFromModal: true,
      };
    } else if (this.cb_model["elementFormPostKey"].toLocaleLowerCase().replace('_','') == "propertytype") {
      comp = SelectResidentsTypeDropDownPage;
      dataParams = {
        allResidents: this.cb_model.elementDataObj,
        modalTag: "PropertyTypeName",
        selectedItems: [],
        data:{selected_value:this.cb_model.elementSelectedVal,base_label:this.cb_model['elementDataDefault'],param_key: '', router_selector: '' },
        isFromModal: true,
      };
    } else {
      comp = ProCustomModalComponent;
      dataParams = {
        items:this.cb_model.elementDataArr,
        selectedItems: this.cb_model.elementSelectedValues,
        modalTitle: this.cb_model.elementText,
        titleKey: this.cb_model.elementDataKey,
        modalTag: this.cb_model.elementDataValKey,
        filterValKey: this.cb_model.elementDataValKey,
        defaultLabel:this.cb_model['elementDataDefault'],
        isMutiSelect: false,
        searchType:1
      };
    }
    const modal = await this.modalController.create({
      component: comp,
      componentProps: {
        ...dataParams
      },
    });
    await this.alertHandeler.dismissLoading();
    modal.onDidDismiss().then((data) => {
      var selectedData = data["data"] || {};
      if (selectedData["tag"]) {
        if(this.cb_model["elementFormPostKey"].toLocaleLowerCase() == "state" || this.cb_model["elementFormPostKey"].toLocaleLowerCase().replace('_','') == "propertytype"){
          this.cb_model["elementSelectedVal"] =selectedData["selectedItems"][selectedData["tag"]] || '';
          this.cb_model["elementSelectedValues"]=Object.keys(selectedData["selectedItems"] || {})?[selectedData["selectedItems"]]:[]
        } else {
          this.cb_model["elementSelectedVal"] =(selectedData['selectedItems'][0] || {})[selectedData["tag"]] || '';
          this.cb_model["elementSelectedValues"]=selectedData['selectedItems']
        }
        this.eventHandlerEmitter.emit(this.cb_model);
        // this.cb_model[selectedData['tag']] = selectedData['selectedItems'];
      }
    });
    return await modal.present();
  }


  onChangeEvent(itemVal = -1) {
    if (this.cb_model["elementDataType"] == 7) {
      var val = itemVal;
      if (this.cb_model.elementCBDVal == 0) {
        this.cb_model.elementCBDVal = {};
      }
      var cbVal =
        this.cb_model.elementCBDVal[itemVal] > 0
          ? this.cb_model.elementCBDVal[itemVal] == 1
            ? 2
            : 0
          : 1;
      var postParams = this.cb_model["postParams"];
      var elementFormPostKey = this.cb_model["elementFormPostKey"] || "";
      var selectedValues = this.cb_model["elementSelectedValues"] || [];
      if (postParams.hasOwnProperty(elementFormPostKey)) {
        selectedValues = postParams[elementFormPostKey] || [];
      }
      var isChecked =
        selectedValues.filter((f) => f == val).length > 0 ? false : true;
      if (isChecked) {
        selectedValues.push(val);
        selectedValues = Array.from(new Set(selectedValues));
      } else {
        selectedValues = selectedValues.filter((f) => f != val);
      }
      this.cb_model.elementCBDVal[itemVal] = cbVal;
      this.cb_model.elementSelectedValues = selectedValues;
      this.eventHandlerEmitter.emit(this.cb_model);
    } else {
      var val =
        this.cb_model["elementCBDVal"] > 0
          ? this.cb_model["elementCBDVal"] == 1
            ? 2
            : 0
          : 1;
      this.cb_model.elementCBDVal = val;
      this.eventHandlerEmitter.emit(this.cb_model);
    }
    this.cd.detectChanges();
  }
  onSelectChange(e) {
    var val = e.target.value;
    this.cb_model["elementSelectedVal"] = val
    this.eventHandlerEmitter.emit(this.cb_model);
  }
}
