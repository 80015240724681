import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { ModalController, NavController, Platform } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { StorageService } from '../services/storage-service.service';
import { LanguageService } from '../services/language.service';
import { deep_link_hostname } from 'src/environments/environment';
import { AppHelper } from "../services/middleware/app-helper";
import { DeeplinkHelperService } from '../services/middleware/deeplink-helper.service';

@Component({
  selector: "app-action-sheet-model",
  templateUrl: "./action-sheet-model.page.html",
  styleUrls: ["./action-sheet-model.page.scss"],
})
export class ActionSheetModelPage implements OnInit {
  scanSub: any;
  qrText: string;
  proppyUserID = "";
  propertyAgentID: string;
  showAgentActivation: boolean;

  constructor(public router: Router, private navCtrl:NavController
    , private modalController: ModalController
    , private translate:TranslateService
    , private qrScanner: QRScanner
    , private plt: Platform
    , private iab: InAppBrowser
    , private socialSharing: SocialSharing
    , private storageService:StorageService
    , public lanService:LanguageService,
    private deeplink_helper:DeeplinkHelperService,
    public helper:AppHelper) { }

  ngOnInit() {
    this.storageService.getString("Proppy_Agent_ID", (proppyAgentID) => {
      if (proppyAgentID != "") {
        this.propertyAgentID = proppyAgentID;
        this.showAgentActivation = false;
      } else {
        this.propertyAgentID = "";
        this.showAgentActivation = true;
      }
      console.log(this.propertyAgentID);
      console.log(this.showAgentActivation);
    });
  }

  btnNavClick(path) {
    this.dismiss();
    this.navCtrl.navigateForward([path]);
  }

  dismiss(role=null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    var params = {dismissed: true}
    if(role != null){
      params['role'] = role
    }
    this.modalController.dismiss(params);
  }

  navigateTo(path, params = {}) {
    if (Object.keys(params).length == 0) {
      this.navCtrl.navigateForward(path);
    } else {
      this.navCtrl.navigateForward(path, { queryParams: params });
    }
    this.dismiss();
  }

  shareSvgQRCodeImgData() {
      this.storageService.getString("Proppy_UserID",(proppyID)=>{
        this.deeplink_helper.processShareLink('MemberProfile',proppyID,(link)=>{
          var sMessage = 'Click to View My Profile at ProppyApp. '+link;
          // this.socialSharing.share(sMessage, sMessage, null, null);
          this.helper.socialShare(sMessage)
        })
      })
      this.dismiss();
  }

  mEndorseMe(){
    this.storageService.getString("Proppy_UserID",(proppyID)=>{
      this.deeplink_helper.processShareLink('AddEndorsement',proppyID,(link)=>{
        var sMessage = 'Click to Endorse Me at ProppyApp. '+link;
        this.socialSharing.share(sMessage, sMessage, null, null);
      },'Member/'+proppyID)
    })
    this.dismiss();
  }

  sendShare(message, subject) {
    this.storageService.getString("Proppy_UserID",(proppyID)=>{
      this.deeplink_helper.processShareLink('Registration',proppyID,(link)=>{
        this.socialSharing.share(message + ' Referral Code: ' + proppyID, subject + ' Referral Code: ' + proppyID, null, link);
      })
    })
    this.dismiss();
  }

  goToEarn(){
    this.navCtrl.navigateForward("/tabs/earn");
    this.dismiss();
  }

  btnAgentActivateClick() {
    this.router.navigate(["/tabs/agent-activation"]);
    this.dismiss();
  }

  btnAgentProfileClick() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        AgentId: this.propertyAgentID,
      },
    };
    this.navCtrl.navigateForward(["./tabs/property-agent"], navigationExtras);
    this.dismiss();
  }

  openQRScanner() {
    this.dismiss("OpenScanner")    
    return
    this.qrScanner.prepare().
      then((status: QRScannerStatus) => {
        if (status.scanning) {
          // Scanner active, close scanner
          document.querySelector('ion-app').classList.remove('cameraView');
          document.querySelector('ion-app').style.background='#e1e9ec'
          document.getElementById('homepage').style.visibility = "visible";
          document.getElementById('qr-button').style.visibility = "hidden";
          this.qrScanner.hide();
          this.qrScanner.destroy();
          this.scanSub.unsubscribe();
  
        }
        else if (status.authorized) {
          this.qrScanner.show();
          document.getElementById('homepage').style.visibility = "hidden";
          document.getElementById('qr-button').style.visibility = "visible";
          document.querySelector('ion-app').classList.add('cameraView');
          this.scanSub = this.qrScanner.scan()
            .subscribe((textFound: string) => {
              document.querySelector('ion-app').classList.remove('cameraView');
              // document.getElementsByTagName('ion-content')[0].style.opacity = '1';
              document.getElementById('homepage').style.visibility = "visible";
              document.getElementById('qr-button').style.visibility = "hidden";
              this.qrScanner.hide();
              this.qrScanner.destroy();
              this.scanSub.unsubscribe();
  
              this.qrText = textFound;
              //alert(this.qrText);
  
              //first check the string is our valid app link
              if (this.isValidAppLink(this.qrText)) {
                let applink = new URL(this.qrText);
                if(applink.toString().includes('AccessID')){
                  this.deeplink_helper.onAccessCapture(applink.toString().split('=')[1])
                  return;
                }
                let pathname = applink.pathname.replace(/^\/+/g, '');
                let approute = pathname.split('/');
          
                // Create our internal Router path by hand
                //`/${match.$route}/${match.$args['id']}`;
                let internalPath = ""; 
                if (approute[0]) {
                  internalPath = "/"+ approute[0];
                }
                if (approute[1]) {
                  internalPath = internalPath + "/"+ approute[1];
                }
          
                // Run the navigation in the Angular zone
                if (internalPath != "") {
                  this.router.navigateByUrl(internalPath); 
                }
              }
              //valid link that's not our app
              else if (this.isValidLink(this.qrText)) {
                //open the link in inAppBrowser
                this.openAppstoreEntry(this.qrText);
              }
              //any other type of data that we cannot handle atm
              else {
                console.log('invalid data: '+ this.qrText);
              }
  
            }, (err) => {
              //alert(JSON.stringify(err));
              console.log('error: '+ JSON.stringify(err));
            });
        } else if (status.denied) {
          if(confirm("Would you like to enable QR code scanning? You can allow camera access in your settings.")){
            this.qrScanner.openSettings();
          }            
        } else {
  
        }
      })
      .catch((e: any) => console.log('Error is', e));
    }

  isValidAppLink(string) {
    try {
      let url = new URL(string);
      return (
        url.protocol === "proppyapp:" ||
        (url.protocol === "https:" && url.hostname === deep_link_hostname)
      );
    } catch (error) {
      console.log("Error", error);
      return false;
    }
  }

  isValidLink(string) {
    try {
      let url = new URL(string);
      return true;
    } catch (error) {
      console.log("Error", error);
      return false;
    }
  }

  openAppstoreEntry(url = "") {
    const isAndroid = this.plt.is("android");
    //console.log("open:" + url);
    if (isAndroid) {
      window.open(url, "_system");
    } else {
      this.iab.create(url, "_system");
      // this.iab.open('http://whitelisted-url.com', '_system');
      // window.open(url, "_system");
    }
  }
  openForm(url) {
    window.open(url)
  }

}
