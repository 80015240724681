import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalShopCartPage } from './modal-shop-cart.page';

const routes: Routes = [
  {
    path: '',
    component: ModalShopCartPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalShopCartPageRoutingModule {}
