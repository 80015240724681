import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UilabelComponent } from './uilabel.component';
import { IonicModule } from '@ionic/angular';
@NgModule({
  declarations: [UilabelComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports:[UilabelComponent]
})
export class UILabelModule { }
