import { FormStyles, ProElementStyle, ProElementType, UIUnits } from "./enums";
import { UIAction, UIAlignment, UITextAlignment, UITextColor } from "./enums";

export class UIControl {
  public elementDataType: number = 1;
  public elementText: string;
  public elementTag: number = 0;
  public elementDataKey?: string = "";
  public elementDataValKey?: string = "";
  public elementFormPostKey?: string = "";
  public elementPlaceHolder?: string = "";
  public defaultStyle=0; 
  public elementVisitorCalStyle = 0 
  public elementIcon:string = ""
  public elementDataArr: any[] = [];
  public defaultMin: any = 0;
  public defaultMax: any = 10;
  public elementSelectedVal:any=""
  public elementSelectedValues: any[] = [];
  public isMandatory: boolean = false;
  public isSelected: boolean = false;
  public isEnabled: boolean = true;
  public isTextBold: boolean = false;
  public imageIcon:string = ""
  public elementSize:number = -1
  public elementStyle:string = ProElementStyle.PrimaryGradiant
  public elementType:number = ProElementType.Default
  public pickerPresentation:String='date'
  constructor(elementDataType, elementTag, elementText) {
    this.elementDataType = elementDataType;
    this.elementTag = elementTag;
    this.elementText = elementText;
  }
}
export class UINewControl {
  public elementDataType: number = 1;
  public elementText: string;
  public elementSubtext: string;
  public elementTag: number = 0;
  public elementDataKey?: string = "";
  public elementDataValKey?: string = "";
  public elementDataDefault?: string = "";
  public elementFormPostKey?: string = "";
  public elementPointerEvents?: number = 1;
  public elementPlaceHolder?: string = "";
  public maxSize?:number=0;
  public allowOnlyNumerics:number;
  public elementDataObj = {};
  public elementDataArr: any[] = [];
  public elementSelectedVal:any=""
  public elementSelectedValues: any[] = [];
  public isMandatory: boolean = false;
  public isSelected: boolean = false;
  public isEnabled: boolean = true;
  public elementStyle:string = "";
  public defaultStyle:number = 1;
  public elementRowClass:string=""
  public elementErrorClass:string=""
  public elementContentClass:string=""
  public elementIcon:string = ""
  public elementID:string =""
  public elementStatusIcon:string = ""
  public elementDateType1: any = ""
  public elementDateType2: any = ""
  public elementGridColSize:number = 0
  public elementGridDataType:number = 0
  public elementCBDVal:any = 0
  public elementTextColor: string = UITextColor.Black
  public textAlignment: string = UITextAlignment.Left
  public elementIconAlignMent:string = '';
  public actionEvent: any = UIAction.None;
  public defaultMin: any = 0;
  public defaultMax: any = 100;
  public selectedMin: any = 0;
  public selectedMax: any = 100;
  public postParams:any = {};
  public pickerPresentation:any='date'
  public UOM:any=UIUnits.Currency
  public popover_type : any 
  public elementDescription: string = "";
  public elementInputType:String = 'text';
  public elementMaxLength: any;
  public elementMinLength: any;
  public unSatisfactoryElementPostKeys:any = []
  public elementPattern:string = ""
  public elementErrMsgs:any[] = []
  constructor(elementDataType, elementTag, elementText) {
    this.elementDataType = elementDataType;
    this.elementTag = elementTag;
    this.elementText = elementText;
  }
}
export class UIGridView {
  public headerTitle = ""
  public elementModels = {};
  public alignment: string = UIAlignment.Left
  public gridCBDVal:any=0
  constructor(title) {
    this.headerTitle = title;
  }
}
export class UIGridViewElementModel {
  public elementModel:UINewControl
  public childElements:any = []
  public elementSize: number = 0
  public elementAlignment: string = UIAlignment.Left
}
