import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { ApiService } from './api.service';
// import { AppHelper } from './middleware/app-helper';

@Injectable({
  providedIn: "root",
})
export class AlertHandlerService {
  loading: HTMLIonLoadingElement;
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private router: Router,
    // public helper: AppHelper,
    private navCtrl: NavController,
    private plt : Platform,
  ) { }

  async presentAlert(msg, header = "Info") {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presenToast(msg,pos=0) {
    if(msg == 'isTrusted.'){
      return
    }
    var err = true;
    let selectedPosition = false
    if(pos>0){
      selectedPosition = true
    }
    if(msg && typeof(msg) === 'string'){
      err = msg.toLowerCase().includes("please");
    } else {
      msg=msg['error'] || ApiService.ERROR_TOAST_MSG
    }
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: selectedPosition || err ? "middle" : "bottom",
      color: selectedPosition || err ? "danger" : "light",
    });
    toast.present();
  }

  async presentConfirmationAlert(msg, cb) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: msg,
      buttons: [{ text: "Yes", handler: cb }, "No"],
    });

    await alert.present();
  }
  async confirmationAlert(msg, yes_cb,no_cb) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: msg,
      buttons: [{ text: "Yes", handler: yes_cb }, {text:"No",handler:no_cb}],
    });

    await alert.present();
  }
  async presentConfirmationAlertWithCustomButtons(msg, buttons) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: msg,
      buttons: buttons,
    });

    await alert.present();
  }

  async presentLoading(messageDisplay = "Please Wait...", type:any = 0) {
    const loading = await this.loadingController.create({
        message: messageDisplay
    });
    await loading.present();
    if (type == 0) {
        let alreadyDismissed = 0
        const ref = setTimeout(() => {
            alreadyDismissed = 1;
            loading.dismiss();
        }, 8000)
        loading.onDidDismiss().then(() => {
            if(alreadyDismissed == 0) {
                clearTimeout(ref);
            }
        })
    }
}

  async dismissLoading() {
    let topLoader = await this.loadingController.getTop();
    if (topLoader) {
      await topLoader.dismiss();
    }
  }

  loginOrRegisterAlert() {
    this.alertController
      .create({
        header: "Login Required",
        message: "Some features requires you to be logged in to ProppyApp!",
        buttons: [
          {
            text: "Go to Login",
            handler: () => {
              console.log("Go to Login");
              this.router.navigate(["/tabs/tab3"]);
            },
          },
          {
            text: "Go to Registration",
            handler: () => {
              console.log("Go to Registration");
              this.router.navigate(["/tabs/registration"]);
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  webShowRegisterPopup(SenderPID='PROPPY') {
    if (!((this.plt.is("ios") || this.plt.is("android")) && !this.plt.is("mobileweb"))) {
      setTimeout(async () => {
        const alert = await this.alertController.create({
          header: 'Register',
          cssClass: "register",
          message: 'Some features requires you to be registered and logged in to ProppyApp. Please proceed to the registration screen by clicking the Register button below.',
          buttons: [
            {
              text: 'Register',
              handler: () => {
                this.navCtrl.navigateForward('/registration/'+SenderPID)
              }
            },
            {
              text: 'Cancel'
            }
          ]
        });
        await alert.present();
      }, 3000);
    }
  }
}
