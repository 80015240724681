import { Component, OnInit } from '@angular/core';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-permission-modal',
  templateUrl: './permission-modal.page.html',
  styleUrls: ['./permission-modal.page.scss'],
})
export class PermissionModalPage implements OnInit {

  constructor(private navParams:NavParams,private openNativeSettings:OpenNativeSettings) { }

  ngOnInit() {
  }

  public onTryAgainClick(){
    // this.navParams.get('RetryFun')();
    this.openNativeSettings.open('application_details').then((val)=>{
      console.log(val)
      this.navParams.get('RetryFun')();
    },err=>{
      console.log(err)
    })
  }

  public closeModal(){
    this.navParams.get('RetryFun')();
  }
}
