import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-bump-popover',
  templateUrl: './bump-popover.component.html',
  styleUrls: ['./bump-popover.component.scss'],
})
export class BumpPopoverComponent implements OnInit {
  popoverMessageStatus:number = 1
  
  constructor(
    private mdCtrl: ModalController
  ) { }

  ngOnInit() {}

  dismiss(type:number=1) {
    this.mdCtrl.dismiss({messageStatus:this.popoverMessageStatus,status: type});
  }

  bumpPopover() {
    if(this.popoverMessageStatus) {
      this.popoverMessageStatus = 0
    }else {
      this.popoverMessageStatus = 1
    }
  }

}
