import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonsComponent } from './skeletons.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SkeletonsComponent],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [SkeletonsComponent]
})
export class SkeletonsModule { }
