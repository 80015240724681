import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormStyles, UIDatatypes } from '../enums';
import { UIControl } from '../uicontrol';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent implements OnInit {
  @Input("separator") separator: UIControl
  @Input() isCustom: boolean = false
  @Input() customInputData: any = { 'class': '', 'style': 'horizontal' }
  @Output() eventHandlerEmitter = new EventEmitter();
  ui_datatype: any = UIDatatypes;
  form_styles: any = FormStyles;

  constructor() { }

  ngOnInit() {}

}
