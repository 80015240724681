import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewRatingDetailsComponent } from './review-rating-details.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIImgModule } from '../uiimg/uiimg.module';
import { UILabelModule } from '../uilabel/uilabel.module';
import { ProButtonModule } from '../pro-button/pro-button.module';
import { ReviewRatingModule } from '../review-rating/review-rating.module';
import { SkeletonsModule } from '../skeletons/skeletons.module';



@NgModule({
  declarations: [ReviewRatingDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UIImgModule,
    UILabelModule,
    ProButtonModule,
    ReviewRatingModule,
    SkeletonsModule
  ],
  exports:[
    ReviewRatingDetailsComponent
  ]
})
export class ReviewRatingDetailsModule { }
