import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})

export class StorageService {
    constructor(private storage: Storage) { }

    async setString(key: string, value: string) {
        await this.storage.set(key, value);
    }

    async getString(key: string,cb) {
        await this.storage.get(key).then(val => {
            if (val != null) {
                cb(val);
            } else {
                cb('');
            }
        });
    }

    async setInt(key: string, value: Number) {
        await this.storage.set(key, value);
    }

    async getInt(key:string,cb) {
        await this.storage.get(key).then(val => {
            if (val != null) {
                cb(val);
            } else {
                cb('');
            }
        });
    }

    async setBoolean(key: string, value: boolean) {
        await this.storage.set(key, value);
    }

    async getBoolean(key:string,cb) {
        await this.storage.get(key).then(val => {
            if (val != null) {
                cb(val);
            } else {
                cb(false);
            }
        });
    }

    async setData(key: string, value: any) {
        await this.storage.set(key, JSON.stringify(value));
    }

    async addData(key: string, value: {}) {
        await this.getData(key,(data)=>{
            this.storage.remove(key);
            this.storage.set(key, JSON.stringify([...data,value]));
        })
    }

    async getData(key: string,cb) {
    await this.storage.get(key).then(val => {
            if (val != null && val.length > 0) {
                cb(JSON.parse(val));
            } else {
                cb(key.includes('arr')?[]:{});
            }
        });
    }

    async removeItem(key: string) {
        await this.storage.remove(key);
    }

    async clear() {
        await this.storage.clear();
    }

    setIonicDateTime(value: string): Date {
        if (value) {
            let date: Date = new Date(value);
            let ionicDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
            return ionicDate;
        }
        return null;
    }
    getIonicDateTime(value: Date): string {
        if (value) {
            let date: Date = new Date(value);
            let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            return ionicDate.toISOString();
        }
        return null;
    }

    getStorage(){
        return this.storage
    }
}