import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ConstantsService } from 'src/app/services/constants/constants.service';
import { DataFetcherService } from 'src/app/services/data-fetcher/data-fetcher.service';
import { StorageService } from 'src/app/services/storage-service.service';
import { ApiService} from '../../services/api.service'
import { AlertHandlerService } from 'src/app/services/alert-handler.service';

@Component({
  selector: 'app-display-col-dropdown',
  templateUrl: './display-col-dropdown.page.html',
  styleUrls: ['./display-col-dropdown.page.scss'],
})
export class DisplayColDropdownPage implements OnInit {
  displayUsables={};
  isFromModal = false;
  modalTag: string = ""
  objectConstructor = ({}).constructor;
  constructor(public apiService: ApiService,private alertHandeler:AlertHandlerService,private activatedRoute : ActivatedRoute,private location : Location,public navCtrl: NavController,private http:DataFetcherService,private storage:StorageService,private modalCtrl: ModalController) {
    if (!this.isFromModal) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.storage.getData(ApiService.DROP_DOWN_DATA_SEND_KEY, (data) => {
          if (Object.keys(data).length > 0) {
            this.displayUsables = { ...data, display_arr: JSON.parse(data['display_arr']) }
            this.storage.removeItem(ApiService.DROP_DOWN_DATA_SEND_KEY);
            if (this.displayUsables.hasOwnProperty('http_url') && this.displayUsables.hasOwnProperty('http_params')) {
              this.getItems();
            }
          }
        })
      });
    }
   }

  ngOnInit() {
    if(this.isFromModal) {
      console.log(this.displayUsables)
      if (this.displayUsables.hasOwnProperty('http_url') && this.displayUsables.hasOwnProperty('http_params')) {
        this.getItems();
      }
    }
  }

  onBackClick(){
    if(this.isFromModal){
      this.modalCtrl.dismiss({});
    } else {
      this.navCtrl.back();
    } 
  }
  async valueSelected(selectedValue){
  if (this.isFromModal) {
      var data = { "selectedItems": selectedValue, "tag": this.modalTag }
      this.modalCtrl.dismiss(data);
    } else {
    this.displayUsables['selected_value']=selectedValue.constructor===this.objectConstructor?selectedValue[this.displayUsables['value_key']]:selectedValue;
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     selected_value:this.displayUsables['selected_value'],
    //     param_key:this.displayUsables['param_key'],
    //     selected_obj:JSON.stringify(selectedValue)
    //   }
    // };
    await this.storage.setData(ApiService.DROP_DOWN_DATA_RESPONSE_KEY,{
      selected_value:this.displayUsables['selected_value'],
      param_key:this.displayUsables['param_key'],
      param_obj:this.displayUsables['param_obj'] || "{}",
      selected_obj:JSON.stringify(selectedValue)
    });
    this.navCtrl.navigateBack([this.displayUsables['router_selector']]);
    }
  }

  getItems(){
    this.http.doPost(this.displayUsables['http_url'],JSON.parse(this.displayUsables['http_params'])).subscribe(res=>{
      if(res.hasOwnProperty('status') && res['status']==true){
        this.displayUsables['display_arr']=res['data'];
      } else{
        this.alertHandeler.presentAlert("Can't get the data, Please Try again Later.")
      }
    });
  }
  dismiss() {        
    this.modalCtrl.dismiss({});
  }
}

