import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  UiChipComponent } from './ui-chip.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [UiChipComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports:[UiChipComponent]
})
export class UIChipModule { }
