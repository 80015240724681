import { Injectable } from '@angular/core';
import { Camera } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { ModalController } from '@ionic/angular';
import { PermissionModalPage } from '../modals/permission-modal/permission-modal.page';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private androidPermissions: AndroidPermissions,
    private modalController:ModalController,
    private locationAccuracy: LocationAccuracy) { }

  public  initiate(){
    this.checkGPSPermission();
    this.checkPermissions()
  }

  //Check if application having GPS access permission  
  checkGPSPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
      result => {
        if (result.hasPermission) {
          this.modalController.dismiss({
            'dismissed': true
          });
          //If having permission show 'Turn On GPS' dialogue
          this.askToTurnOnGPS();
        } else {
          //If not having permission ask for permission
          this.requestGPSPermission();
        }
      },
      err => {
        if(err['message']=='Illegal Access'){
          this.showGPSModel()
        }
      }
    );
  }

  async showGPSModel(){
    const modal = await this.modalController.create({
      component: PermissionModalPage,
      cssClass: 'modalalert',
      backdropDismiss: false,
      keyboardClose:true,
      componentProps: {
        "RetryFun": ()=>{
          // console.log('retry')
          // setTimeout(() => {
          //   this.checkGPSPermission()
          // }, 1000);
          this.modalController.dismiss({
            'dismissed': true
          });
        },
      }
    });
    await modal.present();
  }

  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log("4");
      } else {
        //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
          .then(
            () => {
              // call method to turn on GPS
              this.askToTurnOnGPS();
            },
            error => {
              //Show alert if user click on 'No Thanks'
              console.log('requestPermission Error requesting location permissions ' + error)
            }
          );
      }
    });
  }

  askToTurnOnGPS() {
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      () => {
        // When GPS Turned ON call method to get Accurate location coordinates
        //this.getLocationCoordinates()
      },
      error => console.log('Error requesting location permissions ' + JSON.stringify(error))
    );
  }

  checkPermissions(){
    Camera.requestPermissions()
    Filesystem.requestPermissions()
  }

}
