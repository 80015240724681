import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
// import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: "root",
})
export class DataFetcherService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private device: Device,
    private appVersion: AppVersion,
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      responseType: "json",
      "x-access-token": '',
    }),
  };

  httpTalkJSOptions = {
    headers: new HttpHeaders({
      "Authorization": 'Bearer '+environment.talk_js_bearer_api_key,
    }),
  };

  async setHttpOptions() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        responseType: "json",
        "x-access-token": await this.storage.getJWTToken(),
      }),
    };
  }

  doPost(url, body) {
    // if (url.includes(ApiService.BASE_NODE_URL)) {
    //   this.setHttpOptions();
    // }
    return this.http.post<any>(url, body, this.httpOptions);
  }

  doPostWithOutHttpHeaders(url, body) {
    return this.http.post<any>(url, body);
  }

  doPut(url, body) {
    // this.setHttpOptions();
    return this.http.put<any>(url, body, this.httpOptions);
  }

  doGet(url) {
    // this.setHttpOptions();
    return this.http.get<any>(url, this.httpOptions);
  }

  doGetWithOutHttpHeaders(url) {
    return this.http.get<any>(url);
  }

  doDelete(url) {
    // this.setHttpOptions();
    return this.http.delete<any>(url, this.httpOptions);
  }
  doPostDownloadFile(url,post_params) {
    return this.http.post(url,post_params, { responseType: "blob" });
  }
  doDownloadFile(url) {
    return this.http.get(url, { responseType: "blob" });
  }
  doCdnUploadRequest(api_path, body) {
    return this.http.post<any>(api_path, body);
  }
  addErrorLog(PageRoute, FunctionName,ErrorLog,ServiceUrl='',RequestPayload={}) {
    // this.appVersion.getVersionNumber().then(val=>{
    this.storage.getString("Proppy_UserID", (id) => {
      var params = {
        app_version_code: environment.app_version,
        device_model: this.device.model || "Desktop",
        device_uuid: this.device.uuid || "Desktop",
        device_platform: this.device.platform != null ? this.device.platform == "Android" ? 1 : 2 : 3,
        platform_version: this.device.version || "Desktop",
        proppy_user_id: id,
        page_route: PageRoute,
        function_name: FunctionName || 'Api',
        error_log: JSON.stringify(ErrorLog),
        service_url:ServiceUrl,
        request_payload:JSON.stringify(RequestPayload)
      }
      this.doPost(ApiService.ADD_ERROR_LOG,params).subscribe((res) => {
          console.log(res);
      });
    });
    // })
  }

  emailSender(
    toRecipient,
    subject,
    sourceType,
    propEmailTemplateCode,
    PID,
    body = "",
    cc = "",
    bcc = ""
  ) {
    var params = {
      toRecipient: toRecipient,
      subject: subject,
      body: body,
      cc: cc,
      bcc: bcc,
      sourceType: sourceType,
      propEmailTemplateCode: propEmailTemplateCode,
      parameter1: PID,
      parameter2: "",
      parameter3: "",
      parameter4: "",
      parameter5: "",
    };
    this.doPost(ApiService.ADD_EMAIL_QUEUE, params).subscribe((res) => {
      const status = res["Success"] || false;
      if (!status) {
        this.addErrorLog("DataFetcher Service", "emailSender",res,ApiService.ADD_EMAIL_QUEUE, params);
      }
    });
  }

  doTalkJsApiRequest(url){
    return this.http.get<any>(url, this.httpTalkJSOptions);
  }

  doTalkJsApiPostRequest(url,body){
    return this.http.post<any>(url,body,this.httpTalkJSOptions);
  }

  doTalkJsApiPutRequest(url,body){
    return this.http.put<any>(url,body,this.httpTalkJSOptions);
  }
}
