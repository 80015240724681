import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { AdmobService } from 'src/app/services/middleware/admob-service';
import { AppHelper } from 'src/app/services/middleware/app-helper';
import { faq_portal } from 'src/environments/environment';
import proppy_marketplace_wizard from 'src/assets/lang/proppy_marketplace_wizard.json'
@Component({
  selector: 'app-proppy-wizard',
  templateUrl: './proppy-wizard.page.html',
  styleUrls: ['./proppy-wizard.page.scss'],
})
export class ProppyWizardPage implements OnInit {
  proppy_marketplace_wizard_json = JSON.parse(JSON.stringify(proppy_marketplace_wizard))
  items = []
  showBannerAd=false
  constructor(
    private sanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    private iab: InAppBrowser,
    private helper:AppHelper
  ) { }

  ngOnInit() {
    this.items.map((item,pos)=>{
      if(item.hasOwnProperty('youtube_url')){
        this.getMediaContent(item['youtube_url'],pos)
      }
    })
    AdmobService.hideBanner()
    if(this.showBannerAd){
      AdmobService.showBanner(this.helper.getPlatformInstance())
    }
  }

  dismiss() {
    this.helper.validateBannerAdsDisplay()
    this.modalCtrl.dismiss();
  }

  btnFAQClick() {
    this.iab.create(faq_portal, '_blank');
  }

  youtube_parser(url) {
    var match = ''
    if(url.includes("v=")){
      match = url.split("v=")[1]
    }else{
      match = url.split("/")[3];
    }   
    return match && match.length == 11 ? match : false;
  }

  getMediaContent(url,pos) {
    var vID = this.youtube_parser(url);
    var youtube_url = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube-nocookie.com/embed/" + vID
    );
    this.items[pos].youtube_url=youtube_url;
  }
  openLink(item){
    window.open(item)
  }
}
