import { Component, OnInit } from '@angular/core';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { AppHelper } from 'src/app/services/middleware/app-helper';
import { ViewVideoPage } from 'src/app/view-video/view-video.page';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.page.html',
  styleUrls: ['./photo-viewer.page.scss'],
})
export class PhotoViewerPage implements OnInit {

  currentIndex = 0;
  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    loop: true,
    parallax: true,
    zoom: {
      minRatio: 1,
      maxRatio: 3,
      toggle: true,
      containerClass: 'swiper-zoom-container',
      zoomedSlideClass: 'swiper-slide-zoomed'
    }
  };
  imgs: any = [];
  selectedPos = 0;
  imgCssClass = ''
  nonSupportedFileExtensionsArr = ['pdf', 'doc', 'docx', 'document']
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private streamingMedia: StreamingMedia,
    private modalController: ModalController,
    private helper: AppHelper,
    private previewAnyFile: PreviewAnyFile
  ) {
    console.log(this.selectedPos)
    this.slideOpts['initialSlide'] = this.selectedPos
  }
  ngOnInit() {
    console.log(this.selectedPos)
    this.slideOpts['initialSlide'] = this.selectedPos
  }
  slideChanged(slides: IonSlides) {
    slides.getActiveIndex().then((index: number) => {
      this.currentIndex = index;
    });
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  async showVideo(url) {
    if (!this.helper.isDesktop()) {
      // alert(this.liveVideopath);
      let options: StreamingVideoOptions = {
        successCallback: () => { console.log('Video played') },
        errorCallback: (e) => { alert('Error streaming' + JSON.stringify(e)) },
        //orientation: 'landscape',
        shouldAutoClose: true,
        controls: false
      };

      this.streamingMedia.playVideo(url, options);
    } else {
      const modal = await this.modalController.create({
        component: ViewVideoPage,
        componentProps: { url: url },
        cssClass: 'viewVideoModal'
      })
      return modal.present();
    }
  }
  checkIsString(img) {
    if (typeof img === 'string')
      return true
    return false
  }

  getDocumentPath(docPath) {
    var FileUrl = "assets/images/default-icons/dummy_document_white.png";
    var ext = docPath.split('.').pop();
    if (this.nonSupportedFileExtensionsArr.includes(ext)) {
      return FileUrl;
    } else if (ext == 'mp4') {
      return docPath.replace('.mp4', '-thumb.jpg')
    } else {
      return docPath;
    }
  }

  onDocClick(docPath) {
    var ext = docPath.split('.').pop();
    if (this.nonSupportedFileExtensionsArr.includes(ext) || ext == 'mp4') {
      this.previewAnyFile.preview(docPath);
    }
  }
}
