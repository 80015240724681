import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProElementType } from '../enums';
import { UIControl } from '../uicontrol';

@Component({
  selector: 'pro-button',
  templateUrl: './pro-button.component.html',
  styleUrls: ['./pro-button.component.scss'],
})
export class ProButtonComponent implements OnInit {  
  @Input('btn_control')btnControl:UIControl;
  @Input('id')id ="pl_area_save_btn";
  @Input('btn_control_new')btn_control_new:UIControl
  @Output() eventHandlerEmitter = new EventEmitter();
  ProElementType=ProElementType
  constructor() { }
  ngOnInit() {}
  onBtnEvent() {
    this.eventHandlerEmitter.emit(this.btnControl)
  }
}
