import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ModalMortgageDiagReportPage } from '../modal-mortgage-diag-report/modal-mortgage-diag-report.page';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-mortgage-diag',
  templateUrl: './modal-mortgage-diag.page.html',
  styleUrls: ['./modal-mortgage-diag.page.scss'],
})
export class ModalMortgageDiagPage implements OnInit {

  mortgage: MortgageOptions = { principal: 0, loanTerm: 0, intRate: 0};

  constructor(private router:Router,private modalCtrl: ModalController) {
   
   }

  ngOnInit() {
  }

  openDiagnoseReport(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        principal: this.mortgage.principal,
        loanTerm: this.mortgage.loanTerm,
        intRate: this.mortgage.intRate
      }
    };
    
    this.router.navigate(['/modal-mortgage-diag-report'],navigationExtras)
    //this.router.navigateByUrl('/modal-mortgage-diag-report')
    this.modalCtrl.dismiss();
  }

}

export interface MortgageOptions {
  principal: number;
  loanTerm: number;
  intRate: number;
}
