import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIControl, UINewControl } from '../uicontrol';

@Component({
  selector: 'app-uiradio-button',
  templateUrl: './uiradio-button.component.html',
  styleUrls: ['./uiradio-button.component.scss'],
})
export class UIRadioButtonComponent implements OnInit {
  @Input('rb_model') rb_model: UINewControl;
  @Input('rb_value') rb_value: any
  @Input('rb_name') rb_name: any
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() { }
  ngOnChanges() {
    if (this.rb_model != null && this.rb_model.elementGridDataType == 0) {
      this.rb_value = this.rb_model["elementDataValKey"] || "";
      this.rb_name = this.rb_model["elementDataKey"] || "";
    }
  }
isChecked(val) {
    var postParams = this.rb_model['postParams']
    var elementFormPostKey = this.rb_model['elementFormPostKey'] || "";
    var selectedValues = this.rb_model['elementSelectedValues'] || [];
    if (postParams.hasOwnProperty(elementFormPostKey)) {
      selectedValues = postParams[elementFormPostKey] || [];
    }
    return selectedValues.indexOf(val) >= 0 ? true : false
  }
  onChangeEvent(item:any={}) {
    var val = this.rb_model['elementGridDataType']>0?this.rb_value:item[this.rb_value]
    this.rb_model.elementSelectedValues = [val]
    this.eventHandlerEmitter.emit(this.rb_model);
  } 
}
