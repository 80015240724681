import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavController, NavParams } from "@ionic/angular";
import { AppHelper } from "../services/middleware/app-helper";

@Component({
  selector: "app-butler-notification",
  templateUrl: "./butler-notification.page.html",
  styleUrls: ["./butler-notification.page.scss"],
})
export class ButlerNotificationPage implements OnInit {
  notificationImage: string;
  imagePath: string = "assets/butlerNotify/";
  constructor(
    private router: Router,
    private navCtrl: NavController,
    public modalCtrl: ModalController,
    private navParams: NavParams,
    public helper:AppHelper
  ) {
    const elapsedDays: number = this.navParams.get("elapsedDays");
    if (elapsedDays < -2) {
      this.notificationImage = "proppy_notification07.png";
    } else if (elapsedDays == -2) {
      this.notificationImage = "proppy_notification06.png";
    } else if (elapsedDays == -1) {
      this.notificationImage = "proppy_notification05.png";
    } else if (elapsedDays == 0) {
      this.notificationImage = "proppy_notification04.png";
    } else if (elapsedDays == 1) {
      this.notificationImage = "proppy_notification03.png";
    } else if (elapsedDays == 2) {
      this.notificationImage = "proppy_notification02.png";
    } else if (elapsedDays == 30){
      this.notificationImage = "proppy_notification01.png";
    }  else {
      setTimeout(() => {
        this.closeModal()
      }, 100);
      return
    }
    this.notificationImage = this.imagePath + this.notificationImage;
    console.log(elapsedDays);
  }

  ngOnInit() {}

  goMortgageButler() {
    this.modalCtrl.dismiss();
    this.router.navigate([(this.helper.isDesktop() ? "" : "tabs/") + "mortageplan",
    ]);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
