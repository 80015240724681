export enum ProDataTypes {
  Textbox = 1,
  Textarea = 2,
  CheckBox = 3,
  RadioButton = 4,
  DropDown = 5,
  MultiDropDown = 6,
  Image = 7,
  Button = 8
}
export enum ProElementStyle {
  PrimaryGradiant = "icon-btn-grad",
  PrimaryGradiantFull = "icon-btn-grad-notfull",
  Tab = "icon-btn-tab",
  TabInActive = "icon-btn-tab-inactive",
  PrimaryGradiants = "icon-btn-grads",
  PrimaryGradiantOutlinered = "icon-btn-grad-outlinered",
  PrimaryGradiantOutlineblue = "icon-btn-grad-outlineblue",
  SmallBtn = "icon-btn-grad-small",
  MediumBtn = "icon-btn-grad-medium",
  TabIconBtn = "icon-pro-tab",
  InactiveTabIconBtn = "icon-pro-tab-inactive",

}
export enum ProImageIcon {
  PeopleOutLine = "people-outline",
  CloudUpload = "cloud-upload",
  Share = "share-social-outline",
  Back = "arrow-back",
  Home = "home",
  Checkmark = "checkmark",
  Close = "close-circle",
  CloudUploadOutLine = "cloud-upload-outline",
  plusCircle = "add-circle-outline",
  Reward = "gift",
  CheckmarkCircleOutline = "checkmark-circle-outline",
  PencilOutline = "pencil-outline"
}
export enum ProElementType {
  Default = 0,
  Small = 1,
  Large = 2,
  XtraLarge = 3
}
export enum UIButtonStyle {
  Primary = "app-primary-btn",
  Secondary = "app-secondary-btn",
  Tertiary = "app-tertiary-btn",
  Success = "app-success-btn",
  Danger = "app-danger-btn",
}
export enum UITextFieldStyle {
  Rounded = "rounded",
  Rounded_Normal = "rounded_normal"
}
export enum UIDropDownStyle {
  Rounded = "rounded",
  Rounded_Incident = "rounded_incident_border"
}
export enum UIDatePickerStyle {
  Rounded = "rounded"
}
export enum UIProductStepperStyle {
  Horizontal = "horizontal",
  Vertical = 'verticle',
  HorizontalWithImg = 'horizontalWithImg'
}
export enum UIFontSize { }
export enum UIFontStyle {
  Upper = 'ion-text-uppercase',
  Lower = 'ion-text-lowercase',
  Capitalize = 'ion-text-capitalize'
}
export enum UIAlignment {
  Left = 'ion-float-left',
  Right = 'ion-float-right',
  FlexJustifyStart = 'ion-justify-content-start',
  FlexJustifyEnd = 'ion-justify-content-end',
  FlexJustifyCenter = 'ion-justify-content-center',
  FlexJustifyAround = 'ion-justify-content-around',
  FlexJustifyBetween = 'ion-justify-content-between',
  FlexJustifyEvenly = 'ion-justify-content-evenly',
  FlexAlignStart = 'ion-align-items-start',
  FlexAlignEnd = 'ion-align-items-end',
  FlexAlignCenter = 'ion-align-items-center',
  FlexAlignBaseLine = 'ion-align-items-baseline',
  FlexAlignStretch = 'ion-align-items-stretch',
  FlexAlignNoWrap = 'ion-align-items-nowrap',
  FlexAlignWrap = 'ion-align-items-wrap',
  FlexAlignWrapReverse = 'ion-align-items-wrap-reverse',
  Hide = 'ion-hide',
  NoBorder = 'ion-no-border'
}
export enum UITextAlignment {
  Left = 'ion-text-left',
  Right = 'ion-text-right',
  Center = 'ion-text-center',
  Justify = 'ion-text-justify',
  Wrap = 'ion-text-wrap',
  NoWrap = 'ion-text-nowrap'
}
export enum UITextColor {
  Black = "#000",
  Red = "danger"
}
export enum UIDatatypes {
  Label = 1,
  TextField = 2,
  TextArea = 3,
  Button = 4,
  RadioButton = 5,
  Checkbox = 6,
  Grid = 7,
  ListView = 8,
  SegmentedControl = 9,
  Dropdown = 10,
  CBDropdown = 11,
  MultiOptionCB = 12,
  RangeSlider = 13,
  NegetiveCBDropdown = 14,
  CBTextField = 15,
  NegetiveCBTextField = 16,
  CBDropdownPopUp = 17,
  DropdownPopUp = 18,
  RangeSliderNoCB = 19,
  AddMemberTF = 20,
  AddvehicleTF = 21,
  DatePicker = 22,
  LabelWithRightImages = 23,
  OverlayDropdown = 24,
  TextFieldwithUserIcon = 25,
  Image = 26,
  LabelWithOutBorder = 27,
  borderBottomlineTextfield = 28,
  SearchWithRighttext = 30,
  ProductStepper = 31,
  currencyMaskTextField = 32,
  ProgressTracker = 33,
  FacilitiesTF = 34,
  Separator = 40,
  ParagraphListView = 41
}
export enum UIAction {
  None = 0,
  Add = 1,
  Update = 2,
  Delete = 3,
  View = 4,
  Modal = 5,
  Clear = 6,
  ItemClick = 7,
  Scan = 8,
  Showhide = 9,
  Save = 13,
  Previous = 10,
  Next = 11,
  Dismiss = 12,
}
export enum UIUnits {
  Currency = 1,
  Area = 2
}

export enum FormStyles {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Bullet = 'bullet',
  Number = 'number',
  None = 'none',
  ListBox = 'list-box',
  Round = "round",
  Rounded = "rounded",
  Left = "left",
  Right = "right"
}
