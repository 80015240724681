import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProCustomModalComponent } from './pro-custom-modal.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProButtonModule } from '../pro-button/pro-button.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';



@NgModule({
  declarations: [ProCustomModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ProButtonModule,
    Ng2SearchPipeModule
  ],
  exports:[ProCustomModalComponent]
})
export class ProCustomModalModule { }
