import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRadioButtonComponent } from './uiradio-button.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [UIRadioButtonComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports:[UIRadioButtonComponent]
})
export class UIRadioButtonModule { }
