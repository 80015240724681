import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
// import { StorageService } from './storage.service';
// import { Storage } from '@ionic/storage';
import { Storage } from '@capacitor/storage';
import { base_path, cdn_portal } from 'src/environments/environment';
import { filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { RefreshAuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: RefreshAuthService,private router : Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const promise = Storage.get({ key: ApiService.LBDB_TOKEN_KEY })
    return from(promise).pipe(mergeMap(result => {
      let val = result['value'] || ""
      if (request.url.includes(ApiService.FD_BASE_URL)) {
        let fd_auth = "QmtTdlJ3V0VkOGV3VWtZMW1FQ2w6WA=="
        if (request.url.includes("proppydev")) {
          fd_auth = "UnBsYlNtNUpnVzVEWDNNRHRKODpY"
        }
        request = request.clone({
          setHeaders: {
            'Authorization': `Basic ${fd_auth}`
          }
        })
      } else if (request.url.includes(ApiService.basePythonApiUrl)) {
        request = request.clone({
          setHeaders: {
            'JWTToken': atob(val),
          }
        })
      } else if (request.url.includes(ApiService.NodeApiUrl)) {
        if (val && this.router.url!='/splash' && !request.url.endsWith('/instant_token')) {
          const modifyToken = this.authService.getJwtToken(atob(val))
          let jwtHelper = new JwtHelperService();
          if (jwtHelper.isTokenExpired(modifyToken)) {            
            return this.handle401Error(request, next)
          }
        }
        request = this.addToken(request, atob(val))
      }
      return next.handle(request);
    }))
  }

  private addToken(request: HttpRequest<any>, token: string) {
    let headers = { 'Authorization': "Basic UEhBZG1pbjpxbXozMUN5QFZJNHgkJWR1Y0EyVU5OTmtYblFD" }
    if (ApiService.IS_SKIP_LOGIN == 1) {
      headers['static-access-token'] = ApiService.STATIC_API_TOKEN
    } else {
      headers['x-access-token'] = token
    }
    return request.clone({
      setHeaders: headers,
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return from(this.authService.generateToken()).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token['result'].accessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}