import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ProSliderComponent } from './pro-slider.component';


@NgModule({
  declarations: [ProSliderComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [ProSliderComponent]
})
export class ProSliderModule { }
