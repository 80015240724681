import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalShopCartPageRoutingModule } from './modal-shop-cart-routing.module';

import { ModalShopCartPage } from './modal-shop-cart.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalShopCartPageRoutingModule
  ],
  declarations: [ModalShopCartPage]
})
export class ModalShopCartPageModule {}
