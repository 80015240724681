import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { ActionSheetController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { PhotoViewerPage } from 'src/app/reusables/photo-viewer/photo-viewer.page';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiFetcherService } from 'src/app/services/api-fetcher.service';
import { ApiService } from 'src/app/services/api.service';
import { LanguageService } from 'src/app/services/language.service';
import { OpenFileUtilService } from 'src/app/services/open-file-util.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-product-review-rating-details',
  templateUrl: './product-review-rating-details.component.html',
  styleUrls: ['./product-review-rating-details.component.scss'],
})
export class ProductReviewRatingDetailsComponent implements OnInit {

  @Input() ObjectType: string = '';
  @Input() ObjectID: string = '';
  @Input() ImageUrl: string = '';
  @Input() ProductName: string = '';
  @Input() product_review_rating_data: any = {}
  @Output() eventHandlerEmitter = new EventEmitter();
  @Input() UnitPrice: number = 0;
  @Input() ParentObjectID: string = '';
  @Input() product_ratings_details = {};
  docs = { document_path: '', submitted: false, Type: 'img' }
  Language = 'en'
  remarkTagIds = []
  user_ratings_details = []
  array = [1, 2, 3, 4, 5];
  tagReasons = []
  params = {
    // 'creator_user_id': '',
    'rating': 0, 'comments': '', 'object_type': '', 'object_id': '', 'like_status': 's', 'user_id': 0, 'review_rating_tag_ids': [], 'parent_object_id': ''
  }
  reviewAndRating: any = {
    'Images': []
  }
  // imageType:number= 1
  countMaxAllowed: number = 1;
  imagesCount;
  maxNoofImagesAllowed;
  isVisible: boolean = false
  skeleton_load: boolean = true;
  is_expand_rnr: boolean = false;
  constructor(
    private apiFetcher: ApiFetcherService,
    private storage: StorageService,
    private languageService: LanguageService,
    private alerthandler: AlertHandlerService,
    public apiService: ApiService,
    public navCtrl: NavController,
    private openFileService: OpenFileUtilService,
    private ref: ChangeDetectorRef,
    private loadingController: LoadingController,
    private actionSheet: ActionSheetController,
    private openImage: PreviewAnyFile,
    private modalController: ModalController
  ) {
    this.Language = this.languageService.selected || 'en';
  }

  ngOnInit() {
    this.getReviewRatingTagsCount()
    this.viewProductReviewsAndRatings()
  }

  ratingClick(val: number, index) {
    if (this.params['rating'] == val) {
      this.params['rating'] = 0;
    } else {
      this.params['rating'] = val;
    }
  }

  onTagSelected(item) {
    if (this.remarkTagIds.includes(item['id'])) {
      this.remarkTagIds = this.remarkTagIds.filter(f => f != item['id']);
    } else {
      this.remarkTagIds.push(item['id'])
    }
  }

  getReviewRatingTagsCount() {
    var params_tags_count = {
      'language': this.Language,
      'review_rating_type': this.ObjectType,
    }
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_HASHTAGS, params_tags_count).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.tagReasons = res['data']
      } else {
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
      }
    }), (error) => {
      this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
    }
  }

  async onSaveProductReviewRatingClick(obj) {
    this.params['object_id'] = this.ObjectID;
    this.params['object_type'] = this.ObjectType;
    this.params['parent_object_id'] = this.ParentObjectID
    this.storage.getString('Proppy_UserID', async (id) => {
      this.params['creator_user_id'] = id;
      this.params['review_rating_tag_ids'] = this.remarkTagIds
      if (!this.params['rating'] && this.params['rating'] == 0) {
        this.alerthandler.presenToast('Please Rate The Product.');
        return false
      }
      if (!this.params['review_rating_tag_ids'] && this.params['review_rating_tag_ids'].length == 0) {
        this.alerthandler.presenToast('Please select Rating Tags.');
        return false
      }
      await this.alerthandler.presentLoading('Adding Review..')
      this.apiFetcher.doPost(ApiService.ADD_REVIEW_RATING, this.params).subscribe(async (res) => {
        this.alerthandler.dismissLoading()
        const status = res['status'] || false
        if (status) {
          // if (obj['document_path'] && !obj['submitted']) {
          //   this.doFileUpload(obj)
          // }
          this.alerthandler.presenToast('Review Rating Submitted Successfully.');
          if (this.reviewAndRating['Images'].length > 0) {
            this.reviewAndRating['Images'].map((a, i) => {
              a['postParams'] = ''
              let params = new URLSearchParams();
              // params.set('ObjectType', this.ObjectType)
              // params.set('ObjectID', this.ObjectID)
              // params.set('ParentObjectID', this.ParentObjectID)
              // params.set("VisitID",'VI221128211658410OF');
              // params.set("num",this.reviewAndRating['imageType'][i].toString());
              // if (a['MediaText']) {
              //   params.set("mediaText", JSON.stringify(a['MediaText']));
              // }
              params.set('ReviewRatingID', res['id'])
              params.set('ObjectID', this.ObjectID)
              params.set('CreatedBy', id)
              a['url'] = ApiService.REVIEW_RATING_IMAGE_UPLOAD_V2 + "?" + params.toString();
            })
            let params = {
              filesArr: this.reviewAndRating['Images']
            }
            await this.alerthandler.presentLoading('Uploading image...');
            this.openFileService.doFilesUpload(params, (result) => {
              if (result['status']) {
                this.viewProductReviewsAndRatings()
                this.alerthandler.presenToast('Uploaded Images Successfully.')
                this.alerthandler.dismissLoading();
                this.ref.detectChanges();
              } else {
                this.viewProductReviewsAndRatings()
                this.alerthandler.presenToast(result['msg'] || 'Uploaded Images Failed.')
                this.alerthandler.dismissLoading();
                this.ref.detectChanges();
              }
            }, 'file', 0)
          } else {
            this.viewProductReviewsAndRatings()
          }
          this.ref.detectChanges()
        } else {
          this.alerthandler.dismissLoading()
          this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
        }
      }, err => {
        this.alerthandler.dismissLoading()
        this.alerthandler.presenToast(err['error'] || ApiService.ERROR_TOAST_MSG);
      });
    })
  }

  viewProductReviewsAndRatings() {
    this.skeleton_load = true;
    this.params['object_id'] = this.ObjectID;
    this.params['object_type'] = this.ObjectType;
    this.params['parent_object_id'] = this.ParentObjectID
    this.apiFetcher.doPost(ApiService.GET_REVIEW_RATING_DETAILS, this.params).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        // if ((res['data'] || []).length > 0) {
        this.product_ratings_details = res['data'][0] || {};
        this.eventHandlerEmitter.emit('success')
        this.ref.detectChanges()
        console.log(this.product_ratings_details, "???");
        // }
        this.skeleton_load = false
      } else {
        this.skeleton_load = false
        this.alerthandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG);
      }
      this.isVisible = true
    }), (error) => {
      this.isVisible = true
      this.skeleton_load = false
      this.alerthandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG);
    }
  }

  openFile(obj) {
    console.log(obj, 'open');
    var curObj = this
    this.openFileService.open(data => {
      obj['Type'] = data['doc_type'] || ''
      obj['doc_name'] = data['doc_name']
      obj['document_path'] = data['document_path']
      curObj.ref.detectChanges()
    })
    obj['submitted'] = false
  }

  async pickImage() {
    const actionSheet = await this.actionSheet.create({
      header: 'Select Image Source',
      buttons: [
        {
          text: 'Camera',
          handler: () => {
            this.openFileService.openImage(1, (result) => {
              if (result) {
                const tmpArr = [...this.reviewAndRating['Images'], ...result]
                if (tmpArr.length > 5) {
                  this.alerthandler.presenToast("Can't upload more than 5 images.")
                } else {
                  this.reviewAndRating['Images'] = [...this.reviewAndRating['Images'], ...result]
                }
                this.ref.detectChanges();
              }
            }, 1);
          }
        },
        {
          text: 'Gallery',
          handler: () => {
            this.openFileService.openImage(2, (result) => {
              if (result) {
                const tmpArr = [...this.reviewAndRating['Images'], ...result]
                if (tmpArr.length > 5) {
                  this.alerthandler.presenToast("Can't upload more than 5 images.")
                } else {
                  this.reviewAndRating['Images'] = [...this.reviewAndRating['Images'], ...result]
                }
                this.ref.detectChanges();
              }
            }, 5);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }]
    })
    await actionSheet.present();
  }
  // preViewImage(images, index, type) {
  //   if (type == 'local') {
  //     this.alerthandler.presentConfirmationAlertWithCustomButtons('Confirm!', 'Please Select an Action Below.', ['Delete', 'Show'], (alert, type) => {

  //     )} else if (type == 'service') {
  //     this.openImage.preview(images)
  //   }
  // }
  // if (type == 'local') {
  //   AlertService.presentAlertConfirm('Confirm!', 'Please Select an Action Below.', ['Delete', 'Show'], (alert, type) => {
  //     if (type == 1) {
  //       this.checkIn_Out['Images'].splice(index, 1)
  //     } else if (type == 2) {
  //       this.openImage.preview(images['document_path'])
  //     }
  //     alert.dismiss()
  //   })
  // } else if (type == 'service') {
  //   this.openImage.preview(images)
  // }


  preViewImage(images, index, type) {
    if (type == 'local') {
      // this.alerthandler.presentConfirmationAlertWithCustomButtons('Please Select an Action Below.', [{
      //   text: 'Delete', handler: () => {
      //     this.reviewAndRating['Images'].splice(index, 1)
      //   }
      // }, {
      //   text: 'Show', handler: () => {
      //     this.openImage.preview(images['document_path'])
      //   }
      // }
      // ])
      // this.alerthandler.dismissLoading();
      this.showImgs(index, 1)
    } else if (type == 'service') {
      this.openImage.preview(images)
    }
  }

  async showImgs(index, type) {
    let imgs = [];
    if (type == 1) {
      this.reviewAndRating['Images'].map(img => { imgs.push(img['doc_web_path']) });
    } else if (type == 2) {
      this.product_ratings_details['image_info'].map(img => { imgs.push(img['image_url']) });
    }
    const modal = await this.modalController.create({
      component: PhotoViewerPage,
      componentProps: {
        imgs: imgs,
        selectedPos: index,
      },
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    await modal
      .onWillDismiss()
      .then((result) => { })
      .catch((err) => { });
  }
  async doFileUpload(obj) {
    console.log(obj, "dofile");
    var params = new URLSearchParams()
    params.set('ObjectType', this.ObjectType)
    params.set('ObjectID', this.ObjectID)
    params.set('ParentObjectID', this.ParentObjectID)
    const loading = await this.loadingController.create({
      message: 'Uploading In Progress...',
    });
    await loading.present();
    this.openFileService.onFileUpload(obj, {}, ApiService.REVIEW_RATING_IMAGE_UPLOAD + '?' + params.toString(), (err, result) => {
      if (err) {
        loading.dismiss(this.alerthandler.presenToast(err['error'] || ApiService.ERROR_TOAST_MSG));
      } else {
        obj['submitted'] = true
        this.ref.detectChanges()
        loading.dismiss(this.alerthandler.presenToast("File Uploaded Successfully."));
        this.viewProductReviewsAndRatings()
      }
    })
  }
  removeDoc(doc) {
    console.log(doc);
    this.alerthandler.confirmationAlert('Confirm To Delete?', () => {
      doc['document_path'] = ''
      this.alerthandler.presenToast('Deleted Sucessfully')
    }, () => { });
  }
  onExpandClick(action: string) {
    this.is_expand_rnr = !this.is_expand_rnr
    // this.product_review_rating_data['actionEvent'] = action;
    // this.eventHandlerEmitter.emit(this.product_review_rating_data);
  }
  restrictCharacters() {
    if (this.params['comments'].length >= 1500) {
      setTimeout(() => {
        this.params['comments'] = this.params['comments'].trim().substring(0, 1500);
      }, 100)
      this.alerthandler.presenToast("Comment Maximum Limit Reached")
    }
    this.ref.detectChanges();
  }
}
