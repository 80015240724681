import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-marketplacenewlisting-modal-info',
  templateUrl: './marketplacenewlisting-modal-info.page.html',
  styleUrls: ['./marketplacenewlisting-modal-info.page.scss'],
})
export class MarketplacenewlistingModalInfoPage implements OnInit {

  InitialClick=true;
  NextClick=false;
  NextClick2=false;
  NextClick3=false;
  NextClick4=false;
  NextClick5=false;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
  }
  OnNextClick(){
    this.InitialClick=false;
      this.NextClick=true;
    }
    OnNextClick2(){
    this.InitialClick=false;
      this.NextClick=false;
      this.NextClick2=true;
    }
    OnNextClick3(){
      this.InitialClick=false;
      this.NextClick=false;
      this.NextClick2=false;
      this.NextClick3=true;
    }
    OnNextClick4(){
      this.modalCtrl.dismiss();
    }
}
