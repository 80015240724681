import { Component, OnInit, Output,Input,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ProElementType, UIUnits } from '../enums';
import { UINewControl } from '../uicontrol';

@Component({
  selector: 'app-pro-slider',
  templateUrl: './pro-slider.component.html',
  styleUrls: ['./pro-slider.component.scss'],
})
export class ProSliderComponent implements OnInit {
  @Input('slider_control') SliderControl:UINewControl;
  stepVal = 10
  @Output() eventHandlerEmitter = new EventEmitter();
  ProElementType=ProElementType

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {}
  onSliderValChange(event) {
    var val=event['detail']['value']
    if(val['lower']>10 && val['lower']<=100){
      this.stepVal = 10
    }    
    if(val['lower']>100 && val['lower']<=1000){
      if(val['lower']>100 && val['lower'] < 200){
        val['lower'] = 200
      }
      this.stepVal = 100
    }   
    if(val['lower']>1000 && val['lower']<=10000){
      if(val['lower']>1000 && val['lower'] < 2000){
        val['lower'] = 2000
      }
      this.stepVal = 1000
    }
     if(val['lower']>10000){
      this.stepVal = 10000
    }
      // this.SliderControl.selectedMin=val['lower'] + 100

    this.SliderControl.selectedMin=val['lower']
    this.SliderControl.selectedMax=val['upper']
    this.cd.detectChanges()
    this.eventHandlerEmitter.emit(this.SliderControl)
  }

  onChangeEvent(){
    var val = this.SliderControl['elementCBDVal'] > 0 ? (this.SliderControl['elementCBDVal'] == 1 ? 2 : 0) : 1
    this.SliderControl.elementCBDVal = val
    this.eventHandlerEmitter.emit(this.SliderControl);
    this.cd.detectChanges()
  }

  getRangeSelected(){
    if(this.SliderControl.UOM==UIUnits.Currency){
      return ((this.SliderControl['selectedMin']==this.SliderControl['defaultMin']?'Min':(this.SliderControl['selectedMin']>=1000000?(this.SliderControl['selectedMin']/1000000)+'M':(this.SliderControl['selectedMin']>=1000?(this.SliderControl['selectedMin']/1000)+'K':this.SliderControl['selectedMin']))) +' - '+ (this.SliderControl['selectedMax']==this.SliderControl['defaultMax']?'Max':this.SliderControl['selectedMax']>=1000000?(this.SliderControl['selectedMax']/1000000)+'M':(this.SliderControl['selectedMax']>=1000?(this.SliderControl['selectedMax']/1000)+'K':this.SliderControl['selectedMax'])))
    } else if(this.SliderControl.UOM==UIUnits.Area){
      return ((this.SliderControl['selectedMin']==this.SliderControl['defaultMin']?'Min':(this.SliderControl['selectedMin']>43560?(this.SliderControl['selectedMin']/43560)+' Acres':this.SliderControl['selectedMin']+' sq. ft.'))) +' - '+ (this.SliderControl['selectedMax']==this.SliderControl['defaultMax']?'Max':this.SliderControl['selectedMax']>43560?(this.SliderControl['selectedMax']/43560)+' Acres':this.SliderControl['selectedMax']+' sq. ft.')
    }
  }
}
