import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class StorageService {
    public user;
    public product;
    public apiToken;
    public chatWith;
    public helpdesk;
    public subject;
    public subjectId;
    public myEvents=new BehaviorSubject('Started');

    constructor(private storage: Storage) { }

    public emitMyEvents(eventType:"login"|"logout"|"message_sent"){
        this.myEvents.next(eventType);
        console.log(eventType + " event happened");
      }
    
      public handleMyEvents(callback,eventType:"login"|"logout"){
        this.myEvents.subscribe((evType)=>{
          if(evType==eventType){
            console.log(eventType + " event handeled");
            callback();
          }
        })
      }

    async setString(key: string, value: string) {
        await this.storage.set(key, value);
    }

    async getString(key: string,cb) {
        await this.storage.get(key).then(val => {
            if (val != null) {
                cb(val);
            } else {
                cb('');
            }
        });
    }

    async getJWTToken(){
        var token="";
        await this.storage.get('proppy_authtoken').then(val=>{
            token=val;
        });
        return token;
    }

    async setInt(key: string, value: Number) {
        await this.storage.set(key, value);
    }

    async getInt(key:string,cb) {
        await this.storage.get(key).then(val => {
            if (val != null) {
                cb(val);
            } else {
                cb('');
            }
        });
    }

    async setData(key: string, value: any) {
        await this.storage.set(key, JSON.stringify(value));
    }

    async getData(key: string,cb) {
        return await this.storage.get(key).then(val => {
            if (val != null && val.length > 0) {
                cb(JSON.parse(val));
            } else {
                cb(key.includes('arr')?[]:{});
            }
        });
    }
    async getAsycData(key: string) {
        const val = await this.storage.get(key);
        return val || ""
    }

    async removeItem(key: string) {
        await this.storage.remove(key);
    }

    async clear() {
        await this.storage.clear();
    }

    setIonicDateTime(value: string): Date {
        if (value) {
            let date: Date = new Date(value);
            let ionicDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
            return ionicDate;
        }
        return null;
    }
    getIonicDateTime(value: Date): string {
        if (value) {
            let date: Date = new Date(value);
            let ionicDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            return ionicDate.toISOString();
        }
        return null;
    }
}