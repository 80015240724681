import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProButtonComponent } from './pro-button.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ProButtonComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports:[ProButtonComponent]
})
export class ProButtonModule { }
