import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppHelper } from '../services/middleware/app-helper';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-merchant-popover',
  templateUrl: './merchant-popover.page.html',
  styleUrls: ['./merchant-popover.page.scss'],
})
export class MerchantPopoverPage implements OnInit {
  merchantData={}
  CreatorUserID=''
  constructor(
    private navParams:NavParams,
    private popover:PopoverController,
    private router:Router,
    public user: StorageService,
    private ngZone:NgZone,
    public helper:AppHelper,
    private storage: Storage,

  ) {
    this.merchantData=this.navParams.data
    this.user.getString('Proppy_UserID',id=>{
      this.CreatorUserID = id
    });
  }

  ngOnInit() {
  }

  async onChatClick(data){
    this.popover.dismiss();
    // this.user.chatWith = {
    //   Proppy_UserID: this.merchantData['CreatedBy'],
    //   Proppy_FirstName: this.merchantData['CreatedBy'],
    //   Proppy_Profile_Pic: this.merchantData['Image_URL']
    // };
    // // console.log('onChatClic' + data.CreatedBy)
    // // console.log('onChatClic' + data.Image_URL)
    // // console.log('onChatClic' + JSON.stringify(data))

    // this.user.subjectId = this.merchantData['MerchantID'] + this.CreatorUserID;
    // this.user.subject = 'ProppyCares : '+ this.merchantData['Name'];

    // //console.log(this.user.chatWith);

    // this.router.navigate(['/tabs/chat']);


    const participants = {"UserID":this.merchantData['created_by'],"Name":this.merchantData['created_by'],"ProfilePicURL":this.merchantData['company_logo']}
    var conversationData = {"GroupName":("ProppyCares : " + this.merchantData['name']),
    "GroupId":(this.merchantData['merchant_id'] +  this.CreatorUserID),"Participants":[participants]}
    await this.user.setData("newConversation",conversationData);
    console.log(conversationData);
    this.router.navigate(['group-chat']);


}

onDetailsClick() {
  this.popover.dismiss();
  let navigationExtras: NavigationExtras = {
    queryParams: {
      MerchantID: this.merchantData['merchant_id']
    }
  };
  this.ngZone.run(() => {
    this.router.navigate([(this.helper.isDesktop()?'':'tabs/')+'merchantdetails'], navigationExtras);
  });
}

}
