import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../api.service';
@Pipe({
  name: 'langPipe',
  pure:false
})
export class LangPipe implements PipeTransform {

  transform(langJson: any, ...args: any[]): unknown {
    var selectedCode=ApiService.SELECTED_LANGUAGE_CODE || ''
    var langCode= selectedCode || args[2] || ApiService.DEFAULT_LANG_CODE
    if(args.length>1){
      const tmpObj = langJson[args[0]] || {}
      var langObj = tmpObj[args[1]] || {}
      return langObj[langCode] || langObj[ApiService.DEFAULT_LANG_CODE] || ""
    }   
    return null;  
  }

}
