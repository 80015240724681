import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { AlertHandlerService } from '../services/alert-handler.service';
import { ConstantsService } from '../services/constants/constants.service';
import { DataFetcherService } from '../services/data-fetcher/data-fetcher.service';
import { StorageService } from '../services/storage-service.service';
import { ApiService } from '../services/api.service'
import  addProperty  from "src/assets/lang/addmyproperty.json";

@Component({
  selector: 'app-select-residents-type-drop-down',
  templateUrl: './select-residents-type-drop-down.page.html',
  styleUrls: ['./select-residents-type-drop-down.page.scss'],
})
export class SelectResidentsTypeDropDownPage implements OnInit {
  add_property_json = JSON.parse(JSON.stringify(addProperty))
  allResidents = {};
  isFromModal = false;
  modalTag: string = ""
  selected_value = ''
  data = { selected_value: '', param_key: '', router_selector: '' }
  constructor(public apiService: ApiService, private activatedRoute: ActivatedRoute, private alertHandeler: AlertHandlerService, public navCtrl: NavController, private http: DataFetcherService, private storage: StorageService,private modalCtrl: ModalController) {
    if (!this.isFromModal) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.storage.getData(ApiService.DROP_DOWN_DATA_SEND_KEY, (data) => {
          if (data.hasOwnProperty("selected_value")) {
            this.data = data;
            this.storage.removeItem(ApiService.DROP_DOWN_DATA_SEND_KEY);
          }
          this.getPropertyType();
        })
      });
    }
  }

  ngOnInit() {
    if(this.isFromModal) {
      // this.data['base_label']=''
      console.log(this.data)
    }
  }

  getPropertyType() {
    this.http.doPost(ApiService.GET_PROPERTY_TYPE, {}).subscribe((res) => {
      if (res.hasOwnProperty('status') && res['status'] == true) {
        if (this.data.hasOwnProperty('CategoryID')) {
          this.allResidents = {}
          var catogaoryID = this.data['CategoryID'];
          this.allResidents[catogaoryID] = res['data'][catogaoryID];
          if (this.data.hasOwnProperty('UnwantedSubCategoryID')) {
            delete this.allResidents[catogaoryID][this.data['UnwantedSubCategoryID']];
          }
        } else {
          this.allResidents = res['data'];
        }
      } else {
        this.alertHandeler.presentAlert("Invalid Data From service.");
      }
    });
  }

  async onBackClick() {
    if(this.isFromModal){
      this.selected_value = ''
      this.modalCtrl.dismiss({});
    } else {
      await this.storage.setData(ApiService.DROP_DOWN_DATA_RESPONSE_KEY, {
        selected_value: this.data.selected_value,
        param_key: this.data.param_key,
        selected_obj: JSON.stringify(this.selected_value)
      });
      this.navCtrl.navigateForward([this.data['router_selector']]);
      // this.navCtrl.back();
    }
  }

  async valueSelected(selectedValue) {
    if (this.isFromModal) {
      var data = { "selectedItems": selectedValue, "tag": this.modalTag }
      this.modalCtrl.dismiss(data);
    } else {
      await this.storage.setData(ApiService.DROP_DOWN_DATA_RESPONSE_KEY, {
        selected_value: this.data.selected_value,
        param_key: this.data.param_key,
        selected_obj: JSON.stringify(selectedValue)
      });
      this.navCtrl.navigateForward([this.data['router_selector']]);
    }
  }
  dismiss() {        
    this.selected_value = ''
    this.modalCtrl.dismiss({});
  }
}
