import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UIAction } from '../enums';
import { UIControl } from '../uicontrol';
@Component({
  selector: 'app-uilabel',
  templateUrl: './uilabel.component.html',
  styleUrls: ['./uilabel.component.scss'],
})
export class UilabelComponent implements OnInit {

  @Input('label_model') label_model: UIControl;
  @Input() isCustom: boolean = false
  @Input() customInputData: any = {"class":"","text":"",'color':'#000'}
  lang_code = ""
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor(private router: Router,private navCtrl: NavController,private modalController:ModalController) {
    // DBHandlerService.getDataFromStorage(ApiService.LBDB_LANG_CODE,(val => {
    //   this.lang_code = (val != null?val:ApiService.DEFAULT_LANG_CODE);
    // }))
   }

  ngOnInit() {}

  onClick(){
    let actionEvent = this.label_model['actionEvent'];
    if(actionEvent == UIAction.Previous && this.label_model['routePath']){
      this.navCtrl.navigateBack(this.label_model['routePath']); // Previous URL
    }else if(actionEvent == UIAction.Previous){
      this.navCtrl.back() // Previous URL
    }else if(actionEvent == UIAction.Next && this.label_model['routePath']){
      this.navCtrl.navigateForward(this.label_model['routePath']); // next URL
    }else if(actionEvent == UIAction.Dismiss){
      this.modalController.dismiss
    } else { // this is for Api Service call for Save 
      this.eventHandlerEmitter.emit(this.label_model);
    }
  }
  setMyStyles() {
    let styles = {
      'background-color': this.customInputData['background-color'] ? this.customInputData['background-color'] : 'transparent',
      'color': this.customInputData['color'] ? (this.customInputData['color']=='NONE'?'':this.customInputData['color']) : '#000',
      'margin-left': this.customInputData['margin-left'] ? this.customInputData['margin-left'] : '',
      'margin-top': this.customInputData['margin-top'] ? this.customInputData['margin-top'] : '',
      'margin-bottom': this.customInputData['margin-bottom'] ? this.customInputData['margin-bottom'] : ''
    };
    return styles;
  }
}

