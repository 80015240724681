import { Injectable, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, fromEvent, merge, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { InternetConnectionModalPage } from '../modals/internet-connection-modal/internet-connection-modal.page';
import { AlertHandlerService } from './alert-handler.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService implements OnInit{
  minSpeedInMb = 0.5
  intervalMillis = 60000
  public appIsOnline: Observable<boolean>;
  networkListener: PluginListenerHandle

  constructor(private modalController: ModalController,private alertHandeler:AlertHandlerService) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  public  async initialize(){
    console.log("connectivity check")
    this.initConnectivityMonitoring(); 
    this.initiateCheck()
  }

  initiateCheck(){
    setInterval(() => {
      this.CheckConnectivitySpeed()
    },this.intervalMillis);
  }
  CheckConnectivitySpeed(){
    var imageLink = "https://cdn.proppyapp.net/images/AppImages/ProppyCaresCategory/SA.png";
    var time_start, end_time;
    var downloadSize = 3220;
    var downloadImgSrc = new Image();
    downloadImgSrc.src=imageLink
    var gbl=this
    downloadImgSrc.onload = function () {
      end_time = new Date().getTime();
      var duration = (end_time - time_start) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = Number((bitsLoaded / duration).toFixed(2));
      var speedKbps = Number((speedBps / 1024).toFixed(2));
      var speedMbps = Number((speedKbps / 1024).toFixed(2));
      ApiService.IS_SLOW_INTERNET = !speedMbps || speedMbps<gbl.minSpeedInMb
      setTimeout(() => {
        ApiService.IS_SLOW_INTERNET = false
      }, 30000);
      };
    time_start = new Date().getTime();
  }
  public CheckConnectivity(type=0){
    this.appIsOnline.subscribe(async online => {
      console.log(type)
      console.log(online)
      if (online) {
        this.modalController.dismiss({
          'dismissed': true
        });
        console.log("App is online")
      } else {
          if(type==0){
            // SplashScreen.hide()
            const modal = await this.modalController.create({
              component: InternetConnectionModalPage,
              cssClass: 'modalalert',
              backdropDismiss: false,
              keyboardClose:true,
              componentProps: {
                "RetryFun": ()=>{
                  // this.CheckConnectivity(1)
                },
              }
            });
            await modal.present();
          }  else {
            this.alertHandeler.presenToast('Still No Internet Connection.')
          }
          console.log("App is offline")     
      }
    })
  }
  private initConnectivityMonitoring() {

    // if (!window || !navigator || !('onLine' in navigator)) return;

    // this.appIsOnline = merge(
    //   of(null),
    //   fromEvent(window, 'online'),
    //   fromEvent(window, 'offline')
    // ).pipe(map(() => navigator.onLine))

    Network.addListener('networkStatusChange',async status => {
      console.log('Network status changed', status);
      if (status.connected) {
        console.log('true')
        this.modalController.dismiss({
          'dismissed': true
        });
        console.log("App is online")
      } else {
        console.log('false')
        const modal = await this.modalController.create({
          component: InternetConnectionModalPage,
          cssClass: 'modalalert',
          backdropDismiss: false,
          keyboardClose:true,
          componentProps: {
            "RetryFun": async ()=>{
              const status = await Network.getStatus()
              if(!status.connected){
                this.alertHandeler.presenToast('Still No Internet Connection.')
              } else{
                modal.dismiss()
              }
            },
          }
        }); 
        await modal.present()
        SplashScreen.hide()
      }
    });
  }
}