import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProCbDropdownComponent } from './pro-cb-dropdown.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [ProCbDropdownComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports:[ProCbDropdownComponent]
})
export class ProCbDropdownModule { }
