import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReviewRatingComponent } from './review-rating.component';
@NgModule({
  declarations: [ReviewRatingComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports:[ReviewRatingComponent]
})
export class ReviewRatingModule { }
