import { Component, OnInit, Input } from '@angular/core';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiService } from 'src/app/services/api.service';
import { DataFetcherService } from 'src/app/services/data-fetcher/data-fetcher.service';
import { AppHelper } from 'src/app/services/middleware/app-helper';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-ui-ph-property',
  templateUrl: './ui-ph-property.component.html',
  styleUrls: ['./ui-ph-property.component.scss'],
})
export class UiPhPropertyComponent implements OnInit {
  @Input() isCustom: boolean = false
  @Input() customInputData: any = { "class": "", "text": "", "subText": "", 'color': '#000' }
  @Input() isVMS: boolean = false
  @Input() vmsInputData: any = { "class": "", "propertyName": "", "unitno": "", "unit_id": "", 'is_avilable': 0 }
  headerbg: boolean = false;
  user_id = ''
  resident_data:any;
  constructor(
    private _http: DataFetcherService,
    private alert: AlertHandlerService,
    private storage: StorageService,
    public helper : AppHelper
  ) { }
  ngOnInit() {
    this.storage.getString("Proppy_UserID", (proppyUserID) => {
      this.user_id = proppyUserID || "";
    })
  }
  myStatus() {
    this.vmsInputData['is_avilable'] = this.vmsInputData['is_avilable']==1 ? 0 : 1;
    this.onStatusUpdate();
  }

  onStatusUpdate() {
    var params = {
      unit_id: this.vmsInputData['unit_id'],
      pid: this.user_id,
      is_available: this.vmsInputData['is_avilable']
    }
    this._http.doPut(ApiService.DELETE_PH_PROPERTY_USER, params).subscribe(res => {
      const status = res['status'] || false;
      if (status) {
        this.setConversation(this.user_id,this.vmsInputData['is_avilable']);
        this.alert.presenToast(res['msg'] || "Updated Successfully. ")
        this.storage.getData(ApiService.RESIDENTS_DATA, (data) => {
          this.resident_data = data || {}
          this.resident_data['is_available'] = params['is_available']   
          this.storage.setData(ApiService.RESIDENTS_DATA, this.resident_data);  
        });
      } else {
        this.alert.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG)
      }
    }, (error) => {
      this.alert.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG)
    })
  }

  setConversation(pid,type){
    let removeUser = []
    let addUser = []
    if(type==0) removeUser.push(pid)
    if(type==1) addUser.push(pid)
    this.helper.getConversationsByPID(pid,(err,res)=>{
      if(res){
        var conversationData = (res['data'] || []).filter(f => (f['id'] || '').endsWith(this.vmsInputData['unit_id']))
        conversationData.map(conv => {
          this.helper.updateConversationAccess(conv['id'],addUser,removeUser,()=>{})
        })
      }              
    })
  }
}