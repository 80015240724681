import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { UINewControl } from '../uicontrol';

@Component({
  selector: 'app-ui-popover',
  templateUrl: './ui-popover.component.html',
  styleUrls: ['./ui-popover.component.scss'],
})
export class UiPopoverComponent implements OnInit {
  
  
  filterTerm: any;
  popData: any = []
  item : any 
  popover_type : any

  constructor( private navParams: NavParams,
    private popCtrl: PopoverController) {}

  ngOnInit() {
   console.log(this.popData)
  }
  OnChange(item){
    console.log(item , "////////")
    this.popCtrl.dismiss(item);
  }

  onChangeEvent(e) {
    // this.popover_model.elementSelectedVal = e.target.value
    // this.eventHandlerEmitter.emit(this.popover_model);

    this.popCtrl.dismiss();
  }
}
