import { Injectable } from "@angular/core";
import { Device } from "@ionic-native/device/ngx";
// import { FirebaseX, IChannelOptions } from "@ionic-native/firebase-x/ngx";
import { LocalNotifications } from '@capacitor/local-notifications'
import { NavController, Platform } from "@ionic/angular";
import { ApiService } from "./api.service";
import { DataFetcherService } from "./data-fetcher/data-fetcher.service";
import { StorageService } from "./storage.service";
import {
  ActionPerformed,
  Channel,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { SplashScreen } from "@capacitor/splash-screen";
import { DBHandlerService } from "./db-handler.service";
import { UtilsConfig, DBManger } from '@proppy/shared-lib';
import { LoginConfig } from '@proppy/proppy-app-login-lib';

@Injectable({
  providedIn: "root",
})
export class FcmService {
  constructor(
    private platform: Platform,
    private device: Device,
    private storage: StorageService,
    private http: DataFetcherService,
    private navctrl: NavController
  ) {
    this.platform.ready().then(() => {
      if (
        (this.platform.is("ios") || this.platform.is("android")) &&
        !this.platform.is("mobileweb")
      ) {
        const defaultchannel: Channel = {
          id: "ProppyChannel",
          name: "ProppyChannel",
          sound: "bit_beeps_21.wav",
          importance: 5,
        };
        PushNotifications.createChannel(defaultchannel).then(() => { });
        const inboxchannel: Channel = {
          id: "ProppyInboxChannel",
          name: "ProppyInboxChannel",
          sound: "poppi_sound.wav",
          importance: 5,
        };
        PushNotifications.createChannel(inboxchannel).then(() => { });
        const gemchannel: Channel = {
          id: "ProppyGemChannel",
          name: "ProppyGemChannel",
          sound: "coin_sound.wav",
          importance: 5,
        };
        PushNotifications.createChannel(gemchannel).then(() => { });
      }
    });
  }

  initializeFcm() {
    console.log("test");
    this.platform.ready().then(() => {
      this.setFcmToken();
      // var channel:IChannelOptions = {
      //   id: "proppy_default",
      //   name: "Proppy",
      //   description: "My Default Description",
      //   sound: "bit_beeps_21.wav",
      //   vibration: [500, 200, 500],
      //   light: true,
      //   lightColor: parseInt("FF0000FF", 16).toString(),
      //   importance: 4,
      //   badge: true,
      //   visibility: -1
      // };

      // this.fcm.setDefaultChannel(channel).then(value=>{
      //   console.log(value)
      // },err=>{ console.log(err)});

      /* if (this.platform.is("ios")) {
         this.fcm.hasPermission().then((value) => {
           if (!value) {
             this.localNotifications.requestPermission().then((permitted) => {
               console.log(permitted);
             });
             // this.fcm.grantPermission().then(permited=>{
             //   console.log(permited)
             // })
           }
         });
       }*/
      /*  this.fcm.onMessageReceived().subscribe((data) => {
          console.log(data);
          if (data.tap) {
            console.log("Received in background and clicked");
            if (data.hasOwnProperty("route")) {
              this.navctrl.navigateForward(this.getRoutePath(data["route"]));
            }
          } else {
            this.storage.setData(data.sent_time, data);
            this.localNotifications.schedule({
              title: data.title,
              text: data.body,
              id: Number(data.sent_time),
              foreground: true,
              sound: "bit_beeps_21",
            });
            console.log(
              "Received in foreground and local notification initiated"
            );
            this.localNotifications.on("click").subscribe((data) => {
              console.log("local notification clicked");
              console.log(data);
              this.storage.getData("" + data.id, (notificationData) => {
                this.storage.removeItem("" + data.id);
                if (notificationData.hasOwnProperty("route")) {
                  this.navctrl.navigateForward(
                    this.getRoutePath(notificationData["route"])
                  );
                }
              });
            });
          }
        });*/

      /* this.fcm.onTokenRefresh().subscribe((token) => {
         // Register your new token in your back-end if you want
         // backend.registerToken(token);
         this.storage.setString(ApiService.FCM_TOKEN_KEY, token);
         this.recordUserFCM(token);
       });*/
    });
  }

  async getRoutePath(data) {
    var route = data["route"] || "";
    if (route.includes("/")) {
      data["route_path"] = route;
    }
    switch (route) {
      case "chat": {
        return "tabs/chat";
      }
      case "VaccineBookingList": {
        return (
          "c360/c360-vaccinebookingslist?ListingID=" +
          data["ListingID"] +
          "&Title=" +
          data["Title"]
        );
      }
      case "MyVaccineBookingList": {
        return "c360/c360-vaccinebookingslist";
      }
      case "GemHistory": {
        return "tabs/gem-transfer/history";
      }
      case "ProppyDeal": {
        return "tabs/proppy-deal";
      }
      case "PMInbox": {
        return `tabs/proppy-deal?subType=pm&order_type=${data["order_type"] || "P"
          }`;
      }
      case "PMTracker": {
        return `tabs/proppy-deal?subType=pm&displayTab=2&order_type=${data["order_type"] || "P"
          }`;
      }
      case "ViewOrder": {
        return `tabs/proppy-deal/view-order?order_id=${data["order_hdr_id"]
          }&order_type=${data["order_type"] || "P"}`;
      }
      case "PMProgressTracker": {
        return `tabs/proppy-deal/pm-progress-tracker?order_hdr_id=${data["order_hdr_id"]}`;
      }
      case "PMBookingTracker": {
        return `tabs/premium-merchant/booking-tracker?order_hdr_id=${data["order_hdr_id"]}`;
      }
      case "NeedsDetails": {
        return `tabs/bulletin-board-need-details?NeedsID=${data["needs_id"] || data["NeedsID"]
          }`;
      }
      case "NeedsOrder": {
        return "tabs/proppycare-needs-confirm-order?OrderID=" + data["OrderID"];
      }
      case "NeedsTracker": {
        return "tabs/proppy-needs-process-tracker?OrderID=" + data["OrderID"];
      }
      case "PMQueue": {
        return "tabs/merchantdetails?merchant_id=" + data["merchant_id"];
      }
      case "ProppyHomes": {
        var params = {};
        params["unit_id"] = data?.unit_id;
        params["ph_route"] = data?.ph_route;
        params["selected_tab"] = data?.selected_tab;
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return "proppy-homes-v2";
      }
      case "PrelovedProgressTracker": {
        return (
          "/tabs/proppy-deal/preloved-deals-tracker?listing_id=" +
          data["listing_id"] +
          "&type=" +
          data["request_type"] +
          "&requestor_pid=" +
          data["requestor_pid"] +
          "&id=" +
          data["request_id"]
        );
      }
      case "PrelovedInbox": {
        return "/tabs/proppy-deal?subType=preloved&displayTab=1";
      }
      case "MyAccount": {
        return "tabs/myaccount";
      }
      case "MyGoodies": {
        data['selected_type'] = 3
        return "tabs/my-goodies?selected_type=" + data["selected_type"];
      }
      case "NeedsHistory": {
        return "&isModal=" + true;
      }
      case "Notifications": {
        return "/tabs/notification";
      }
      case "MerchantRenewalReminder": {
        let params: any = {
          "merchant_renewal_reminder": 1,
          "event_type": data['event_type']
        };
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return `/tabs/business-add?ListingID=${data["unit_id"]}&Business=true&own=true`;
      }
      case "Signature": {
        return `/proppy-signature?doc_id=${data['doc_id']}`;
      }
      case "BizCard": {
        let temp: any = {
          "assignee_id": data['assignee_id'],
          "object_id": data['object_id'],
          "is_in_contact": (data['is_in_contact'] || 0),
          "pid": data['pid']
        }
        await DBManger.addJsonToLocalDB(UtilsConfig.TEMP_NOTIFICATION_ROUTE_DATA, temp, (err: any) => { });
        return `/business-card`;
      }
      case "VoucherDetails": {
        data['type'] = 'notification'
        return `/tabs/voucher-details?voucherID=${data["voucher_id"]}&merchantID=${data["merchant_id"]}&type=${data['type']}`;
      }
      case "Promoter": {
        const params: any = { 'promoter_id': data['promoter_id'], 'promoter_request': 1 }
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return `/tabs/premium-merchant/pm-promoter`;
      }
      case "CampaignRoute": {
        const params: any = { 'merchant_id': data['merchant_id'], 'campaign_update': 1 }
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return `/tabs/premium-merchant/pm-promoter`;
      }
      case "RouteToPayout": {
        const params: any = { 'merchant_id': data['merchant_id'], 'payout': 1 }
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return `/tabs/premium-merchant/pm-promoter`;
      }
      case "PcardBizBookAcceptOrReject": {
        if ((data['is_exchanged'] || 0) == 1)
          return `/business-card/pcard-biz-book`
        else
          return `/business-card/bump-card-share?id=${data.id}`
      }
      case "MerchantVerification": {
        return `/business-card/business-verification?merchant_id=${data.object_id}&type=${data.type}`
      }
      case "OfflineLoyaltyPoints": {
        let route_params: any = {
          "points": data['points'],
          "order_number": data['order_number'],
          "notification_status": data['notification_status'],
          "trx_type": data['trx_type']
        }
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, route_params);
        return `/tabs/my-membership/members-transaction-history?merchant_id=${data["merchant_id"]}`;
      }
      case "UserTierUpdate": {
        return `/tabs/my-membership/members-transaction-history?merchant_id=${data["merchant_id"]}`;
      }
      case "AssetListing": {
        return `/tabs/own-property-list/assets-listing?selected_type=${data["selected_type"]}`;
      }
      case "WallEvent": {
        return `/tabs/proppy-wpe/wall-post-event-details?event_id=${data['event_id']}`;
      }
      case "eBadge": {
        const params: any = { 'merchant_id': data['merchant_id'], 'id': data['id'], "bid": data['bid'] }
        await this.storage.setData(ApiService.NOTIFICATION_PARAMS, params);
        return `/tabs/merchantdetails?merchant_id=${data['merchant_id']}`;
      }
      case "RemoveQualifier": {
        return `/tabs/merchantdetails?merchant_id=${data['merchant_id']}`;
      }
      default: {
        return data["route_path"] || "tabs/tab1";
      }
    }
  }

  setFcmToken() {
    /* this.fcm.getToken().then((token) => {
       // Register your new token in your back-end if you want
       // backend.registerToken(token);
       this.storage.setString(ApiService.FCM_TOKEN_KEY, token);
       this.recordUserFCM(token);
     });*/
  }

  recordUserFCM(token) {
    this.storage.getString("Proppy_UserID", (Proppy_UserID) => {
      if (Proppy_UserID.length > 0) {
        this.http
          .doPost(ApiService.SET_USER_FCMTOKEN, {
            UserID: Proppy_UserID,
            FirebaseToken: token,
            DeviceUUID: this.device.uuid,
          })
          .subscribe((res) => {
            console.log(res.msg);
          });
      }
    });
  }

  UpdateNotificationStatus() { }

  setUpRemoteNotifications() {
    this.platform.ready().then(() => {
      if (
        (this.platform.is("ios") || this.platform.is("android")) &&
        !this.platform.is("mobileweb")
      ) {
        console.log("Mobile Device Detected");
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });
        PushNotifications.addListener("registration", (token: Token) => {
          ApiService.PUSH_NOTIFICATION_TOKEN = token['value'] || ""
          LoginConfig.FB_PUSH_TOKEN = token['value'] || ""
          this.storage.setString(ApiService.FCM_TOKEN_KEY, token.value);
          this.recordUserFCM(token.value);
          DBHandlerService.addToLocalDB(
            ApiService.LBDB_PUSH_KEY,
            token.value,
            () => { }
          );
          // alert('Push registration success, token: ' + token.value);
        });

        PushNotifications.addListener("registrationError", (error: any) => {
          console.log("Error on registration: " + JSON.stringify(error));
        });

        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            var data = notification.data;
            // if (notification.click_action) {
            //   console.log("Received in background and clicked");
            //   if (data.hasOwnProperty("route")) {
            //     this.navctrl.navigateForward(this.getRoutePath(data["route"]));
            //   }
            // } else {
            var id = new Date().getUTCMilliseconds();
            console.log(notification);
            LocalNotifications.schedule({
              notifications: [
                {
                  title: notification.title,
                  body: notification.body,
                  id: id,
                  sound: data['sound'],
                  channelId: data['android_channel_id'],
                  extra: data
                }
              ]
            });
            console.log(
              "Received in foreground and local notification initiated"
            );
            LocalNotifications.addListener("localNotificationActionPerformed", async (notificationAction) => {
              SplashScreen.hide();
              console.log("local notification clicked");
              console.log(notificationAction);
              var notificationParams = notificationAction['notification']
              var notificationData = notificationParams['extra']
              if (notificationData['message_id']) {
                this.recordNotificationReadStatus(notificationData['message_id'])
              }
              if (notificationData.hasOwnProperty("route")) {
                var route_path = await this.getRoutePath(notificationData);
                route_path =
                  route_path +
                  ((route_path || "").includes("?")
                    ? "&prev_route=notification"
                    : "?prev_route=notification");
                this.navctrl.navigateForward(route_path);
              }
            });
            // }
            console.log("Push received: " + JSON.stringify(notification));
          }
        );

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          async (notification: ActionPerformed) => {
            console.log(
              "Push action performed: " + JSON.stringify(notification)
            );
            SplashScreen.hide();
            var data = notification.notification.data;
            if (data["message_id"]) {
              this.recordNotificationReadStatus(data["message_id"]);
            }
            if (data.hasOwnProperty("route")) {
              var route_path = await this.getRoutePath(data);
              route_path =
                route_path +
                ((route_path || "").includes("?")
                  ? "&prev_route=notification"
                  : "?prev_route=notification");
              this.navctrl.navigateForward(route_path);
            }
          }
        );
      }
    });
  }

  recordNotificationReadStatus(message_id, Status = 2) {
    this.storage.getString("Proppy_UserID", (Proppy_UserID) => {
      this.http
        .doPut(ApiService.PROPPY_NOTIFICATION, {
          message_id: message_id,
          receiver_proppy_user_id: Proppy_UserID,
          status: Status,
        })
        .subscribe((res) => {
          const status = res["status"] || false;
          if (status) {
            //nothing to do
          }
        });
    });
  }
}
