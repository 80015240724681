import { Component, OnInit, EventEmitter, Input, Output, } from '@angular/core';
import { UINewControl } from '../uicontrol';

@Component({
  selector: 'app-ui-chip',
  templateUrl: './ui-chip.component.html',
  styleUrls: ['./ui-chip.component.scss'],
})
export class UiChipComponent implements OnInit {

  @Input('chip_model')
  @Input() isCustom: boolean = false
  @Input() customInputData: any = { "chip_class": "", "text": "", "text_class": '', 'color': '', 'ui_chip_img': '', 'ui_chip_icon': '','background-color' :''}
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() { }
  setMyStyles() {
    let styles = {
      'background-color': this.customInputData['background-color'] ? this.customInputData['background-color'] : 'transparent',
      'color': this.customInputData['color'] ? this.customInputData['color'] : '#000',
    };
    return styles;
  }
}
