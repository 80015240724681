import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, filter, switchMap, retry, catchError } from 'rxjs/operators'
import { environment, url } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProppyService {

  private url = url;
  //private url = 'http://localhost:6146/api';
  //private url = 'https://api.proppyapp.dev/api'
  //private url = 'http://realitysystemsapi.realty-systems.com/api';
  //private url2 = 'https://aversavewebapi.aversave.com';
  //private apiProducts = this.url2 + '/api/products';
  public apiProductsAddToCart = this.url + '/ShoppingCarts1'
  public apiMember = this.url + '/Members'
  
  public apiMemberPoints = this.url + '/memberpoints'
  //public apiMemberPoints = this.url + '/ProppyApp/GetMemberPoints'

  public apiAdvertiser = this.url + '/AdvertiserProppyCares'
  public apiMortgage = this.url + '/mortgage'
  public apiProperty = this.url + '/propertymarkets?'
  public apiProperty2 = this.url + '/ProppyPropertyMarket?'
  public apiProperty3 = this.url + '/ProppyPropertyMarket/'
  public apiPropertySale = this.url + '/propertymarkets?type=sale'
  public apiPropertyRent = this.url + '/propertymarkets?type=rent'
  public apiDiagnoseWizard = this.url + '/MemberProperties'
  private apiMerchantsImages = this.url + '/merchants_images';
  public apiMerchants = this.url + '/Merchants1/';
  public apiMerchants1Images = this.url + '/Merchants1_Images';
  public apiMerchants1ImagesUpload = this.url + '/ProppyCaresImageUploader';
  public apiMemberProperty = this.url + '/MemberProperties1'
  public apiMemberPropertyPaymentSch = this.url + '/MemberPropertyPaymentSches'
  public apiPMONotification = this.url + '/PMONotifications'
  public apiPChat = this.url + '/ProppyChats'
  public apiPChatMessages = this.url + '/ProppyChatMessages'
  public apiChatGetPPM = this.url + '/ProppyPropertyMarket/'
  public apiCountries = this.url + '/countries'
  public apiProducts = this.url + '/Products1'
  public apiDefaultImagePPM = this.url + '/PPMImages'
  public apiDefaultImagePSP = this.url + '/ProppyCaresImage'
  public apiDefaultDescriptionPSP = this.url + '/ProppyCaresImageDescription'
  public apiDefaultImageProppyPreloved = this.url + '/ProppyPrelovedImageUploader'
  
  public apiProppyPreloved = this.url + '/ProppyPreloved'
  public apiProppyPrelovedImage = this.url + '/ProppyPrelovedImage'

  public apiProppyCaresCategory = this.url + '/ProppyCaresCategories'
  public apiProductImages = this.url + '/Products_Images'
  public apiAcademy = this.url + '/Videos' 

  public advertiserPortal = "https://advertiser.proppyapp.dev"

  public CartItems: number = 0;
  public userProppyPoints: number;

  constructor(private http: HttpClient) { }

  getMerchantsImagesByUsername(username) {
    return this.http.get(this.apiMerchantsImages + '?merchantId=' + username);
}
  getCategories() {
    return this.http.get(this.url + '/Categories1');
  }

  getCategoriesMain() {
    return this.http.get(this.url + '/ServiceProvider');
  }

  getProppyCaresCategories() {
    return this.http.get(this.apiProppyCaresCategory);
  }

  getCountries() {
    return this.http.get(this.apiCountries);
  }

  getCategoriesSub(id) {
    return this.http.get(this.url + '/CategoriesSub?id=' + id);
  }

  getProductImages(id) {
    return this.http.get(this.url + '/Products_Images?productId=' + id);
  }

  getPropertyImages(id) {
    return this.http.get(this.url + '/PropertyMarket_Images?propertyId=' + id);
  }

  getProppyCaresImages(merchantid) {
    return this.http.get(this.apiMerchants1Images + '?merchantID=' + merchantid);
  }

  getPropertyDetails(id) {
    return this.http.get(this.apiChatGetPPM + id);
  }

  getAdvertistments(): Observable<any> {
    //return this.http.get(this.url + '/Advertistments1');
    return this.http.get<any>(this.url + '/Advertistments1')
     .pipe(
       retry(1),
       catchError(this.handleError)
     );
  }

  getProducts() {
    return this.http.get(this.url + '/Products1');
  }

  getMerchants(category,long, lat, search) {
    //return this.http.get(this.url + '/Merchants1?categoryCode=' + category);
    if (search != ''){
      return this.http.get(this.url + '/ProppyCares?categoryCode=' + category + '&gpslong=' + long + '&gpslat=' + lat + '&search=' + search);
    } else {
      return this.http.get(this.url + '/ProppyCares?categoryCode=' + category + '&gpslong=' + long + '&gpslat=' + lat);
    }
  }

  getMerchantsByCreator(createdBy) {
    return this.http.get(this.url + '/AdvertiserProppyCares?createdBy=' + createdBy);
  }

  getPrelovedByCreator(createdBy) {
    return this.http.get(this.url + '/proppypreloved?createdBy=' + createdBy);
  }

  // getProductsByMerchant(merchantCode) {
  //   return this.http.get(this.apiProducts + '?merchantCode=' + merchantCode);
  // }

  getShoppingCartItems(customerEmail) {
    return this.http.get(this.apiProductsAddToCart + '?customerEmail=' + customerEmail).pipe(res => res);
  }

  // getMortgage(principle,loanTerm,intRate) {
  //   return this.http.get(this.apiMortgage + '?principle=' + principle + '&loanTerm=' + loanTerm + 'intRate=' + intRate).pipe(res => res);
  // }

  getMortgage(principle,loanTerm,intRate) {
    return this.http.get(this.apiMortgage + '?principal=' + principle + '&loanTerm=' + loanTerm + '&intRate=' + intRate);
  }

  async mGeocoder(address){
    return this.http.get('https://api.locationiq.com/v1/autocomplete.php?key=pk.173ad3548e6f88d486058ecf9f42e720&q=' + address);
  }
  
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  
  deg2rad(deg) {
    return deg * (Math.PI/180)
  }

}
