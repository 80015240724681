import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  //Proppy App Routes Start
  public static countryCodesDropDown='country-codes-dropdown'
  //Proppy App Routes Start

  //Community 360 Routes Start
  public static c360base='c360';
  public static c360Login='c360-login';
  public static c360Home='c360-home';
  public static c360Registration='c360-registration';
  public static c360OtpValidation='c360-otpvalidation';
  public static c360MyAccount= 'c360-myaccount';
  public static c360MyQrCode= 'c360-myqrcode';
  public static c360SOSCreate= 'c360-soscreate';
  public static c360SOSDisplay= 'c360-sosdisplay';
  public static c360ShowAllFeed= 'c360-showallfeed';
  public static c360About360= 'c360-about360';
  public static c360SupportDisplay= 'c360-supportdisplay';
  public static c360SupportCreate= 'c360-supportcreate';
  public static c360Supportlist= 'c360-supportlist';
  public static c360Supplielist= 'c360-supplieslist';
  public static c360SupplieCreate= 'c360-suppliescreate';
  public static c360SupplieDisplay= 'c360-suppliesdisplay';
  public static c360Model='c360-model'
  public static c360RedFlagDisplay='c360-redflagdisplay'
  public static c360VaccineList='c360-vaccinelist'
  public static c360VaccineDisplay='c360-vaccinedisplay'
  public static c360VaccineCreate='c360-vaccinecreate'
  public static c360VaccineBookingCreate='c360-vaccinebookingcreate'
  public static c360VaccineBookingsList='c360-vaccinebookingslist'
  public static c360VaccineBookingDetails='c360-vaccinebookingdetails'

  public static c360GlobalSearch='c360-globalsearch'
  public static c360HowTo='c360-howto'
  public static c360Beep='c360-beep'
  public static c360CreateGroups='c360-create-groups'
  public static c360GroupChat='c360-group-chat'
  public static c360SupportersCreate='c360-supportorscreate'
  public static c360ForgetPwd='c360-forget-pwd'
  public static c360StoreLink='c360-store-link'


  public static c360Default=RoutesService.c360Home;
  //Community 360 Routes End


  constructor() { }

  static c360NavigatableURL(route):string{
    return RoutesService.c360base+'/'+route
  }
}
