import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProgressTrackerComponent } from './progress-tracker.component';
@NgModule({
  declarations: [ProgressTrackerComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports:[ProgressTrackerComponent]
})
export class ProgressTrackerModule { }
