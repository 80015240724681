import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UITextFieldComponent } from './uitext-field.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CurrencyMaskModule } from 'ng2-currency-mask';
@NgModule({
  declarations: [UITextFieldComponent],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    CurrencyMaskModule
  ],
  exports:[UITextFieldComponent]
})
export class UITextFieldModule { }
