import { LanguagePopoverPageModule } from './language-popover/language-popover.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule,HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalShopCartPageModule } from './modal-shop-cart/modal-shop-cart.module';
import { ModalMortgageDiagPageModule } from './modal-mortgage-diag/modal-mortgage-diag.module';
import{ ButlerNotificationPageModule } from './butler-notification/butler-notification.module';
import { ActionSheetModelPageModule } from './action-sheet-model/action-sheet-model.module';
import { ProppycaresModalInfoPageModule } from './proppycares-modal-info/proppycares-modal-info.module';
import { MarketplaceMediaPopupPageModule } from './marketplace-media-popup/marketplace-media-popup.module';


import { Stripe } from '@ionic-native/stripe/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Badge } from '@ionic-native/badge/ngx';

// geolocation and native-geocoder
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';  
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { EndorsementListPipe } from './endorsement-list.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { Device } from '@ionic-native/device/ngx';
import { JwtInterceptor } from './services/jwt.interceptor';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';

//Leaflet Cluster plugin
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { AgmCoreModule } from '@agm/core';

//import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseAuthService } from './services/firebase-auth-service.service';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

import { ArchwizardModule } from 'angular-archwizard';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@ionic-native/media-capture/ngx';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MarketplacenewlistingModalInfoPageModule } from './marketplacenewlisting-modal-info/marketplacenewlisting-modal-info.module';
import { InternetConnectionModalPageModule } from './modals/internet-connection-modal/internet-connection-modal.module';
import { Network } from '@ionic-native/network/ngx';
import { PhotoViewerPageModule } from './reusables/photo-viewer/photo-viewer.module';
import { PermissionModalPageModule } from './modals/permission-modal/permission-modal.module';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { MultipleDocumentsPicker } from '@ionic-native/multiple-document-picker/ngx';
import { MerchantPopoverPageModule } from './merchant-popover/merchant-popover.module';
import { AuthGuardService } from './services/middleware/auth-guard.service';
import { AuthService } from './services/middleware/auth.service';
import { GlobalErrorHandlerService } from './services/middleware/global-error-handler.service';
import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator/ngx';
import { ErrorInterceptor } from './services/error.interceptor';
import { UiSharedModule } from './ui-shared.module';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    // pan: { direction: Hammer.DIRECTION_ALL },
    // swipe: { direction: Hammer.DIRECTION_ALL },
    'pinch': { enable: false },
    'rotate': { enable: false }    
  };
}

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwW67TDu8X8UHkN_nwTgRxq_1vNP1kmB0",
  authDomain: "proppyapp-82d1b.firebaseapp.com",
  projectId: "proppyapp-82d1b",
  storageBucket: "proppyapp-82d1b.appspot.com",
  messagingSenderId: "357990397459",
  appId: "1:357990397459:web:80d8d7fe2a14602788b1e6",
  measurementId: "G-H7TJDC9FBE"
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
  //return new TranslateHttpLoader(http, 'https://proppyapp.com/app/translation/', '.json');
  
}

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
    declarations: [AppComponent, EndorsementListPipe],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({ name: "proppy_local_db", driverOrder: ['indexeddb', 'sqlite', 'websql'] }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }), AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAaQJpf2YbAitpQH0FFg-QT6yY9uSa0eHM', libraries: ["places"]
        }),
        AppRoutingModule, HttpClientModule, ModalShopCartPageModule, ModalMortgageDiagPageModule,
        FormsModule, ButlerNotificationPageModule, ActionSheetModelPageModule, ProppycaresModalInfoPageModule, MarketplacenewlistingModalInfoPageModule, MarketplaceMediaPopupPageModule, LanguagePopoverPageModule, NgSelectModule, CurrencyMaskModule,
        LeafletModule, LeafletMarkerClusterModule, AngularFireModule.initializeApp(firebaseConfig), AngularFireAuthModule,
        LazyLoadImageModule, ArchwizardModule, InternetConnectionModalPageModule, PhotoViewerPageModule, PermissionModalPageModule, MerchantPopoverPageModule, UiSharedModule,
      ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        StatusBar,
        Camera,
        Stripe,
        SocialSharing,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Geolocation,
        NativeGeocoder,
        LocationAccuracy,
        InAppBrowser,
        Badge,
        File,
        WebView,
        FilePath,
        AppVersion,
        Device,
        Deeplinks,
        ImagePicker,
        FileOpener,
        AndroidPermissions,
        QRScanner,
        PhotoViewer,
        FileChooser,
        IOSFilePicker,
        PreviewAnyFile,
        Clipboard,
        Network,
        OpenNativeSettings,
        CallNumber,
        MultipleDocumentsPicker,
        PDFGenerator,
        AuthGuardService, AuthService,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        FirebaseAuthService, GooglePlus, Facebook, SignInWithApple, MediaCapture,
        StreamingMedia, VideoEditor,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(library: FaIconLibrary) { 
	// 	library.addIconPacks(fas, fab, far);
	// }
}
