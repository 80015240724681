import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  UINewControl } from '../uicontrol';

@Component({
  selector: 'app-uicheckbox',
  templateUrl: './uicheckbox.component.html',
  styleUrls: ['./uicheckbox.component.scss'],
})
export class UICheckboxComponent implements OnInit {
  @Input('cb_model') cb_model: UINewControl
  @Input('cb_value') cb_value: any
  @Input('cb_name') cb_name: any
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() { }
  ngOnChanges() {
    if (this.cb_model != null && this.cb_model.elementGridDataType == 0) {
      this.cb_value = this.cb_model["elementDataValKey"] || "";
      this.cb_name = this.cb_model["elementDataKey"] || "";
    }
  }
  isChecked(val) {
    var postParams = this.cb_model['postParams']
    var elementFormPostKey = this.cb_model['elementFormPostKey'] || "";
    var selectedValues = this.cb_model['elementSelectedValues'] || [];
    if (postParams.hasOwnProperty(elementFormPostKey)) {
      selectedValues = postParams[elementFormPostKey] || [];
    }
    return selectedValues.indexOf(val) >= 0 ? true : false
  }
  onChangeEvent(item:any={}) {    
    var val = this.cb_model['elementGridDataType']>0?this.cb_value:item[this.cb_value]  
    var postParams = this.cb_model['postParams']
    var elementFormPostKey = this.cb_model['elementFormPostKey'] || "";
    var selectedValues = this.cb_model['elementSelectedValues'] || [];
    if (postParams.hasOwnProperty(elementFormPostKey)) {
      selectedValues = postParams[elementFormPostKey] || [];
    }
    var isChecked = selectedValues.filter(f => f == val).length>0?false:true;
    if (isChecked) {
      selectedValues.push(val)
      selectedValues = Array.from(new Set(selectedValues));
    } else {
      selectedValues = selectedValues.filter(f => f != val);
    }
    this.cb_model.elementSelectedValues = selectedValues
    this.eventHandlerEmitter.emit(this.cb_model);
  }
}
