import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ActionSheetModelPageRoutingModule } from './action-sheet-model-routing.module';
import { ActionSheetModelPage } from './action-sheet-model.page';
import {TranslateModule} from  '@ngx-translate/core'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ActionSheetModelPageRoutingModule,
    TranslateModule
  ],
  declarations: [ActionSheetModelPage]
})
export class ActionSheetModelPageModule {}
