import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UIAction } from '../enums';
import { UINewControl } from '../uicontrol';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss'],
})
export class ProgressTrackerComponent implements OnInit {

  uiactions = UIAction
  @Input('tracker_model') tracker_model: UINewControl;
  @Output() eventHandlerEmitter = new EventEmitter();
  
  constructor(
    private navCtrl: NavController,
    private modalController:ModalController) { }

  ngOnInit() {}
  onClick(){
    let actionEvent = this.tracker_model['actionEvent'];
    if(actionEvent == UIAction.Previous && this.tracker_model['routePath']){
      this.navCtrl.navigateBack(this.tracker_model['routePath']); // Previous URL
    }else if(actionEvent == UIAction.Previous){
      this.navCtrl.back() // Previous URL
    }else if(actionEvent == UIAction.Next && this.tracker_model['routePath']){
      this.navCtrl.navigateForward(this.tracker_model['routePath']); // next URL
    }else if(actionEvent == UIAction.Dismiss){
      this.modalController.dismiss
    } else { // this is for Api Service call for Save 
      this.eventHandlerEmitter.emit(this.tracker_model);
    }
  }
  itemClick(action){
    this.eventHandlerEmitter.emit({ ...this.tracker_model, actionEvent: action })
  }
}
