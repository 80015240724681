import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  constructor() { }
  static doPost = async (url,token, post_params) => {
    try {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Basic UEhBZG1pbjpxbXozMUN5QFZJNHgkJWR1Y0EyVU5OTmtYblFD");
      if(token) {
        headers.append("x-access-token", token);
      }
      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(post_params)
      };
      const result = await (await fetch(url, requestOptions)).json()
      return result
    } catch (error) {
      throw error
    }
  }
}
