import { Injectable } from "@angular/core";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { Observable, Subject, from } from "rxjs";
import { AlertController, Platform } from "@ionic/angular";
import firebase from "firebase/app";
import { filter, map, take } from "rxjs/operators";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook/ngx";
import {
  AppleSignInErrorResponse,
  AppleSignInResponse,
  ASAuthorizationAppleIDRequest,
  SignInWithApple,
} from "@ionic-native/sign-in-with-apple/ngx";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataFetcherService } from "./data-fetcher/data-fetcher.service";
import { ApiService } from "./api.service";
import { AppHelper } from "./middleware/app-helper";
import { ConstantsService } from "./constants.service";
import { DBHandlerService } from "./db-handler.service";
import { Geolocation } from "@ionic-native/geolocation/ngx";

class ProfileModel {
  image: string;
  name: string;
  role: string;
  description: string;
  email: string;
  provider: string;
  phoneNumber: string;
}

@Injectable()
export class FirebaseAuthService {
  user_location = {
    longitude: 0.0,
    latitude: 0.0,
  };
  constructor(
    public angularFireAuth: AngularFireAuth,
    public platform: Platform,
    private google: GooglePlus,
    private fb: Facebook,
    private alertController: AlertController,
    private signInWithApple: SignInWithApple,
    private http: DataFetcherService,
    public constant: ConstantsService,
    private app_helper: AppHelper,
    private geolocation: Geolocation
  ) {}

  googleSignin(cb) {
    if (this.app_helper.isDesktop()) {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("email");
      provider.addScope("profile");
      provider.addScope("phone");
      this.desktopSign(provider, cb);
      return;
    }
    this.google
      .login({})
      .then((response) => {
        const { idToken, accessToken } = response;
        const credential = idToken
          ? firebase.auth.GoogleAuthProvider.credential(idToken, accessToken)
          : firebase.auth.GoogleAuthProvider.credential(null, accessToken);
        this.onLoginSuccess(credential, cb);
      })
      .catch((error) => {
        cb(false, error);
        console.log(error);
        // alert('error:' + JSON.stringify(error))
      });
  }

  fbSignin(cb) {
    if (this.app_helper.isDesktop()) {
      var provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("public_profile");
      provider.addScope("email");
      this.desktopSign(provider, cb);
      return;
    }
    this.fb
      .login(["public_profile", "email"])
      .then((res: FacebookLoginResponse) => {
        const credential = firebase.auth.FacebookAuthProvider.credential(
          res.authResponse.accessToken
        );
        this.onLoginSuccess(credential, cb);
        console.log("Logged into Facebook!", res);
      })
      .catch((e) => {
        cb(false, e);
        console.log("Error logging into Facebook", e);
      });
  }

  appleSignin(cb) {
    if (this.app_helper.isDesktop()) {
      var provider = new firebase.auth.OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      // provider.addScope('phone')
      this.desktopSign(provider, cb);
      return;
    }
    if (this.platform.is("ios")) {
      this.signInWithApple
        .signin({
          requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
          ],
        })
        .then((res: AppleSignInResponse) => {
          // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
          const credential = new firebase.auth.OAuthProvider(
            "apple.com"
          ).credential(res.identityToken);
          this.onLoginSuccess(credential, cb);
          // alert('Send token to apple for verification: ' + res.identityToken);
          console.log(res);
        })
        .catch((error: AppleSignInErrorResponse) => {
          // alert(error.code + ' ' + error.localizedDescription);
          cb(false, error.localizedDescription);
          console.error(error);
        });
    } else {
      cb(false, "No an IOS Device, Please try with an IOS Device.");
    }
  }

  desktopSign(provider, cb) {
    this.angularFireAuth
      .signInWithPopup(provider)
      .then((response) => {
        let providerData: any = response.user.providerData[0];
        let additionalUserInfo: any = response.additionalUserInfo;
        if (additionalUserInfo) {
          providerData = { ...providerData, ...additionalUserInfo };
        }
        this.validateUserAndRegister(
          providerData.phoneNumber,
          providerData.email,
          providerData.displayName,
          providerData.profile["family_name"] ||
            providerData.profile["first_name"],
          providerData.profile["given_name"] ||
            providerData.profile["last_name"],
          this.getProviderType(response.additionalUserInfo.providerId),
          response.user.uid,
          providerData.photoURL,
          cb
        );
      })
      .catch((e) => {
        console.log(e);
        cb(false, e);
      });
  }

  onLoginSuccess(credential, cb) {
    this.angularFireAuth
      .signInWithCredential(credential)
      .then((response) => {
        let providerData: any = response.user.providerData[0];
        let additionalUserInfo: any = response.additionalUserInfo;
        if (additionalUserInfo) {
          providerData = { ...providerData, ...additionalUserInfo };
        }
        const displayName = providerData["displayName"] || "";
        this.validateUserAndRegister(
          providerData.phoneNumber,
          providerData.email,
          displayName,
          providerData.profile["family_name"] ||
            providerData.profile["first_name"],
          providerData.profile["given_name"] ||
            providerData.profile["last_name"],
          this.getProviderType(response.additionalUserInfo.providerId),
          response.user.uid,
          providerData.photoURL,
          cb
        );
      })
      .catch((e) => {
        console.log(e);
        cb(false, e);
      });
  }

  getProviderType(providerId) {
    switch (providerId) {
      case "google.com":
        return 1;
      case "facebook.com":
        return 2;
      case "apple.com":
        return 3;
    }
  }

  deleteCurrentUser(cb) {
    this.angularFireAuth.currentUser.then((user) => {
      user.delete().then(() => {
        cb(true);
      });
    });
  }

  logout(type) {
    if (type == 1) {
      this.google.disconnect();
    } else {
      this.fb.logout();
    }
    this.angularFireAuth.signOut().then(() => {});
  }

  public getProfileData(res) {
    const userModel = new ProfileModel();
    let providerData: any = res.user.providerData[0];
    let additionalUserInfo: any = res.additionalUserInfo;

    if (additionalUserInfo) {
      providerData = { ...providerData, ...additionalUserInfo };
    }

    // Default imgs are too small and our app needs a bigger image
    switch (providerData.providerId) {
      case "facebook.com":
        userModel.image =
          "https://graph.facebook.com/" +
          providerData.uid +
          "/picture?height=400";
        break;
      case "google.com":
        userModel.image = providerData.photoURL.split("=")[0];
        break;
      default:
        userModel.image = providerData.photoURL;
    }
    userModel.name =
      providerData.name || providerData.displayName || "What's your name?";
    userModel.role = "How would you describe yourself?";
    userModel.description =
      providerData.description ||
      "Anything else you would like to share with the world?";
    userModel.phoneNumber =
      providerData.phoneNumber || "Is there a number where I can reach you?";
    userModel.email = providerData.email || "Where can I send you emails?";
    userModel.provider =
      providerData.providerId !== "password"
        ? providerData.providerId
        : "Credentials";
    console.log(res);
    // this.getPh(res['credential']['accessToken']);
    return userModel;
  }

  async presentConfirmationAlert(msg, cb) {
    const alert = await this.alertController.create({
      header: "Alert",
      message: msg,
      buttons: [{ text: "Yes", handler: cb }, "No"],
    });

    await alert.present();
  }

  async validateUserAndRegister(
    Mobile,
    Email,
    Name,
    FirstName,
    LastName,
    ProviderType,
    UID,
    ProfilePicURL,
    cb
  ) {
    await this.getUserLoc();
    this.generateInstantToken((push_token) => {
      var body = {
        mobile: "",
        email: Email,
        name: Name || (Email || "").split("@")[0],
        first_name: FirstName || "",
        last_name: LastName || "",
        provider_type: ProviderType,
        uid: UID,
        profile_pic_url: ProfilePicURL || "",
        app_name: "PROPPYAPP",
        device_token: push_token,
        referrer: "PROPPY",
        app_id:ApiService.PROPPYAPP_APP_ID
      };
      body = { ...body, ...this.user_location };
      this.http
        .doPost(ApiService.SOCIAL_NETWORK_USER_AUTHENTICATION, body)
        .subscribe((res) => {
          const status = res["status"];
          if (status) {
            res["Email"] = Email;
            cb(true, res);
          } else {
            cb(false, res);
          }
          console.log(res);
        });
    });
  }

  async generateInstantToken(cb) {
    var post_params = { ip_address: "122.175.13.25" };
    this.constant.onPost(ApiService.INSTANT_TOKEN, post_params).subscribe(
      (res) => {
        const status = res["status"] || false;
        if (status) {
          DBHandlerService.addToLocalDB(
            ApiService.LBDB_TOKEN_KEY,
            res["token"],
            (err) => {
              if (!err) {
                DBHandlerService.getDataFromStorage(
                  ApiService.LBDB_PUSH_KEY,
                  (push_err, push_token) => {
                    if (!push_err && push_token.length > 0) {
                      cb(push_token);
                    } else if (ApiService.FCM_TOKEN_KEY) {
                      cb(ApiService.FCM_TOKEN_KEY);
                    }
                  }
                );
              } else {
                this.AlertMessage("Error Message");
              }
            }
          );
        }
      },
      (error) => {
        console.log(error, "Error");
        this.AlertMessage("Unable to process request");
      }
    );
  }
  AlertMessage(msg) {
    this.alertController
      .create({ header: "Error", message: msg, buttons: ["OK"] })
      .then((res) => {
        res.present();
      });
  }
  async getUserLoc() {
    await this.geolocation.getCurrentPosition().then((res) => {
      this.user_location.latitude = res["coords"]["latitude"];
      this.user_location.longitude = res["coords"]["longitude"];
    });
  }
}
