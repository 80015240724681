import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Platform } from '@ionic/angular';
import { AlertHandlerService } from '../alert-handler.service';
import { AppHelper } from './app-helper';
@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  constructor(private helper: AppHelper,
    private mediaCapture:MediaCapture,
    private alertService: AlertHandlerService,
    private platform: Platform,
    private webview:WebView,
    private sanitizer:DomSanitizer,) {
    Camera.requestPermissions()
  }
  async getPicture(src_type = 1,limit=1) {
    if (!Capacitor.isPluginAvailable('Camera') || (this.helper.isDesktop())) {
      return;
    }
    if (src_type == 1) {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: ((src_type == 1) ? CameraSource.Camera : CameraSource.Photos)
      });
      return image;
    }else {
      const image = await Camera.pickImages({
        quality: 100,
        limit:limit
      });
      return image;
    }
  }
  async getVideo(src_type = 1,limit=1,duration=null) {
    if (!Capacitor.isPluginAvailable('Camera') || (this.isDesktop())) {
      return;
    }
    if (src_type == 1) {
      var options={limit:limit,quality:75}
      if(duration){
        options['duration']=duration
      }
      const video=this.mediaCapture.captureVideo(options)
      return video
    } else {
      this.alertService.presenToast('Currently Not Supported');
      return null;
    }
  }
  isDesktop() {
    if ((this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb")) {
      return false    
    }
    return true;
  }
  pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      let converted = this.webview.convertFileSrc(img);
      const safeImg = this.sanitizer.bypassSecurityTrustUrl(converted);
      return safeImg;
      // return converted;
    }
  }
}
