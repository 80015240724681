import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { AgentProfilev2Page } from 'src/app/agent-profilev2/agent-profilev2.page';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { ApiService } from 'src/app/services/api.service';
import { DataFetcherService } from 'src/app/services/data-fetcher/data-fetcher.service';
import { AdmobService } from 'src/app/services/middleware/admob-service';
import { AppHelper } from 'src/app/services/middleware/app-helper';
import { ProDataTypes, ProElementStyle, UIAction } from '../enums';
import { UIControl } from '../uicontrol';

@Component({
  selector: 'app-pro-custom-modal',
  templateUrl: './pro-custom-modal.component.html',
  styleUrls: ['./pro-custom-modal.component.scss'],
})
export class ProCustomModalComponent implements OnInit {
  typeOfSelect: string = ""
  assetUrl:string=""
  serviceImg:boolean=true
  items: any = []
  selectedItems = []
  modalTitle: string = ""
  modalTag: string = ""
  imageIconKey: string = ""
  titleKey: string = ""
  subTitleKey: string = ""
  filterValKey: string = ""
  customFilterValKey: string = ""
  maxLimit: number = 0
  isMutiSelect: boolean = false;
  confirmationMsg: string = ""
  action: number = UIAction.Add
  saveBtnModel: UIControl = new UIControl(ProDataTypes.Button, 1, "Save")
  postUrl: String = ''
  postParams = {}
  hasPagination: boolean = false
  filterTerm: string = ''
  postParamFilterKey: string = ""
  searchType: number = 0
  hasCustomSelection: boolean = false
  defaultImgURL = 'assets/avatar.png'
  customItem: string = ''
  isCustomItemSelected: boolean = false
  viewIcon = ''
  viewModel = ''
  showBannerAd=false
  tempItems = []
  defaultLabel = ''
  constructor(
    private modalCtrl: ModalController,
    private alertHandler: AlertHandlerService,
    private __http: DataFetcherService,
    private modalController: ModalController,
    private helper:AppHelper,
  ) { }

  ngOnInit() {
    this.tempItems = this.items
    if (this.typeOfSelect != "") {
      this.saveBtnModel =new UIControl(ProDataTypes.Button, 1, this.typeOfSelect)
    }

    if (this.postUrl.length > 0) {
      this.getItems()
    } else {
      this.setSelectedItems()
    }
    this.saveBtnModel.elementStyle = ProElementStyle.PrimaryGradiant;
    AdmobService.hideBanner()
    if(this.showBannerAd){
      AdmobService.showBanner(this.helper.getPlatformInstance())
    }

  }

  onFilterTermChange() {
    if (this.searchType == 1) {
      if (this.hasCustomSelection) {
        const filterPipe = new Ng2SearchPipe();
        const filteredArr = filterPipe.transform(this.items, this.filterTerm);
        if (filteredArr.length == 0) {
          this.customItem = this.filterTerm;
        } else {
          this.isCustomItemSelected = false
          this.customItem = ''
        }
      }
    } else if (this.searchType == 2) {
      this.postParams[this.postParamFilterKey] = this.filterTerm
      this.getItems()
    } else if(this.searchType==3){
      this.items = this.tempItems
      this.items = this.items.filter(i=>(i[this.titleKey].toLowerCase()).startsWith((this.filterTerm).toLowerCase()))
    }
  }

  async getItems(event = null) {
    if (event == null) {
      if (this.filterTerm.length == 0) {
        await this.alertHandler.presentLoading();
      }
      if (this.hasPagination) {
        this.postParams['PageNumber'] = 1
      }
    } else {
      this.postParams['PageNumber']++
    }
    this.__http.doPost(this.postUrl, this.postParams).subscribe(res => {
      const status = res['status'] || false
      if (status) {
        if (event == null) {
          if (this.filterTerm.length == 0) {
            console.log('hii')
            this.alertHandler.dismissLoading()
          }
          this.items = res['data']
        } else {
          this.items = [...this.items, ...res['data']]
          event.target.complete()
        }
        this.setSelectedItems()
      } else {
        this.alertHandler.presenToast(ApiService.ERROR_TOAST_MSG)
        if (event == null) {
          if (this.filterTerm.length == 0) {
            this.alertHandler.dismissLoading()
          }
          if (this.hasPagination) {
            this.postParams['PageNumber']--
          }
        } else {
          this.postParams['PageNumber']--
          event.target.complete()
        }
      }
    }, err => {
      this.alertHandler.presenToast(ApiService.ERROR_TOAST_MSG)
      if (event == null) {
        if (this.filterTerm.length == 0) {
          this.alertHandler.dismissLoading()
        }
        if (this.hasPagination) {
          this.postParams['PageNumber']--
        }
      } else {
        this.postParams['PageNumber']--
        event.target.complete()
      }
    })
  }

  setSelectedItems() {
    this.items.map(m => {
      var val = m[this.filterValKey] || m[this.customFilterValKey]
      var filterItems = this.selectedItems.filter(f => f[this.filterValKey] == val);
      m['isChecked'] = false
      if (filterItems.length > 0) {
        m['isChecked'] = true
      }
    })
  }
  onElementEventChange(event) {
    const tag = event['elementTag'] || 0
    if (tag == 1) {
      this.selectedItems = this.items.filter(f => f['isChecked']);
      if (this.defaultLabel.length==0 && this.selectedItems.length == 0 && (!this.hasCustomSelection || !this.isCustomItemSelected || this.customItem.length == 0)) {
        this.alertHandler.presentAlert("Please select At Least one record")
        return
      }
      var data = { "selectedItems": this.selectedItems, "tag": this.modalTag }
      if (this.hasCustomSelection && this.isCustomItemSelected && this.customItem.length > 0) {
        data["customSelection"] = this.customItem
      }
      this.helper.validateBannerAdsDisplay()
      this.modalCtrl.dismiss(data);
    }
  }
  onItemClick(item) {
    if((item || '').length==0){
      this.items.map((m) => {
          m['isChecked'] = false
      })
      return
    }
    if (item['isChecked']) {
      item['isChecked'] = false
    } else {
      item['isChecked'] = true
    }
    if (!this.isMutiSelect) {
      this.items.map((m) => {
        if (item[this.filterValKey] != m[this.filterValKey])
          m['isChecked'] = false
      })
    } else if (this.isMutiSelect && this.maxLimit > 0) {
      var selectedItems = this.items.filter(f => f['isChecked']);
      if (selectedItems.length > this.maxLimit) {
        if (item['isChecked']) item['isChecked'] = false
        this.alertHandler.presentAlert("You can't select more than " + this.maxLimit + " records");
        return
      }
    }
    if (this.action == UIAction.ItemClick && item['isChecked']) {
      this.alertHandler.confirmationAlert(this.confirmationMsg, () => {
      }, () => {
        item['isChecked'] = false
      });
    }
  }

  onCustomItemClick() {
    this.isCustomItemSelected = !this.isCustomItemSelected
  }

  dismiss() {
    this.selectedItems = [];
    this.helper.validateBannerAdsDisplay()
    this.modalCtrl.dismiss({});
  }

  onViewIconClick(obj) {
    if (this.viewModel == 'Agent') {
      this.presentModal(AgentProfilev2Page, {
        details_params: { AgentID: obj['AgentID'], PID: "" },
        isModel: true
      })
    }
  }

  async presentModal(Model, componentProps) {
    const modal = await this.modalController.create({
      component: Model,
      componentProps: componentProps
    });
    return await modal.present();
  }

  isItemsSelected(){
    return this.items.filter(f=>f['isChecked']).length>0
  }
}

