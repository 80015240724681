import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '../date.pipe';
import { ImageSanitizer } from '../iamge-sanitizer.pipe';
import { LangPipe } from 'src/app/services/middleware/lang.pipe';

@NgModule({
  declarations: [DatePipe,ImageSanitizer,LangPipe],
  imports: [
    CommonModule,
  ],
  exports:[
    DatePipe,
    LangPipe,
    ImageSanitizer
  ]
})
export class CustomPipeModule { }
