import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class DBHandlerService {
  constructor(private router: Router) { }  
  static addJsonToLocalDB(storage_key, data, cb) {
    const json_data = btoa(unescape(encodeURIComponent(JSON.stringify(data))))
    Storage.set({ key: storage_key, value: json_data }).then(result => {
      cb()
    }).catch(e => {
      cb(e)
    })
  }
  static addToLocalDB(storage_key, data, cb) {
    const enc_data = btoa(data)
    Storage.set({ key: storage_key, value: enc_data }).then(result => {
      cb()
    }).catch(e => {
      cb(e)
    })
  }
  static async getSyncDataFromStorage(keyStr){
    const value = await Storage.get({key:keyStr})
    const val = value['value'] || "en"
    if (val != null && val.length > 0) {
      return atob(val)
    }
    return val
  }
  static getDataFromStorage(storage_key, cb) {
    Storage.get({ key: storage_key }).then(result => {
      const val = result['value'] || ""
      if (val != null && val.length > 0) {
        cb(null,atob(val));
      } else {
        cb(null,"")
      }
    }).catch(e => {
      cb(e,null)
    })
  }
  static getJsonDataFromStorage(storage_key, cb) {    
    Storage.get({ key: storage_key }).then(result => {      
      const val = result['value'] || ""      
      if (val != null && val.length > 0) {
        cb(JSON.parse(decodeURIComponent(escape(window.atob(val)))));
      } else {
        cb({})
        //cb(Constants.isArrayReturnPages.includes(storage_key) ? [] : {});
      }
    }).catch(e => {
      cb({})
      //cb(Constants.isArrayReturnPages.includes(storage_key) ? [] : {});
    })
  }
  static removeDataFromStorage(storage_key, cb) {
    Storage.remove({ key: storage_key }).then(() => {
      cb()
    }).catch(e => {
      cb(e)
    })
  }
  static clearAllStroage(cb) {
    Storage.clear().then(() => {
      cb(null)
    }).catch(e => {
      cb(e)
    })
  }

  static doLogout(router,navController) {
    DBHandlerService.getDataFromStorage(ApiService.LBDB_PUSH_KEY, (push_err, push_token) => {
      this.clearAllStroage((err)=>{
        if(!push_err){
          DBHandlerService.addToLocalDB(ApiService.LBDB_PUSH_KEY,push_token,()=>{});
        }
        // window.location.href = Constants.LOGOUT_REDIRCT_LINK
          navController.navigateRoot(ApiService.LOGOUT_REDIRCT_LINK)
        // router.navigate([Constants.LOGOUT_REDIRCT_LINK],{});   
      })  
    })
      
  }

  static checkNotificationParamsOnData(data,cb){
    DBHandlerService.getJsonDataFromStorage(ApiService.NOTIFICATION_PARAMS, (notificationParams) => {
      var id = data[0].hasOwnProperty('ID') ? 1 : 2 
      switch (id) {
        case 1: 
          cb(data.filter(d=> d['ID'] == notificationParams['app_id'])[0] || {})
        case 2: 
          cb(data.filter(d=> d['property_id'] == notificationParams['property_id'])[0] || {})
      }
    });
  }
}