import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { DataFetcherService } from '../services/data-fetcher/data-fetcher.service';
import { ApiService } from '../services/api.service';
import { AlertHandlerService } from '../services/alert-handler.service';
import 'hammerjs';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { environment, cdn_portal } from '../../environments/environment';

@Component({
  selector: 'app-marketplace-media-popup',
  templateUrl: './marketplace-media-popup.page.html',
  styleUrls: ['./marketplace-media-popup.page.scss'],
})
export class MarketplaceMediaPopupPage implements OnInit {
  public appName = "PropertyMarket" ;
  ListingID=''
  ID=0
  PropertyID=0
  propertyImages=[];
  constructor(public activatedRoute: ActivatedRoute
    , private navParams:NavParams
    ,private constant: DataFetcherService
    ,private ref: ChangeDetectorRef
    ,private alertHandeler:AlertHandlerService
    ,private modalCtrl:ModalController
    ,private streamingMedia: StreamingMedia
    , private loadingController: LoadingController
    , private http: HttpClient
    ,private photoViewer:PhotoViewer) { 
    this.ListingID = this.navParams.get('ListingID');
    this.ID = this.navParams.get('ID');
    this.PropertyID = this.navParams.get('PropertyID');
    this.getPropertyImages()
    console.log(this.PropertyID,this.ID)
  }

  ngOnInit() {

  }

  getPropertyImages() {
    this.constant.doPost(ApiService.GET_PROPERTY_MARKET_IMAGES_BY_MEDIABYID, {"ID": this.ID}).subscribe((res) => {
      if (res.hasOwnProperty('status') && res['status'] == true) {
        this.propertyImages = res['data'];
        console.log(this.propertyImages)
        this.ref.detectChanges();
      } else {
        this.alertHandeler.presentAlert("Invalid Data From service.");
      }
    });
  }

  async deleteMerchantImage(imageID, publicId) {
    const loading = await this.loadingController.create({
      message: 'Deleting...',
    });
    await loading.present();
    this.constant.doPost(ApiService.DELETE_PROPERTY_MARKET_IMAGES, {"ID":imageID,"PublicID":publicId,"PropertyId":this.PropertyID}).pipe(finalize(() => { loading.dismiss(); })).subscribe((res) => {
      if (res.hasOwnProperty('status') && res['status'] == true) {
        this.closeBtnClick();
      } else {
        //this.errorMessage = res['msg'] || res['error'] || "Invalid data from server";
      }
    });
  }

  async deleteMedia(mediaId, mediaURL) {
    const loading = await this.loadingController.create({
      message: 'Deleting...',
    });
    await loading.present();
    let files = new Array();
    files.push(mediaURL);
    let params = new URLSearchParams();
    params.set("appName", this.appName);
    params.set("mediaID", mediaId);
    params.set("file", mediaURL);

    this.http.post(cdn_portal + "ifile2/api/File/Delete?"+params.toString(), null)
      .pipe(
          finalize(() => {
              loading.dismiss();
              // this.endTime = Date.now();
              // alert("Upload completed in: " + (this.endTime - this.startTime)/1000 + " seconds");
          })
      )
      .subscribe(res => {
        this.closeBtnClick();
      });
  }

  closeBtnClick(){
    const onClosedData: boolean = true;
    this.modalCtrl.dismiss();
  }

  showImg(url){
    this.photoViewer.show(url)
  }

  playPreview(event) {
    console.log(event.srcEvent.srcElement);
    if (event.srcEvent.srcElement.paused) {
      event.srcEvent.srcElement.play();
    }
    else {
      event.srcEvent.srcElement.pause();
    }

  }

  playVid(video) {
    let options: StreamingVideoOptions = {
      successCallback: () => { console.log('Video played') },
      errorCallback: (e) => { console.log('Error streaming') },
      //orientation: 'landscape',
      shouldAutoClose: true,
      controls: true
    };

    this.streamingMedia.playVideo(video, options); 
  }

}
