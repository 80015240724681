import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UiPopoverComponent } from './ui-popover.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
@NgModule({
  declarations: [UiPopoverComponent],
  imports: [
    CommonModule,
    IonicModule,
    Ng2SearchPipeModule
  ],
  exports:[UiPopoverComponent]
})
export class UIPopoverModule { }