import { Component, OnInit } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { NavParams } from '@ionic/angular';
import { ConnectivityService } from 'src/app/services/connectivity.service';

@Component({
  selector: 'app-internet-connection-modal',
  templateUrl: './internet-connection-modal.page.html',
  styleUrls: ['./internet-connection-modal.page.scss'],
})
export class InternetConnectionModalPage implements OnInit {


  constructor(private navParams:NavParams) { }

  ngOnInit() {
  }

  public onTryAgainClick(){
    this.navParams.get('RetryFun')();
  }

}
