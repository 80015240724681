import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.scss'],
})
export class ReviewRatingComponent implements OnInit {
   @Input() isCustom: boolean = false
   @Input() customInputData: any = {"class":"med_chip","rating_avg":0.0}
   /*****
    * small
    * medium
    * large
    *****/
  constructor() { }

  ngOnInit() {}

}
