import { ErrorHandler, Injectable, Injector } from '@angular/core';
// import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { Platform } from '@ionic/angular';
const LOGTAG = '[GlobalErrorHandlerService]';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector,private platform:Platform) {}

  public handleError(error: unknown): void {
    this.platform.ready().then(() => {
      if ((this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb")) {
        this.handle(error);
      }
    });
  }

  private async handle(error: unknown): Promise<void> {
    try {
      console.error(error);
      const message = this.getMessageFromUnknownError(error);
     // await FirebaseCrashlytics.addLogMessage({message:message})
    } catch (errorHandlerError) {
      //  await FirebaseCrashlytics.addLogMessage({message:errorHandlerError})
      console.error(`${LOGTAG} Internal exception:`, errorHandlerError);
    }
  }

  private getMessageFromUnknownError(error: unknown): string {
    let message = 'An unknown error has occurred.';
    if (error instanceof Object && 'rejection' in error) {
      error = (error as any).rejection;
    }
    if (error instanceof Error && error.message) {
      message = error.message;
    }
    return message;
  }
}