import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { UIAction } from '../enums';
import { UINewControl } from '../uicontrol';

@Component({
  selector: 'app-uiimg',
  templateUrl: './uiimg.component.html',
  styleUrls: ['./uiimg.component.scss'],
})
export class UiimgComponent implements OnInit {
  
  @Input('img_model') img_model: UINewControl;
  lang_code = "";
  @Input() isCustom: boolean = false
  @Input() customInputData: any = {"class":"","src":"",'width':25,'height':25}
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor(private router: Router,private navCtrl: NavController,private modalController:ModalController) {

  }
  ngOnInit() {}
  onClick(){
    let actionEvent = this.img_model['actionEvent'] || 0;
    let routePath=this.img_model['routePath'] || ''
    if(actionEvent == UIAction.Previous && routePath){
      this.navCtrl.navigateBack(routePath); // Previous URL
    }else if(actionEvent == UIAction.Previous){
      this.navCtrl.back() // Previous URL
    }else if(actionEvent == UIAction.Next && routePath){
      this.navCtrl.navigateForward(routePath); // next URL
    }else if(actionEvent == UIAction.Dismiss){
      this.modalController.dismiss()
    } else { // this is for Api Service call for Save 
      this.eventHandlerEmitter.emit(this.img_model);
    }
 }

 setMyStyles() {
  let styles = {
    'background-color': this.customInputData['background-color'] ? this.customInputData['background-color'] : 'transparent',
    'color': this.customInputData['color'] ? this.customInputData['color'] : '#000',
    'border-radius': this.customInputData['border-radius'] ? this.customInputData['border-radius'] : '',
    'margin-top': this.customInputData['margin-top'] ? this.customInputData['margin-top'] : ''
  };
  if(this.customInputData['width']){
    styles['width'] = this.customInputData['width']+"px"
  }
  if(this.customInputData['height']){
    styles['height'] = this.customInputData['height']+"px"
  }
  return styles;
}
}
