import { Injectable } from "@angular/core";
import { ApiManagerService } from "./api-manager.service";
import { ApiService } from "./api.service";
import { Storage } from '@capacitor/storage';
import { DBHandlerService } from "./db-handler.service";

@Injectable({
  providedIn: "root",
})
export class RefreshAuthService {

  constructor(
  ) {}

		generateInstantToken = async () => {
			try {
				const params = {"host":"","ip_address":"127.0.0.1"}
				const result = await ApiManagerService.doPost(ApiService.INSTANT_TOKEN,"",params)
				return result['token'] || ""
			} catch (error) {
				return ""
			}
		}
	generateToken = async () => {
		try {
			const login_data = await this.getLoginData()
			if (login_data['is_login'] && login_data['is_login'] == "1") {
				const token = await this.generateInstantToken()
				const post_params = { "access_code": login_data['access_code'], "user_id": login_data['user_id'], "app_id": ApiService.PROPPYAPP_APP_ID, "device_token": ApiService.PUSH_NOTIFICATION_TOKEN }
				const result = await ApiManagerService.doPost(ApiService.LOGIN_AC,token, post_params)
				const loginToken = result['token'] || ""
				DBHandlerService.addToLocalDB(ApiService.LBDB_TOKEN_KEY, loginToken, err => {})
				return loginToken
			}
		} catch (error) {
			return false
		}
	}
	getJwtToken = (token) => {
		try {
			var tokenSplit = token.split('.')
			var modifyToken = this.deleteCharAt(tokenSplit[1],0) + '.' + this.deleteCharAt(tokenSplit[2],1) + '.' + this.deleteCharAt(tokenSplit[0],2)				
			return modifyToken
		} catch (error) {
			return ""
		}
	}
  async getLoginData() {
    const logged_in_data = await Storage.get({ key: ApiService.LBDB_IS_LOGIN });
				return JSON.parse(atob(logged_in_data['value'] || '{}'))
  }
		deleteCharAt = (str,index) => {
			return str.substring(0, index) + str.substring(index + 1);
	}
}