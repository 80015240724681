import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-ui-side-fab',
  templateUrl: './ui-side-fab.component.html',
  styleUrls: ['./ui-side-fab.component.scss'],
})
export class UiSideFabComponent implements OnInit {
  @Input('side_fab_data') side_fab_data=[]
  @Output() eventHandlerEmitter = new EventEmitter();
  constructor(
    private navCtrl:NavController
  ) { }

  ngOnInit() {}
  
  onItemClick(item){
    this.eventHandlerEmitter.emit(item)
  }

}
