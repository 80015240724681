import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: "app-skeletons",
  templateUrl: "./skeletons.component.html",
  styleUrls: ["./skeletons.component.scss"],
})
export class SkeletonsComponent implements OnInit {
  @Input() display_type?: number = 1;
  @Input() display_count?: number = 1;
  @Input() type?: number = 0;
  @Input() sub_type?: number = 0;
  /**** DISPLAY TYPES
   * 1 - image With Bottom Text
   * 2 - Single Image Along Horizontal
   * 3 - Image With Right Text
   * 4 - IMAGE WITH HEADER TEXT
   * 5 - List Of item Vertically
   * 10 - Package Room, Merchant Details Photos Tab && Proppy Cares
   * 52 - Homes360 Property
   * 53 - Merchant details About Tab
   * 55 - Deals Preloved Inbox
   * 56 - Deals Preloved Tracker
   * 57 - Needs-Process-Tracker
   * 58 - Gems Transaction Details
   * 59 - Developer Profile
   * 60 - Developer Details
   * 61 - Needs History
   * 62 - Property Details
   */

  constructor() {}

  ngOnInit() {}
}
