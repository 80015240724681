import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IonInfiniteScroll, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { AlertHandlerService } from '../services/alert-handler.service';
import { ApiService } from '../services/api.service';
import { DataFetcherService } from '../services/data-fetcher/data-fetcher.service';
import { AppHelper } from '../services/middleware/app-helper';
import { DeeplinkHelperService } from '../services/middleware/deeplink-helper.service';
import { StorageService } from '../services/storage.service';
import { ProDataTypes, ProElementStyle, ProImageIcon, UIButtonStyle } from '../uicomponents/enums';
import { UIControl } from '../uicomponents/uicontrol';
import { Storage } from '@ionic/storage';
import * as svg from "save-svg-as-png";
import QRCode from 'easyqrcodejs';
import { ConstantsService } from '../services/constants.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import * as _ from 'underscore';
@Component({
  selector: 'app-agent-profilev2',
  templateUrl: './agent-profilev2.page.html',
  styleUrls: ['./agent-profilev2.page.scss'],
})
export class AgentProfilev2Page implements OnInit {
  forSaleBtn: UIControl;
  forRentBtn: UIControl;
  editbtn: UIControl;
  shareSvgQRCodeImg : UIControl;
  details_params = { AgentID: "", PID:""};
  property_params={
    ListingType:'Sale',
    AgentID:'',
    PageNumber:1,
    PageLimit:20
  }
 
  AgentDetails={}
  AgentProperties=[]
  isModel=false
  @ViewChild("qrcode1", { static: false }) qrcode: ElementRef;
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;
  constructor(
    private storage: StorageService,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private http: DataFetcherService,
    private alertHanderler: AlertHandlerService,
    private deeplink_helper: DeeplinkHelperService,
    private ref: ChangeDetectorRef,
    private socialSharing: SocialSharing,
    public modalCtrl: ModalController,
    public plt:Platform,
    public helper:AppHelper,

  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("AgentId")) {
        this.details_params.AgentID = params["AgentId"];
        this.property_params.AgentID = params["AgentId"];
        this.storage.getString('Proppy_UserID', pid => {
          this.details_params.PID = pid;
          this.getPropertyAgentDetails()
        })
        this.genQRCode()
      }
    });
  }

  ngOnInit() {
    if(this.isModel){
      this.property_params.AgentID=this.details_params.AgentID
      this.storage.getString('Proppy_UserID', pid => {
        this.details_params.PID = pid;
        this.getPropertyAgentDetails()
      })
      this.genQRCode()
    }
  }

  getPropertyAgentDetails() {
    this.http
      .doPost(ApiService.GET_AGENT_DETAILS, this.details_params)
      .subscribe((res) => {
        if (res.hasOwnProperty("status") && res.status) {
          this.AgentDetails=res['data']
          this.AgentDetails['FavInfo']=JSON.parse(this.AgentDetails['FavInfo']||'[{}]')[0]||{}
          this.onTabChange(this.property_params.ListingType || 'Sale')
        } else {
          this.alertHanderler.presenToast(ApiService.ERROR_TOAST_MSG)
        }
      });
  }

  
  onTabChange(val) {
    this.property_params.ListingType = val
    this.setUpView();
    this.getAgentProperties()
  }
  
  setUpView() {
    this.forSaleBtn = new UIControl(ProDataTypes.Button, 1, "For sale (" + (this.AgentDetails['SaleCount'] || 0) + ")");
    this.forSaleBtn.elementSize = 6;
    this.forSaleBtn.elementStyle =
      ProElementStyle[
      this.property_params.ListingType == "Sale" ? "TabIconBtn" : "InactiveTabIconBtn"
      ];
    this.forRentBtn = new UIControl(ProDataTypes.Button, 2, "For Rent(" + (this.AgentDetails['RentCount'] || 0) + ")");
    this.forRentBtn.elementSize = 6;
    this.forRentBtn.elementStyle =
      ProElementStyle[
      this.property_params.ListingType == "Rent" ? "TabIconBtn" : "InactiveTabIconBtn"
      ];
    this.editbtn = new UIControl(ProDataTypes.Button, 2, "Edit");
    this.editbtn.elementSize = 6;
    this.editbtn.imageIcon = ProImageIcon.plusCircle;
    this.editbtn.elementStyle = UIButtonStyle.Primary

    this.shareSvgQRCodeImg = new UIControl(ProDataTypes.Button,1,'share');
    this.shareSvgQRCodeImg.elementSize = 6;
    this.shareSvgQRCodeImg.elementStyle = ProElementStyle.PrimaryGradiantFull;
    this.shareSvgQRCodeImg.imageIcon  = ProImageIcon.Share

  }

  async getAgentProperties(event=null) {
      if(event==null){
        this.infiniteScroll.disabled=true;
        this.property_params.PageNumber=1;
        // await this.alertHanderler.presentLoading('Loading News...')
      } else {
        this.property_params.PageNumber++;
      }
      this.http.doPost(ApiService.GET_AGENT_PROPERTIES_V2,this.property_params).subscribe(res=>{
        if(res.hasOwnProperty('status') && res['status']==true){
          res['data'].map(obj=>{
            obj['images']=JSON.parse(obj['images']||'[]')||[]
          })
          if(event==null){
            this.AgentProperties=res['data']
            this.alertHanderler.dismissLoading()
            this.infiniteScroll.disabled=false
          } else {
            this.AgentProperties=[...this.AgentProperties,...res['data']]
            event.target.complete()
          }
          if (event != null && (res.data.length == 0 || res.data.length < this.property_params.PageLimit)) {
            event.target.disabled = true;
          }
        } else {
          if (event != null) {
            this.property_params.PageNumber--;
          } else {
            this.alertHanderler.dismissLoading()
          }
          this.alertHanderler.presenToast('Unable to process your request, Please try again.')
        }
      },err=>{
        if (event != null) {
          this.property_params.PageNumber--;
        } else {
          this.alertHanderler.dismissLoading()
        }
        this.alertHanderler.presenToast('Unable to process your request, Please try again.')
      })
  }

  async onChatClick() {
    const participants = { "UserID": this.AgentDetails['AgentID'], "Name": this.AgentDetails['Name'], "ProfilePicURL": this.AgentDetails['ImageUrl'] }
    var conversationData = {
      "GroupName": ("Agent : " + this.AgentDetails['DisplayName']),
      "GroupId": (this.AgentDetails['AgentID'] + this.details_params['PID']), "Participants": [participants]
    }
    await this.storage.setData("newConversation", conversationData);
    console.log(conversationData);
    this.navCtrl.navigateForward(['group-chat']);
  }

  goBack() {
    if(this.isModel){
      this.modalCtrl.dismiss()
    } else {
      this.navCtrl.back();
    }
  }
  
  onDetailsClick(ListingID){
    if(this.isModel){
      // this.alertHanderler.presenToast("Unable To Open It From Get An Agent,")
      return
    }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        ListingID: ListingID,
      },
    };
    this.navCtrl.navigateForward(["./tabs/property-details"], navigationExtras);
  }

  onEditClick() {
    // if(this.isModel){
    //   this.alertHanderler.presenToast("Unable To Open It From Get An Agent,")
    //   return
    // }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        AgentID: this.AgentDetails['AgentID'],
      },
    };
    this.navCtrl.navigateForward(["./tabs/update-agent"], navigationExtras);
  }
  onEndorsementclick(objectType) {
    // if(this.isModel){
    //   this.alertHanderler.presenToast("Unable To Open It From Get An Agent,")
    //   return
    // }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        ObjectID: this.AgentDetails['AgentID'],
        ObjectType: objectType,
      },
    };
    this.navCtrl.navigateForward(["tabs/add-endorsement"], navigationExtras);
  }

  mEndorseMe() {
    this.deeplink_helper.processShareLink('AddEndorsement', this.AgentDetails['AgentID'], (link) => {
      var sMessage = "Click to Endorse Me at ProppyApp. " + link;
      this.socialSharing.share(sMessage, sMessage, null, null);
    }, 'Agent/' + this.AgentDetails['AgentID'])
  }

  onFavoriteStarClick() {
        var params = { Favourite: 1, Type: 3 };
        if (this.AgentDetails['FavInfo'].hasOwnProperty("ID")) {
          params["Favourite"] = this.AgentDetails['FavInfo']["Favourite"] == 1 ? 0 : 1;
          params["ID"] = this.AgentDetails['FavInfo']["ID"];
        } else {
          params["ProppyUserID"] = this.details_params['PID'];
          params["ListingID"] = this.details_params.AgentID;
        }
        this.http
          .doPost(
            params.hasOwnProperty("ID")
              ? ApiService.UPDATE_PROPERTYMARKET_USER_FAVOURITE
              : ApiService.ADD_PROPERTYMARKET_USER_FAVOURITE,
            params
          )
          .subscribe((res) => {
            if (res.hasOwnProperty("status") && res["status"] == true) {
              if (!params.hasOwnProperty("ID")) {
                this.AgentDetails['FavInfo'] = res["insertedRow"];
              } else {
                this.AgentDetails['FavInfo']['Favourite'] = params["Favourite"];
              }
              this.ref.detectChanges();
            }
          });
  }


  genQRCode() {
    this.deeplink_helper.processShareLink('AgentProperty', this.details_params["AgentID"], (link) => {
      // Options
      var options = {
        width: 300,
        height: 300,
        drawer: "svg",
        binary: false,
        correctLevel: QRCode.CorrectLevel.H,
        text: link,
        colorDark: "#010600",
        dotScale: "0.8",
        logo: "/assets/icon/proppy_logo_forapp_TM.png",
        //logoBackgroundTransparent:true,
        PO: "#0320F7",
        timing_H: "#6274FB",
        timing_V: "#6274FB",
        AO: "#0320F7",
        quietZone: 10,
        quietZoneColor: "#FFFFFF",
      };

      // Create new QRCode Object
      new QRCode(this.qrcode.nativeElement, options);
      this.ref.detectChanges()
    })
  }

  shareSvgQRCodeImgData(type=1) {
    svg.svgAsPngUri(
      document.getElementById("qrcode1").getElementsByTagName("svg")[0],
      { excludeCss: true },
      (uri) => {
        this.deeplink_helper.processShareLink('AgentProperty', this.details_params['AgentID'], (link) => {
          var sMessage = "Click to see My Agent Profile at ProppyApp. " + link;
          this.helper.socialShare(sMessage,uri,type)
        })
      }
    );
  }
}