import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProButtonModule } from './uicomponents/pro-button/pro-button.module';
import { ProCustomModalModule } from './uicomponents/pro-custom-modal/pro-custom-modal.module';
import { ProCbDropdownModule } from './uicomponents/pro-cb-dropdown/pro-cb-dropdown.module';
import { UICheckboxModule } from './uicomponents/uicheckbox/uicheckbox.module';
import { UITextFieldModule } from './uicomponents/uitext-field/uitext-field.module';
import { UIRadioButtonModule } from './uicomponents/uiradio-button/uiradio-button.module';
import { ProSliderModule } from './uicomponents/pro-slider/pro-slider.module';
import { UIDropdownModule } from './uicomponents/uidropdown/uidropdown.module';

import { UILabelModule } from './uicomponents/uilabel/uilabel.module';
import { UIDatePickerModule } from './uicomponents/uidate-picker/uidate-picker.module';
import { UIImgModule } from './uicomponents/uiimg/uiimg.module';
import { DisplayQrPageModule } from './uicomponents/display-qr/display-qr.module';
import { UIChipModule } from './uicomponents/ui-chip/ui-chip.module';
import { UIPopoverModule } from './uicomponents/ui-popover/ui-popover.module';
import { UiadsDisplayModule } from './uicomponents/uiads-display/uiads-display.module';
import { ProgressTrackerModule } from './uicomponents/progress-tracker/progress-tracker.module';
import { UiSideFabModule } from './uicomponents/ui-side-fab/ui-side-fab.module';
import { UiPhPropertyModule } from './uicomponents/ui-ph-property/ui-ph-property.module';
import { ReviewRatingModule } from './uicomponents/review-rating/review-rating.module';
import { ReviewRatingDetailsModule } from './uicomponents/review-rating-details/review-rating-details.module';
import { ProductReviewRatingDetailsModule } from './uicomponents/product-review-rating-details/product-review-rating-details.module';
import { CustomPipeModule } from './uicomponents/utils/custom-pipe/custom-pipe.module';
import { ImageSanitizer } from './uicomponents/utils/iamge-sanitizer.pipe';
import { SeparatorModule } from './uicomponents/separator/separator.module';
import { AppRefresherModule } from './uicomponents/app-refresher/app-refresher.module';
import { SkeletonsModule } from './uicomponents/skeletons/skeletons.module';
import { UifollowModule } from './uicomponents/uifollow/uifollow.module';
import { UibookmarkModule } from './uicomponents/uibookmark/uibookmark.module';
import { UiLikesModule } from './uicomponents/ui-likes/ui-likes.module';
import { UiFavouritesModule } from './uicomponents/ui-favourites/ui-favourites.module';
// import { UicongratulationsPageModule } from './uicomponents/uicongratulations/uicongratulations.module';
@NgModule({
  imports: [
    CommonModule,
    ProButtonModule,
    ProCbDropdownModule,
    UICheckboxModule,
    UITextFieldModule,
    UIRadioButtonModule,
    ProCustomModalModule,
    UIDropdownModule,
    ProSliderModule,
    UILabelModule,
    UIDatePickerModule,
    UIImgModule,
    UIChipModule,
    UIPopoverModule,
    UiadsDisplayModule,
    ProgressTrackerModule,
    UiSideFabModule,
    UiPhPropertyModule,
    ReviewRatingModule,
    // UicongratulationsPageModule
    // DisplayQrPageModule
    ReviewRatingDetailsModule,
    ProductReviewRatingDetailsModule,
    CustomPipeModule,
    SeparatorModule,
    AppRefresherModule,
    SkeletonsModule,
    UifollowModule,
    UibookmarkModule,
    UiLikesModule,
    UiFavouritesModule
  ],
  exports: [
    ProButtonModule,
    ProCbDropdownModule,
    UICheckboxModule,
    UITextFieldModule,
    UIRadioButtonModule,
    ProCustomModalModule,
    ProSliderModule,
    UIDropdownModule,
    UILabelModule,
    UIDatePickerModule,
    UIImgModule,
    UIChipModule,    
    UIPopoverModule,
    UIChipModule,
    UiadsDisplayModule,
    ProgressTrackerModule,
    UiSideFabModule,
    UiPhPropertyModule,
    ReviewRatingModule,
    // UicongratulationsPageModule
    // DisplayQrPageModule
    ReviewRatingDetailsModule,
    ProductReviewRatingDetailsModule,
    CustomPipeModule,
    ImageSanitizer,
    SeparatorModule,
    AppRefresherModule,
    SkeletonsModule,
    UifollowModule,
    UibookmarkModule,
    UiLikesModule,
    UiFavouritesModule
  ]
})
export class UiSharedModule { }
