import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from "rxjs";
import { environment } from 'src/environments/environment';
import Talk from "talkjs";
import { StorageService } from '../services/storage.service';
import { ApiService } from '../services/api.service';


@Injectable({
  providedIn: 'root'
})
export class TalkService {
  constructor(private storage: StorageService) {
  }

  private currentUser: Talk.User;
  //role: applicationUser.role
  async createUser(applicationUser: any) {
    const talkUser = new Talk.User({
      id: applicationUser.Proppy_UserID,
      name: applicationUser.Proppy_Name,
      photoUrl: applicationUser.Proppy_Profile_Pic ? applicationUser.Proppy_Profile_Pic : "assets/avatar.png",
      role: "members",
      welcomeMessage: null
    });
    // console.log(talkUser, "talkUser creating user");
    // console.log(applicationUser.Proppy_UserID + ' - ' + applicationUser.Proppy_Profile_Pic);
    //?applicationUser.photoUrl:'assets/avatar.png'
    return talkUser;
  }

  async createCurrentSession(user) {
    await Talk.ready;
    //console.log(user);
    this.currentUser = await this.createUser(user);
    const session = new Talk.Session({
      appId: environment.talkjs.appId,
      me: this.currentUser
    });
   session.unreads.on("change", function (unreadConversations) {
    ApiService.BeepNotificationCount = unreadConversations.length
  });
    return session;
  }

  async getConversation(session: Talk.Session, chatWith, subject, subjectId) {

    //const conversation = await this.getOrCreateConversation(session, otherApplicationUser);
    const otherUser = await this.createUser(chatWith);
    const conversation = session.getOrCreateConversation(subjectId); // we should pass subject id here not tile
    conversation.setParticipant(this.currentUser);
    conversation.setParticipant(otherUser);
    conversation.subject = subject;
    return conversation;
  }

  // private async getOrCreateConversation(session: Talk.Session, otherApplicationUser: any) {
  //   const otherUser = await this.createUser(otherApplicationUser);
  //   const conversation = session.getOrCreateConversation(Talk.oneOnOneId(this.currentUser, otherUser));
  //   conversation.setParticipant(this.currentUser);
  //   conversation.setParticipant(otherUser);
  //   const popup = session.createPopup(conversation, { keepOpen: false });
  //   popup.mount({ show: false });

  //   const button = document.getElementById("btn-getInTouch");
  //   button.addEventListener("click", event => {
  //       event.preventDefault();
  //       popup.show();
  //   });
  //   return conversation;
  // }

  async createInbox(session: Talk.Session, chatWith, subject: string, subjectId: string) {
    const otherApplicationUser = chatWith;
    const conversation = await this.getConversation(session, otherApplicationUser, subject, subjectId);
    conversation.subject = subject;    
    // try {
    //   await session.registerDevice({ platform: "android", pushRegistrationId: this.pushNotificationService.androidToken });
    //  } catch (error) {
    //  console.log("Unable to fetch token",conversation);
    //  }
    return session.createInbox({
      selected: conversation,
      useBrowserHistory: false,
      chatTitleMode: "subject",
      chatSubtitleMode: "participants",
      feedConversationTitleMode: "subject",      
    });
  }

  async createChatBoxes(session: Talk.Session) {
    //registering for push

    return session.createInbox({
      useBrowserHistory: false,
      chatTitleMode: "subject",
      chatSubtitleMode: "participants",
      feedConversationTitleMode: "subject"
    });
  }

  async createPopup(session: Talk.Session) {
    //console.log('talk service',this.storage.helpdesk);
    const customerCare = await this.createUser(this.storage.helpdesk);
    const me = this.currentUser;
    const other = customerCare;
    const conversation = session.getOrCreateConversation(Talk.oneOnOneId(me, other));
    conversation.setParticipant(me);
    conversation.setParticipant(other);
    conversation.subject = "HELP DESK";
    // try {
    //  await session.registerDevice({ platform: "android", pushRegistrationId: this.pushNotificationService.androidToken });
    // } catch (error) {
    //   console.log("Unable to fetch token",error);
    // }
    return session.createPopup(conversation, {
      chatTitleMode: "subject",
      chatSubtitleMode: "participants",
    });
  }

  async createCustomChatbox(session: Talk.Session, conversationId, subject) {
    const conversation = session.getOrCreateConversation(conversationId); // we should pass subject id here not tile
    conversation.subject = subject;
    var chatbox = session.createChatbox({
      showChatHeader: false
    });
    chatbox.select(conversation)
    return chatbox
  }
  async createConversation(session: Talk.Session, user,conversationId, conversationName, participants) {
    const conversation = session.getOrCreateConversation(conversationId); // we should pass subject id here not tile
    conversation.subject = conversationName
    const me = await this.createUser(user);
    conversation.setParticipant(me);
    for (const op1 of participants) {
      const op = new Talk.User({id: op1['UserID'] || '',name: op1['Name'] || '',"role":"members",photoUrl:op1['ProfilePicURL'] || "assets/avatar.png"});
      conversation.setParticipant(op);
    }
    // conversation.setAttributes({
    //   photoUrl: "https://demo.talkjs.com/img/11.jpg",
    //   subject: conversationName
    // });
    // conversation.sendMessage("Welcome!!")
     var chatbox = session.createChatbox({
      showChatHeader: false
    });
    chatbox.select(conversation)
    return chatbox
  }
  async joinSuperChatConversaton(conversationId,userInfo) {
    // console.log(userInfo);return;
    const session = new Talk.Session({
      appId: environment.talkjs.appId,
      me: new Talk.User({
        id: userInfo['Proppy_UserID'],
        name: userInfo['Proppy_Name'],
        photoUrl: (userInfo['Proppy_Profile_Pic'] || "assets/avatar.png"),
      })
    });
    const conversation = session.getOrCreateConversation(conversationId);
    const chatbox = session.createChatbox({showChatHeader: false});
    chatbox.select(conversation, { asGuest: true });
    return chatbox
  }
}
