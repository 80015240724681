import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateHelper } from '../utils/date-helper.service';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-uicalendar',
  templateUrl: './uicalendar.page.html',
  styleUrls: ['./uicalendar.page.scss'],
  providers:[DatePipe]
})
export class UicalendarPage implements OnInit {
  week_days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
  time_hh = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
  time_mm = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "59"]
  calendar_dates = []
  selected_date = ""
  selectedMonth = DateHelper.getMonth()
  view_type = 0
  date_opts = { 'min_dt': "", 'max_dt': "" }
  sel_time: any = { "hh": "", "mm": "", "time": "" }
  month_toggle_tag = false; year_toggle_tag = false;
  cal_months = JSON.parse(JSON.stringify(ApiService.MONTH_NAMES))
  cal_years = [];
  constructor(private modelCtrl: ModalController) { }

  ngOnInit() {
    if (this.sel_time['time'] && this.sel_time['time'].length > 0 && this.sel_time['time'].includes(":")) {
      var time_split = this.sel_time['time'].split(":")
      this.sel_time['hh'] = time_split[0]
      this.sel_time['mm'] = time_split[1]
    } else if (this.selected_date && this.selected_date.length > 0) {
      var date_split = this.selected_date.split("-")
      this.selectedMonth['year'] = parseInt(date_split[0])
      var prevDate = DateHelper.getDate(1, (parseInt(date_split[1]) - 1), this.selectedMonth['year']);
      this.selectedMonth = DateHelper.getMonth(prevDate)
    }
    this.getCalendarYears()
    this.changeCalendarDates()
  }
  navigateToPreviousMonth() {
    var monthNum = this.selectedMonth['id']
    if (monthNum == 1) {
      monthNum = 12
      this.selectedMonth['year'] -= 1
    } else {
      monthNum -= 1
    }
    var prevDate = DateHelper.getDate(1, (monthNum - 1), this.selectedMonth['year']);
    this.selectedMonth = DateHelper.getMonth(prevDate)
    this.changeCalendarDates()
  }
  navigatToNextMonth() {
    var monthNum = this.selectedMonth['id']
    if (monthNum == 12) {
      monthNum = 1
      this.selectedMonth['year'] += 1
    } else {
      monthNum += 1
    }
    var nextDate = DateHelper.getDate(1, (monthNum - 1), this.selectedMonth['year']);
    this.selectedMonth = DateHelper.getMonth(nextDate)
    this.changeCalendarDates()
  }
  navigateToPreviousYear() {
    var monthNum = this.selectedMonth['id']
    var year = this.selectedMonth['year']
    year -= 1
    this.selectedMonth['year'] = year
    var prevDate = DateHelper.getDate(1, (monthNum - 1), this.selectedMonth['year']);
    this.selectedMonth = DateHelper.getMonth(prevDate)
    this.changeCalendarDates()
  }
  navigatToNextYear() {
    var monthNum = this.selectedMonth['id']
    var year = this.selectedMonth['year']
    year += 1
    this.selectedMonth['year'] = year
    var prevDate = DateHelper.getDate(1, (monthNum - 1), this.selectedMonth['year']);
    this.selectedMonth = DateHelper.getMonth(prevDate)
    this.changeCalendarDates()
  }
  changeCalendarDates() {
    // console.log(this.selectedMonth)
    var date_split = this.selected_date.split("-")
    this.selected_date = DateHelper.getYMD(new Date(this.selectedMonth['year'],parseInt(this.selectedMonth['id'] || '1')-1,parseInt(date_split[2] || '1')))
    this.selectedMonth['curr_date'] = this.selected_date
    this.calendar_dates = DateHelper.getWeekDates(this.selectedMonth['id'], this.selectedMonth['year'])
  }
  onClickDate(e, cdt: any) {
    e.stopPropagation()
    if (!this.checkDateOpts(cdt)) return
    this.modelCtrl.dismiss(cdt)
  }
  checkDateOpts(cdt) {
    if (cdt['month'] != this.selectedMonth['id']) return false
    var min_dt = this.date_opts['min_dt'] || ""
    var max_dt = this.date_opts['max_dt'] || ""
    var dt2 = new Date(cdt['unix_date_format'])
    if (min_dt.length > 0 && max_dt.length > 0) {
      if (new Date(min_dt) > dt2 && dt2 < new Date(max_dt)) {
        return false
      } else if (dt2 > new Date(max_dt)) {
        return false
      }
    } else if (min_dt.length > 0 && dt2 < new Date(min_dt)) {
      return false
    } else if (max_dt.length > 0 && dt2 > new Date(max_dt)) {
      return false
    }
    return true
  }
  hoursChange() {
    this.checkAndDismissTimePicker()
  }
  minsChange() {
    this.checkAndDismissTimePicker()
  }
  checkAndDismissTimePicker() {
    if (this.sel_time['hh'].length > 0 && this.sel_time['mm'].length > 0) {
      this.sel_time['mins'] = ((parseInt(this.sel_time['hh']) * 60) + parseInt(this.sel_time['mm']))
      this.sel_time['time'] = `${this.sel_time['hh']}:${this.sel_time['mm']}`
      this.modelCtrl.dismiss(this.sel_time)
    }
  }
  dismissModal() {
    this.modelCtrl.dismiss({})
  }
  gridClickEvent(event) {
    event.stopPropagation()
  }

  onSelectMonth(e, item) {
    e.preventDefault();
    e.stopPropagation()
    this.selectedMonth['id'] = item['id']
    this.selectedMonth['name'] = item['name']
    this.changeCalendarDates()
    this.month_toggle_tag = !this.month_toggle_tag
  }
  showMonths() {
    this.month_toggle_tag = !this.month_toggle_tag
    setTimeout(() => {
      document.getElementById("mnth_" + this.selectedMonth['id'] + "_div").scrollIntoView();
    }, 100);
  }
  showYears() {
    this.year_toggle_tag = !this.year_toggle_tag
    setTimeout(() => {
      document.getElementById(this.selectedMonth['year'] + "_div").scrollIntoView();
    }, 100);
  }
  onSelectYear(e, item) {
    e.preventDefault();
    e.stopPropagation()
    this.selectedMonth['year'] = item
    this.changeCalendarDates()
    this.year_toggle_tag = !this.year_toggle_tag
  }
  getCalendarYears() {
    var cur_date = new Date();
    var curr_year = cur_date.getFullYear()
    var min_year = (curr_year - 100);
    var max_year = (curr_year + 50)
    this.cal_years = []
    for (var index = min_year; index <= (max_year + 1); index++) {
      this.cal_years.push(index)
    }
  }
}