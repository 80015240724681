import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationExtras, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { UserData } from './providers/user-data';
import Parse from 'parse'
import { LanguageService } from './services/language.service';
import Talk from 'talkjs';
import { StorageService } from './services/storage.service';
import { TalkService } from './api/talk.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { NgZone } from '@angular/core';
import { PropertyDetailsPageModule } from './property-details/property-details.module';
import { PropertyDetailsPage } from './property-details/property-details.page';
import { Tab3Page } from './tab3/tab3.page';
import { RegistrationPageModule } from './registration/registration.module';
import { RegistrationPage } from './registration/registration.page';
import { FcmService } from './services/fcm.service';
import { RoutesService } from './services/routes.service';
import { ConnectivityService } from './services/connectivity.service';
import { PermissionService } from './services/permission.service';
import { ApiService } from './services/api.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { faq_portal } from 'src/environments/environment.prod';
import { filter, pairwise } from 'rxjs/operators';
import { DataFetcherService } from './services/data-fetcher/data-fetcher.service';
import { Device } from '@ionic-native/device/ngx';
import { AlertHandlerService } from './services/alert-handler.service';
import { HttpClient } from '@angular/common/http';
import { AppHelper } from './services/middleware/app-helper';
// import { AdMob } from '@capacitor-community/admob';
import { DeeplinkHelperService } from './services/middleware/deeplink-helper.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { c360_web_url, cdn_portal, deep_link_base_url, deep_link_hostname, environment, new_deep_link_base_url, payment_portal, proffis_deep_link_base_url } from 'src/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { AdmobService } from './services/middleware/admob-service';
import { PushNotifications } from '@capacitor/push-notifications';
import hamburger_menu from 'src/assets/lang/hamburger_menu.json';
import { DBHandlerService } from './services/db-handler.service';
import { UtilsConfig } from '@proppy/signature-lib';
import { UtilsConfig as sharedLibUtilConfig } from '@proppy/shared-lib';
import { Keyboard } from '@capacitor/keyboard';
import { UtilsConfig as proppyHomesUtils } from '@proppy/proppy-homes-pkg'
import { BumpPopoverComponent } from './bump-popover/bump-popover.component';
import { LoginConfig } from '@proppy/proppy-app-login-lib';

// import { ActionPerformed, PushNotificationSchema, PushNotifications, Token} from '@capacitor/push-notifications';
import { UtilsConfig as p360Config } from '@proppy/shared-lib'
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  hamburger_menu_json = JSON.parse(JSON.stringify(hamburger_menu))
  @ViewChild('mainContainer', { static: true }) mainContainer!: ElementRef;
  private popup: Talk.Popup;
  loggedIn = false;
  myProppyProfilePic = ''
  myDisplayName = ''
  getEmail = ''
  countModerator = 0;
  isModerator = false;
  isMarketPlaceOpen = false
  isDesktop = false;
  deepLinkRoutePaths = {
    'splash': 'splash',
    'property-details-by-id': '/property-details/:id',
    'property-agent-by-id': '/property-agent/:id',
    'merchantdetails-by-id': '/merchantdetails/:id',
    'memberprofile-by-id': '/memberprofile/:id',
    'proppy-academy-details-by-id': '/proppy-academy-details/:id',
    'invitation': '/invitation',
    'registration-by-referral': '/registration/:id?selected_country_id=127',
    'endorsement-objecttype-objectid': '/add-endorsement/:id1/:id2',
    'proppycares-claim-merchantid-transferuserid': '/proppycares-claim/:id1/:id2',
    'proppycares-listing-get-merchant': '/proppycares-listing/:id',
    'c360-sosdisplay-objectid': '/c360/c360-sosdisplay/:id',
    'c360-supportdisplay-objectid': '/c360/c360-supportdisplay/:id',
    'c360-suppliesdisplay-objectid': '/c360/c360-suppliesdisplay/:id',
    'c360-vaccinedisplay-objectid': '/c360/c360-vaccinedisplay/:id',
    'proppycare-needs-confirm-order-orderid': '/proppycare-needs-confirm-order/:id'
  }
  apiService=ApiService
  proppyUserId:string = ''
  bump:any = {
    isBumpPopover: false,
    bumpStatus: 0
  }
  postParams: any = {
    latitude: 0,
    longitude: 0,
  }
  area : string = ''
  state: string = ''

  private previousScrollTop = 0;
  // private scrollEventListeners: Function[] = [];
  constructor(
    public helper: AppHelper,
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private languageService: LanguageService,
    private storageService: StorageService,
    private geolocation: Geolocation,
    private talkService: TalkService,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    public alertCtrl: AlertController,
    public navController: NavController,
    private fcmService: FcmService,
    public connectivity: ConnectivityService,
    private permissionService: PermissionService,
    private iab: InAppBrowser,
    private httpService: DataFetcherService,
    private device: Device,
    private alertHandeler: AlertHandlerService,
    private http: HttpClient,
    private deeplink_helper: DeeplinkHelperService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private modal_ctrl: ModalController,
    private ref: ChangeDetectorRef
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('AccessID')
        && this.helper.isDesktop()
      ) {
        console.log('deeplink')
        this.deeplink_helper.onAccessCapture(params['AccessID'], true)
      }
    })
    ApiService.IS_SLOW_INTERNET = false
    this.connectivity.initialize();
    this.permissionService.initiate();
    this.helper.getCurrentCoordinates()
    p360Config.BASE_NODE_API_URL = ApiService.BASE_NODE_URL
    p360Config.BASE_P360_NODE_API_URL = ApiService.BASE_P360_NODE_API_URL
    p360Config.LS_TOKEN_KEY = ApiService.LBDB_TOKEN_KEY
    p360Config.LS_APP_DATA = ApiService.LBDB_KEY
    p360Config.LS_LANG_CODE = ApiService.LBDB_LANG_CODE
    p360Config.DEFAULT_LANG_CODE = ApiService.DEFAULT_LANG_CODE
    p360Config.LS_NOTIFICATION_PARAMS = ApiService.NOTIFICATION_PARAMS
    p360Config.LBDB_BEEP_CHAT_NEW = 'newConversation'
    p360Config.HOMES_NODE_URL=this.apiService.HOMES_NODE_URL
    // this.fcmService.initializeFcm();
    UtilsConfig.BASE_NODE_URL = ApiService.BASE_NODE_URL
    UtilsConfig.PROPPY_BASE_PATH = ApiService.base_path
    UtilsConfig.BASE_API_URL = ApiService.PROFFICE_API_URL
    UtilsConfig.ANDROID_GOOGLE_MAP_KEY = ApiService.ANDROID_GOOGLE_MAP_KEY
    UtilsConfig.IOS_GOOGLE_MAP_KEY = ApiService.IOS_GOOGLE_MAP_KEY

    proppyHomesUtils.baseNodeApiUrl = ApiService.BASE_NODE_URL
    proppyHomesUtils.baseDotNetApiUrl = ApiService.base_path
    proppyHomesUtils.baseHomesNodeUrl = ApiService.BASE_HOMES_NODE_URL
    proppyHomesUtils.baseCdnPortalUrl = cdn_portal
    proppyHomesUtils.baseNodeBillingApiUrl = ApiService.BILLING_BASE_NODE_URL
    proppyHomesUtils.baseSharedNodeUrl = ApiService.SHARED_NODE_URL
    proppyHomesUtils.talkJsAppId = environment.talkjs.appId
    proppyHomesUtils.talkJsBearerApiKey = environment.talk_js_bearer_api_key
    proppyHomesUtils.basePythonApiUrl = ApiService.basePythonApiUrl
    proppyHomesUtils.fdBaseUrl = ApiService.FD_BASE_URL
    proppyHomesUtils.new_deep_link_base_url = new_deep_link_base_url;
    proppyHomesUtils.proffis_deep_link_base_url = proffis_deep_link_base_url;
    proppyHomesUtils.LBDB_TOKEN_KEY = ApiService.LBDB_TOKEN_KEY
    proppyHomesUtils.IS_SKIP_LOGIN = ApiService.IS_SKIP_LOGIN;
    
    this.setSharedLibConstUrls();
    this.fcmService.setUpRemoteNotifications();
    this.statusBar.overlaysWebView(false);
    this.statusBar.backgroundColorByHexString('#2f8dcd');
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.router.url.includes('tabs/tab1') || this.router.url.includes('/login')) {
        navigator['app'].exitApp();
      } else if (this.router.url.includes(RoutesService.c360NavigatableURL(RoutesService.c360Default)) || this.router.url.includes(RoutesService.c360NavigatableURL(RoutesService.c360Login)) || this.router.url.includes('thankyou-screen')) {
        this.router.url.includes('tabs/tab1')
      } else if (!this.router.url.includes('map-marker-selection')) {
        //AdmobService.hideBanner()
        this.navController.back();
      }

    });
    this.initializeApp();
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe(async (events: RoutesRecognized[]) => {
        // this.setScrollListener()
        ApiService.deeplinkCallBack = null
        ApiService.clearGlobalSearchData = null
        if ((events[0].urlAfterRedirects || '').startsWith('/login') || (events[0].urlAfterRedirects || '').startsWith('/registration')) {
          this.myProppyProfilePic = await this.storage.get("Proppy_Profile_Pic");
          this.myDisplayName = await this.storage.get("Proppy_Name");
          this.getEmail = await this.storage.get('Proppy_Email');
          if (this.getEmail != undefined && ApiService.ENABLE_FD) {
            this.checkFreshDeskContact(this.getEmail, this.myDisplayName)
          }
          this.getIsProppyModerator()
          this.ref.detectChanges()
        }
        if ((events[1].urlAfterRedirects || '').startsWith('/login') || (events[1].urlAfterRedirects || '').startsWith('/registration')) {
          this.myProppyProfilePic = '';
          this.myDisplayName = '';
          this.getEmail = '';
          this.isModerator = false;
        }
      });
    this.storageService.getString(ApiService.DB_LNG_KEY, (lang_code) => {
      ApiService.SELECTED_LANGUAGE_CODE = lang_code || ApiService.DEFAULT_LANG_CODE
    })
    this.initializeAdmob()
    this.setCordinatesAndGetData();
    // this.setScrollListener()
  }

  ionViewDidEnter() {
    // setTimeout(() => {
    //   this.connectivity.initialize();
    //   this.permissionService.initiate();
    // }, 2000);
  }
  ngOnInit() {
    // if ((this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb")) {
    //   alert('Mobile Device Detected');
    //   this.setUpRemoteNotifications()
    // }
  }
  async initializeApp() {
    this.myProppyProfilePic = await this.storage.get("Proppy_Profile_Pic");
    this.myDisplayName = await this.storage.get("Proppy_Name");
    this.getEmail = await this.storage.get('Proppy_Email');
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // SplashScreen.hide();
      this.isDesktop = this.platform.is("desktop")
      this.languageService.setInitialAppLanguage();
      this.getIsProppyModerator();
      //this.router.navigateByUrl('/tabs/tab1');
      //Parse auth
      //Parse.initialize("vlNZNla41Z9PDisD6lkDGPxiYzQQSxngTzNF45E4", "MhxlpbMZlyy83RKJt6lfczZFCfsuo6Px8hTl54jk");
      //Parse.initialize("YCAM0Iz2pJzDnmeTtTNgHjDjzjNQo8u9CQpBebRF", "oqPWAha1NdyH8gR6BbuqGW3j2zcaxtwtnJVjH7or");
      //Parse.serverURL = 'https://parseapi.back4app.com/';

      //let install = new Parse.Installation();
      //install.set("deviceType", this.platform.platforms().toString());

      // install.save(null, {
      //   success: (install) => {
      //     // Execute any logic that should take place after the object is saved.
      //     console.log('New object created with objectId: ' + install.id);
      //   },
      //   error: (install, error) => {
      //     // Execute any logic that should take place if the save fails.
      //     // error is a Parse.Error with an error code and message.
      //     console.log('Failed to create new object, with error code:' + error.message.toString());
      //   }
      // });
      //parse auth
      if (!this.helper.isDesktop()) {
        // this.setupDeeplinks();
        this.initializeCapDeepLink()
      }

    });





    // //binding event for login
    // this.storageService.handleMyEvents(async ()=>{
    //   const user=await this.storage.get("user");
    //   const helpDesk={

    //       // id: applicationUser.Proppy_UserID,
    //       //name: applicationUser.Proppy_Email,
    //       Proppy_UserID:"CC_1",
    //       Proppy_FirstName:"HelpDesk 1"

    //   };
    //   this.storageService.helpdesk=helpDesk;
    // //const session = await this.talkService.createCurrentSession(user);//Auth USer
    // //this.popup=await this.talkService.createPopup(session);
    // //this.popup.mount(this.mainContainer.nativeElement);
    // //this.popup.show();//if u want to disable
    // },"login");
  }

  setupDeeplinks() {
    this.deeplinks.route(this.deepLinkRoutePaths).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data

      //console.log('Successfully matched route', JSON.stringify(match));
      //alert("match:"+JSON.stringify(match));

      // Create our internal Router path by hand
      // this.internalPath = `${match.$route}`;
      // if (match.$args.id) {
      //   alert(match.$args.id);
      //   this.internalPath = this.internalPath + `/${match.$args.id}`;
      // } 

      //this.navController.navigateRoot(this.internalPath);
      // Run the navigation in the Angular zone
      this.zone.run(async () => {
        // console.log(this.router.url)
        // if(this.router.url=='/' || this.router.url.includes('/login')) {
        //   await this.storageService.setString('route_link',match.$link.path)
        // }
        var queryString = match.$link['queryString'] || match.$link['path']
        if (queryString.includes('AccessID')) {
          var AccessID = queryString.split('=')[1] || ''
          AccessID = AccessID.split('&')[0]
          if (AccessID) {
            this.deeplink_helper.onAccessCapture(AccessID)
          }
        } else {
          this.storageService.getString("Proppy_UserID", async (proppyID) => {
            if (proppyID == '') {
              await this.storageService.setString('route_link', match.$link.path)
              this.router.navigate(['./login'])
            } else
            // if(this.router.url.length>1 && !this.router.url.includes('/login')) 
            {
              this.router.navigateByUrl(match.$link.path);
            }
          });
        }

      });
    },
      nomatch => {
        // nomatch.$link - the full link data  
        // this.zone.run(async () => {
        var queryString = nomatch.$link['queryString'] || nomatch.$link['path']
        // console.log(nomatch)
        if (queryString.includes('AccessID')) {
          var AccessID = queryString.split('=')[1] || ''
          AccessID = AccessID.split('&')[0]
          if (AccessID) {
            this.deeplink_helper.onAccessCapture(AccessID)
          }
        } else if (nomatch.$link['path']) {
          var filterPaths = Object.values(this.deepLinkRoutePaths).filter(f => f.includes(queryString.split("/")[1]))
          if (queryString && filterPaths.length > 0) {
            this.storageService.getString("Proppy_UserID", async (proppyID) => {
              if (proppyID == '') {
                await this.storageService.setString('route_link', nomatch.$link.path)
                this.router.navigate(['./login'])
              } else
              // if(this.router.url.length>1 && !this.router.url.includes('/login')) 
              {
                this.router.navigateByUrl(nomatch.$link.path);
              }
            });
          }
        }
        // })
        // if (nomatch == "cordova_not_available") {          
        //   console.error("Got a deeplink that didn't match:" + JSON.stringify(nomatch), nomatch);
        //   return true;
        // }
        // //alert("nomatch:"+JSON.stringify(nomatch));
        // console.error("Got a deeplink that didn't match:" + JSON.stringify(nomatch), nomatch);
        this.setupDeeplinks();
        // return true;        
      }
    );
  }

  initializeCapDeepLink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        SplashScreen.hide();
        var url = event.url + '';
        var routePath = ''
        if (url.includes(".my")) {
          routePath = url.split(".my")[1];
        } else if (url.includes(".biz")) {
          routePath = url.split(".biz")[1];
        } else if (url.includes(".life")) {
          routePath = url.split(".life")[1];
        } else if (url.includes(".com")) {
          routePath = url.split(".com")[1];
        } else if (url.includes("proppyapp://")) {
          routePath = url.split("proppyapp://?")[1];
        }
        if (routePath.includes('AccessID')) {
          var AccessID = routePath.split('=')[1] || ''
          AccessID = AccessID.split('&')[0]
          this.openDeepLink(routePath, AccessID)
        } else {
          this.openDeepLink(routePath)
        }
        console.log(event, 'CapDeeplink')
      });
    });
  }

  async openDeepLink(path, AccessID = null) {
    console.log(path, AccessID)
    if (AccessID) {
      this.deeplink_helper.onAccessCapture(AccessID)
    } else {
      var filterPaths = Object.values(this.deepLinkRoutePaths).filter(f => f.includes(path.split("/")[1]))
      if (path && filterPaths.length > 0) {
        this.storageService.getString("Proppy_UserID", async (proppyID) => {
          if (proppyID == '') {
            await this.storageService.setString('route_link', path)
            this.router.navigate(['./login'])
          } else
          // if(this.router.url.length>1 && !this.router.url.includes('/login')) 
          {
            this.router.navigateByUrl(path);
          }
        });
      }
    }
  }

  // ngAfterViewInit(){
  //   this.deeplinks.route({
  //     //Todo: Add all the supported links
  //     '/:id': 'property-details' 
  //   }).subscribe(match => {
  //     // match.$route - the route we matched, which is the matched entry from the arguments to route()
  //     // match.$args - the args passed in the link
  //     // match.$link - the full link data
  //     console.log('Successfully matched route', match);
  //     alert('Successfully matched route ' + JSON.stringify(match))
  //     // Create our internal Router path by hand
  //     const internalPath = `/${match.$link}/${match.$args['id']}`;

  //     // Run the navigation in the Angular zone
  //     this.zone.run(() => {
  //       this.router.navigateByUrl(internalPath);
  //     });
  //   },
  //   nomatch => {
  //     // nomatch.$link - the full link data
  //     // console.error("Got a deeplink that didn't match:" + nomatch.$link, nomatch);
  //     alert("Got a deeplink that didn't match:" + JSON.stringify(nomatch))
  //   }
  // );
  // }

  // setupDeepLinks2(){
  //   this.deeplinks.routeWithNavController(this.navController, {
  //     '/tabs/tab3': Tab3Page,
  //     '/property-details?ListingID:ListingID': PropertyDetailsPage,
  //     '/registration': RegistrationPage
  //   }).subscribe(match => {
  //       // match.$route - the route we matched, which is the matched entry from the arguments to route()
  //       // match.$args - the args passed in the link
  //       // match.$link - the full link data
  //       console.log('Successfully matched route', match);
  //       alert("Got a deeplink that didn't match:" + JSON.stringify(match))
  //     }, nomatch => {
  //       // nomatch.$link - the full link data
  //       console.error('Got a deeplink that didn\'t match', nomatch);
  //       alert("Got a deeplink that didn't match:" + JSON.stringify(nomatch))
  //     });
  // }

  // setupDeeplinks() {
  //   this.deeplinks.route({
  //     //Todo: Add all the supported links
  //     '/:id': 'property-details' 
  //   }).subscribe(match => {
  //     // match.$route - the route we matched, which is the matched entry from the arguments to route()
  //     // match.$args - the args passed in the link
  //     // match.$link - the full link data
  //     console.log('Successfully matched route', match);
  //     alert('Successfully matched route ' + JSON.stringify(match))
  //     // Create our internal Router path by hand
  //     const internalPath = `/${match.$link}/${match.$args['id']}`;

  //     // Run the navigation in the Angular zone
  //     this.zone.run(() => {
  //       this.router.navigateByUrl(internalPath);
  //     });
  //   },
  //   nomatch => {
  //     // nomatch.$link - the full link data
  //     // console.error("Got a deeplink that didn't match:" + nomatch.$link, nomatch);
  //     alert("Got a deeplink that didn't match:" + JSON.stringify(nomatch))
  //   }
  // );
  // }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(async () => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        this.myProppyProfilePic = await this.storage.get("Proppy_Profile_Pic");
        this.myDisplayName = await this.storage.get("Proppy_Name");
        this.getEmail = await this.storage.get('Proppy_Email');
      } else {
        this.myProppyProfilePic = '';
        this.myDisplayName = '';
        this.getEmail = '';
      }
    }, 300);
  }



  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
      //this.showPopup();
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
      //this.showPopup();
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  async logout() {
    this.alertHandeler.presentConfirmationAlert('Are You Sure You Want To Logout?', async () => {
      // this.userData.logout().then(() => {
      //   return this.router.navigateByUrl('/tabs/tab1');
      // });
      // this.isLoggedIn = false
      await this.alertHandeler.presentLoading();
      // this.fbAuthService.logout(this.type);
      this.removeUserFCM();
      await this.storage.remove('username');
      await this.storage.remove('email');
      await this.storage.set('hasLoggedIn', false);

      this.storage.remove("Proppy_Name");
      this.storage.remove("Proppy_Extension");
      this.storage.remove("Proppy_Email");
      this.storage.remove("Proppy_Mobile");
      this.storage.remove("Proppy_Mobile");
      this.storage.remove("Proppy_UserID");
      this.storage.remove("Proppy_Agent_ID");
      this.storage.remove("Proppy_DeveloperID");
      this.storage.remove("Proppy_Profile_Pic");
      this.storage.remove("proppy_authtoken");
      this.storage.remove("IdentityType");
      this.storage.remove("IdentityHolderCardNo");
      this.storage.remove("IdentityHolderName");
      this.storage.remove("user");
      this.storage.remove("IsMOPFF");
      this.storage.remove("IsAmzRaceInv");
      this.storage.remove(ApiService.HOMES_WELCOME_POPUP_GLOBAL);
      this.storage.remove(ApiService.RESIDENTS_DATA);

      let isLoggedIn: boolean = await this.storage.get('hasLoggedIn');
      console.log(isLoggedIn)
      this.myProppyProfilePic = '';
      this.myDisplayName = '';
      this.getEmail = '';
      this.alertHandeler.dismissLoading();
      this.helper.UpdateLoginAccesstoken()
      //this.hideBanner()
    })
  }

  removeUserFCM() {
    this.storageService.getString(ApiService.FCM_TOKEN_KEY, token => {
      if (token.length > 0) {
        this.storageService.getString('Proppy_UserID', id => {
          this.httpService.doPost(ApiService.REMOVE_USER_FCMTOKEN, { UserID: id, FirebaseToken: token, DeviceUUID: this.device.uuid }).subscribe(res => {
            console.log(res.msg);
          })
        })
      }
    })
  }


  openTutorial() {
    //this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    //this.router.navigateByUrl('/tutorial');
  }

  setCordinatesAndGetData() {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.postParams.latitude = "" + resp.coords.latitude;
      this.postParams.longitude = "" + resp.coords.longitude;
      this.getAddress(parseFloat(this.postParams.latitude), parseFloat(this.postParams.longitude));
    }).catch((err) => {
      if (err['message'] == 'Illegal Access') {
            this.permissionService.showGPSModel()
        }
      });
  }

  async getAddress(lat: number, long: number, cb = () => { }) {
    let geocoder = await new google.maps.Geocoder();
    let latlng = await new google.maps.LatLng(lat, long);
    let request: google.maps.GeocoderRequest = { location: latlng };
    await geocoder.geocode(request, (results) => {
      if (results && results.length > 0) {
        let result = results[0];
        // this.selectedAddress = result.formatted_address
        var administrative_area_level_1 = result['address_components'].filter(f => f.types.includes('administrative_area_level_1'))
        var administrative_area_level_2 = result['address_components'].filter(f => f.types.includes('country'))
        var sublocality_level_1 = result['address_components'].filter(f => f['types'].includes('sublocality_level_1') ? f['types'].includes('sublocality_level_1') : f['types'].includes('locality'))
        this.area = sublocality_level_1[0].long_name
        this.state = administrative_area_level_1[0].long_name.includes('Kuala Lumpur') ? 'Kuala Lumpur' : administrative_area_level_1[0].long_name;
      cb()
      }
    });
  }

  async onSearchClickMarketPlace(landtype){
    var state = await this.storage.get('User_State') || 'Kuala Lumpur'
      await this.storageService.setData(ApiService.MARKETPLACE_FILTER_PARAMS, { land_type: landtype, latitude: this.postParams.latitude, longitude: this.postParams.longitude, state: state, property_type: '' })
      let navigationExtras: NavigationExtras = {
        queryParams: {
          replaceUrl: true,
          area: this.area,
          state: this.state,
          land_type: landtype
        }
      };
      this.navController.navigateForward([(this.helper.isDesktop()?'':'tabs/')+'own-property-list/coupons-property-listing'],navigationExtras);
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {        
    //     land_type: landtype
    //   },
    // };
    // this.geolocation.getCurrentPosition().then(async (resp) => {
    //   var state = await this.storage.get('User_State') || 'Kuala Lumpur'
    //   await this.storageService.setData(ApiService.MARKETPLACE_FILTER_PARAMS, { land_type: landtype, latitude: resp.coords.latitude, longitude: resp.coords.longitude, state: state, property_type: '' })
    //   let navigationExtras: NavigationExtras = {
    //     queryParams: {
    //       replaceUrl: true,
    //       area: this.area,
    //       state: this.state,
    //     }
    //   };
    //   this.navController.navigateForward([(this.helper.isDesktop()?'':'tabs/')+'own-property-list/coupons-property-listing'],navigationExtras);
    // }).catch((err) => {
    //   if (err['message'] == 'Illegal Access') {
    //     this.permissionService.showGPSModel()
    //   }
    // });
  }

  saleToletClick() {
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     type: 'Sale'
    //   }
    // };

    // this.router.navigate(['./tabs/marketplacenewlisting'], navigationExtras);
    this.router.navigate(['./tabs/add-property-to-market-terms-v2'])
  }

  async getIsProppyModerator() {
    this.storageService.getString("Proppy_UserID", async (proppyID) => {
      if (proppyID != '') {
        this.http.post(ApiService.PROPPY_NEWS_GET_MODERATORS_COUNT, { pid: proppyID }).subscribe(res => {
          console.log('proppy getProppyNewsCount')
          if (res.hasOwnProperty('status') && res['status'] == true) {
            this.countModerator = res['proppy_moderators_count'];
            if (this.countModerator > 0) {
              this.isModerator = true
            } else {
              this.isModerator = false
            }
            console.log(this.isModerator)
          }
        });
      }
    });
  }

  async btnFAQClick() {
    const name = await this.storage.get("Proppy_Name");
    const email = await this.storage.get('Proppy_Email');
    let fd_login_url = environment.fd_login_url
    const fd_url = `${fd_login_url}?email=${email}&name=${name}`
    this.iab.create(fd_url, '_blank');
  }
  async initializeAdmob(): Promise<void> {
    /* const { status } = await AdMob.trackingAuthorizationStatus();
   
     if (status === 'notDetermined') {
    
     }
    
     AdMob.initialize({
       requestTrackingAuthorization: true,
     });*/
  }
  /* setUpRemoteNotifications() {
     PushNotifications.requestPermissions().then(result => {
       if (result.receive === 'granted') {
         // Register with Apple / Google to receive push via APNS/FCM
         PushNotifications.register();
       } else {
         // Show some error
       }
     });
     PushNotifications.addListener('registration', (token: Token) => {
       //alert('Push registration success, token: ' + token.value);
       console.log('Push registration success, token: ' + token.value);
 
     });
 
     PushNotifications.addListener('registrationError', (error: any) => {
       //alert('Error on registration: ' + JSON.stringify(error));
       console.log('Error on registration: ' + JSON.stringify(error));
     });
 
     PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
       //alert('Push received: ' + JSON.stringify(notification));
       console.log('Push received: ' + JSON.stringify(notification));
     });
 
     PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
       //alert('Push action performed: ' + JSON.stringify(notification));
       console.log('Push action performed: ' + JSON.stringify(notification));
     });
   }*/

  navigateToC360Web() {
    window.open(c360_web_url)

  }
  // hideBanner() {
  //   AdmobService.hideBanner()
  // }

  onMenuOpen() {
    AdmobService.hideBanner()
    this.checkLoginStatus()
    this.storageService.getString('Proppy_UserID', (proppyUserId) => {
      this.proppyUserId = proppyUserId;
      this.getBumpDetails();
    })
  }

  onMenuClose() {
    this.helper.validateBannerAdsDisplay()
  }
  checkFreshDeskContact(email, name) {
    let url = ApiService.FD_SEARCH_CONTACT_URL
    url = url.replace('$$EMAIL$$', email)
    this.httpService.doGet(url).subscribe(res => {
      const result = res['results'] || []
      if (result.length == 0) {
        // console.log(email,name)
        this.createContact(email, name)
      }
    })
  }
  async createContact(email, name) {
    let pid = await this.storage.get("Proppy_UserID")
    const postParams = { "name": name, "email": email, "unique_external_id": pid }
    this.httpService.doPost(ApiService.FD_CREATE_CONTACT_URL, postParams).subscribe(res => {
      const fdUserId = res['id'] || 0
      if (fdUserId > 0) {
        console.log("Fresh Desk Account Created:", fdUserId)
      }
    })
  }

  setSharedLibConstUrls() {
    sharedLibUtilConfig.CDN_PORTEL = cdn_portal
    sharedLibUtilConfig.DEEP_LINK_BASE_URL = deep_link_base_url
    sharedLibUtilConfig.DEEP_LINK_HOSTNAME = deep_link_hostname
    sharedLibUtilConfig.PAYMENT_PORTEL = payment_portal
    sharedLibUtilConfig.NEW_DEEP_LINK_BASE_URL = new_deep_link_base_url
    sharedLibUtilConfig.PROFFIS_DEEP_LINK_BASE_URL = proffis_deep_link_base_url

    // DOT NET URL 
    sharedLibUtilConfig.PROPPY_BASE_PATH = ApiService.base_path

    // NODE URLS CHANGES 
    sharedLibUtilConfig.BASE_NODE_API_URL = ApiService.BASE_NODE_URL
    sharedLibUtilConfig.MARKET_360_NODE_URL = ApiService.MARKET_360_NODE_URL;
    sharedLibUtilConfig.WPE_NODE_URL = ApiService.WPE_NODE_URL;
    sharedLibUtilConfig.SHARED_NODE_URL = ApiService.SHARED_NODE_URL
    UtilsConfig.SIGNATRUE_API_URL = ApiService.SIGNATRUE_NODE_URL;
    UtilsConfig.LS_TOKEN_KEY = ApiService.LBDB_TOKEN_KEY;

    LoginConfig.AUTH_NODE_BASE_URL = ApiService.AUTH_NODE_URL;
    LoginConfig.NODE_BASE_URL = ApiService.BASE_NODE_URL;
    LoginConfig.LS_TOKEN_KEY = ApiService.LBDB_TOKEN_KEY;
    LoginConfig.APP_VERSION_NUM = environment.app_version;
    LoginConfig.LBDB_PUSH_KEY = ApiService.LBDB_PUSH_KEY;
    LoginConfig.SELECTED_LANGUAGE_CODE=ApiService.SELECTED_LANGUAGE_CODE;
  }
  setScrollListener() {
    setTimeout(() => {
      // if (this.scrollEventListeners?.length > 0) {
      //   this.scrollEventListeners.forEach(listener => listener());
      //   this.scrollEventListeners = []
      // }
      const tabs = document.querySelector('ion-tabs');
      console.log('scrollEvents Pre initialized', tabs);
      const outlet = tabs.querySelector('ion-router-outlet');
      console.log('scrollEvents Pre initialized', outlet);
      const pages = outlet.querySelectorAll('.ion-page')
      const tabBar = document.querySelector('ion-tab-bar');
      const iconProppy = document.querySelector('.icon-proppy');
      let content = null
      pages.forEach((v) => {
        if ((v.getAttribute('style') || '').includes('z-index: 101;')) {
          console.log(v);
          content = v.querySelector('ion-content') || v.querySelector('div');
          return;
        }
      })
      if (content) {
        this.renderer.setProperty(content, 'scrollEvents', true);
        console.log('scrollEvents initialized', content);

        const scrollListener = this.renderer.listen(content, 'ionScroll', (event) => {
          console.log('scrollEvents triggered');
          const currentScrollTop = event.detail.scrollTop;

          if (currentScrollTop > this.previousScrollTop) {
            // Scrolling down
            this.renderer.setStyle(tabBar, 'display', 'none');
            this.renderer.setStyle(iconProppy, 'display', 'none');
          } else {
            // Scrolling up
            this.renderer.setStyle(tabBar, 'display', 'flex');
            this.renderer.setStyle(iconProppy, 'display', 'flex');
          }

          this.previousScrollTop = currentScrollTop;
        });

        // Store the listener to remove it later
        // this.scrollEventListeners.push(scrollListener);
      }
    }, 1000);
  }

  async openBumpPopover(params:any) {
    const modal = await this.modal_ctrl.create({
      component: BumpPopoverComponent,
      cssClass: 'modal-transparency',
      componentProps: {},
    })
    await modal.present();
    await modal.onWillDismiss().then((result) => {
      let tmpData = result.data || {}
      if(tmpData.messageStatus == 0) {
        params.show_bump_message = tmpData.messageStatus
        this.bump.isBumpPopover = tmpData.messageStatus
      }
      if(tmpData.status == 2) this.onOrOffBump(params)
    }).catch((err) => {
      this.alertHandeler.presenToast(err.error || ApiService.ERROR_TOAST_MSG)
    });
  }

  async onBump() {
    if(!this.bump.bumpStatus) {
      this.geolocation.getCurrentPosition()
      .then((res:any) => {
        let data = res.coords
        let params = {
          pid: this.proppyUserId,
          bump_latitude: data.latitude,
          bump_longitude: data.longitude,
          bump_status: 1
        }
        if(this.bump.isBumpPopover) {
          this.openBumpPopover(params)
        }else {
          this.onOrOffBump(params)
        }
      })
    }else {
      let params = {
        pid: this.proppyUserId,
        bump_status: 0
      }
      this.onOrOffBump(params)
    }
  }

  onOrOffBump(params:any) {
    this.httpService.doPost(ApiService.ACTIVATE_BUMP,params).subscribe({
      next: (res:any) => {
        const status = res.status || false
        if(status) {
          this.bump.bumpStatus = params.bump_status 
        }else {
          this.alertHandeler.presenToast(res.error || ApiService.ERROR_TOAST_MSG)
        }
      },
      error: (err:any) => {
        this.alertHandeler.presenToast(err.error || ApiService.ERROR_TOAST_MSG)
      },
      complete: () => {}
    })
  }

  getBumpDetails() {
    this.httpService.doPost(ApiService.GET_USER_BUMP_DETAILS, {pid : this.proppyUserId}).subscribe({
      next: (res:any) => {
        let tmpData = res?.data?.['0'] || {}
        this.bump.bumpStatus = tmpData?.bump_status || 0
        this.bump.isBumpPopover = tmpData?.show_bump_message
      },
      error: (err:any) => {
        this.alertHandeler.presenToast(err.error || ApiService.ERROR_TOAST_MSG)
      },
      complete: () => {}
    })
  }

}
