import { Injectable } from "@angular/core";
/*import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  AdMobBannerSize,
  RewardAdPluginEvents,
  AdLoadInfo,
  AdMobRewardItem,
  RewardAdOptions,
} from "@capacitor-community/admob";*/
import { AppHelper } from "./app-helper";

@Injectable({
  providedIn: "root",
})
export class AdmobService {
  // static defaultBannerAdId='ca-app-pub-5474366311557908/2810395005'
  static adIds={
    banner_default:{
      android:'ca-app-pub-5474366311557908/2810395005',
      ios:'ca-app-pub-5474366311557908/9438698052'
    },
    rewarded_default:{
      android:'ca-app-pub-5474366311557908/3659494433',
      ios:'ca-app-pub-5474366311557908/9247126360'
    }
  }
  static advertisementRoutes=[
    'tabs/merchants',
    'tabs/proppy-gophermap-list',
    'tabs/propertylist',
    'tabs/developer-gallery/developer-listing',
  ]
  constructor() {}
  static async showBanner(plt,adName=''){
    /*var adId=this.getPltBasedAdID(adName || 'banner_default',plt)
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      var ad=document.getElementById('google_esf')
      document.getElementById('ad-div').appendChild(ad)
      console.log('ad-appended')
      // Subscribe Banner Event Listener
    // });
    // AdMob.addListener(
    //   BannerAdPluginEvents.SizeChanged,
    //   (size: AdMobBannerSize) => {
        // Subscribe Change Banner Size
      });
    AdMob.addListener(
      BannerAdPluginEvents.FailedToLoad,
      (info : any)=>{
        console.log(info)
      }
    )
    const options: BannerAdOptions = {
      adId: adId,
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 64,
      isTesting: true
      // npa: true
    };
    AdMob.showBanner(options);  */  
  }

  static async hideBanner() {
    // AdMob.removeBanner();
  }

  static async rewardVideo(plt,cb,adName=''){
   /* var rewarded=0
    var adId=this.getPltBasedAdID(adName || 'rewarded_default',plt)
    AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared rewardVideo
    });
  
    AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) => {
      // Subscribe user rewarded
      console.log(rewardItem);
      if(rewardItem['amount']>0 && rewarded==0){
        cb()
      }
      rewarded=1;
    });
  
    const options: RewardAdOptions = {
      adId: adId,
      isTesting: true
      // npa: true
      // ssv: {
      //   userId: "A user ID to send to your SSV"
      //   customData: JSON.stringify({ ...MyCustomData })
      //}
    };
    await AdMob.prepareRewardVideoAd(options);
    const rewardItem = await AdMob.showRewardVideoAd();
    console.log(rewardItem,);*/
    
  }
 
  static getPltBasedAdID(adName,plt){
   /* var adObj=this.adIds[adName] || {}
    if(plt.is('android')){
      return adObj['android'] || ''
    } else if (plt.is('ios')){
      return adObj['ios'] || ''
    }
    */
  }
  
  static advertisement(routingPath,plt){
   /* routingPath = routingPath.split('?')[0].replace("/", "");
    AppHelper.log(routingPath+'advertisement-routepath')
    var filteredArr = [];
    AppHelper.log(AdmobService.advertisementRoutes+'advertisement-advertisementRoutes')
    filteredArr = AdmobService.advertisementRoutes.filter(f=> (routingPath.includes(f)))
    AppHelper.log(filteredArr+'advertisement-filteredArr')
    if (filteredArr.length > 0){
      AdmobService.showBanner(plt);
    }*/
  }
}