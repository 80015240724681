import { Injectable } from "@angular/core";
import { PreloadAllModules, Route } from "@angular/router";
import { Observable, switchMap, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PreloadModulesService implements PreloadAllModules {
    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        return timer(10000).pipe(
            switchMap(() => fn())
          )
    }

}
