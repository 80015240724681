import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UidatePickerComponent } from './uidate-picker.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UicalendarPageModule } from '../uicalendar/uicalendar.module';
@NgModule({
  declarations: [UidatePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UicalendarPageModule
  ],
  exports:[UidatePickerComponent]
})
export class UIDatePickerModule { }
