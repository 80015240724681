import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertHandlerService } from 'src/app/services/alert-handler.service';
import { FormStyles, UIAction, UIDatatypes } from '../enums';
import { UINewControl } from '../uicontrol';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-uitext-field',
  templateUrl: './uitext-field.component.html',
  styleUrls: ['./uitext-field.component.scss'],
})
export class UITextFieldComponent implements OnInit {
  @Input('text_field_model') text_field_model: UINewControl;
  @Output() eventHandlerEmitter = new EventEmitter();
  @Input() isCustom: boolean = false
  @Input() customInputData: any = {"class":"","text":"",'color':'#000'}
  ui_data_styles = FormStyles;
  ui_actions=UIAction;
  secure_text=true;
  show=true;
  constructor(private alertHandler: AlertHandlerService) { }

  ngOnInit() { }

  onChangeEvent(e) {
    if(this.text_field_model.elementDataType!=UIDatatypes.currencyMaskTextField){
      this.text_field_model.elementSelectedVal = e.target.value
    }
    this.eventHandlerEmitter.emit(this.text_field_model);    
    if (this.text_field_model['elementInputType'] == 'number' || this.text_field_model['elementInputType'] == 'numeric') {
      var valid = this.numberOnlyValidation(e)
      if (!valid) {
        e.target.value = e.target.value.slice(0, -1)
        this.text_field_model.elementSelectedVal = e.target.value
        this.alertHandler.presenToast("Please Enter Numbers Only");
        return;
      }
    }
    if(this.text_field_model['elementPattern'] && this.text_field_model['elementPattern'].length>0){
      if (!ApiService[this.text_field_model['elementPattern']].test(e.target.value)) {
        e.preventDefault();
        this.text_field_model.elementSelectedVal = e.target.value.slice(0, -1)
        return this.alertHandler.presenToast(`${this.text_field_model['elementErrMsgs']['pattern_validation_msg']}`);
      }
      return true
    }
  }

  onIconClick(action){
    this.eventHandlerEmitter.emit({...this.text_field_model,actionEvent:action})
  }
  setMyStyles() {
    let styles = {
      'background-color': this.customInputData['background-color'] ? this.customInputData['background-color'] : 'transparent',
      'color': this.customInputData['color'] ? this.customInputData['color'] : '#000',
    };
    return styles;
  }

  numberOnlyValidation(event: any) {
    return !isNaN(event.target.value)
    var pattern;
    if (this.text_field_model['elementInputType'] == 'number') {
      pattern = /[0-9]/
    } else if (this.text_field_model['elementInputType'] == 'numeric') {
      pattern = /[0-9.]/
    }
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false
    }
    return true
  }
}
