import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiFetcherService {

  constructor(private httpClient:HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  doPost(url,params) {
    return this.httpClient.post<any>(
      url, params,this.httpOptions);
  }

  doGet(url) {
    return this.httpClient.get<any>(url);
  }

  doPut(url,params) {
    return this.httpClient.put<any>(url,params,this.httpOptions);
  }

  doDelete(url){
    return this.httpClient.delete<any>(url,this.httpOptions);
  }

  doGetWithOutHttpHeaders(url) {
    return this.httpClient.get<any>(url);
  }
}

