import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UIImgModule } from '../uiimg/uiimg.module';
import { UILabelModule } from '../uilabel/uilabel.module';
import { ProductReviewRatingDetailsComponent } from './product-review-rating-details.component';
import { ReviewRatingModule } from '../review-rating/review-rating.module';
import { CustomPipeModule } from '../utils/custom-pipe/custom-pipe.module';
import { SkeletonsModule } from '../skeletons/skeletons.module';



@NgModule({
  declarations: [ProductReviewRatingDetailsComponent,],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UIImgModule,
    UILabelModule,
    ReviewRatingModule,
    CustomPipeModule,
    SkeletonsModule
  ],
  exports: [
    ProductReviewRatingDetailsComponent
  ]
})
export class ProductReviewRatingDetailsModule { }
