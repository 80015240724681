import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePipe'
})
export class DatePipe implements PipeTransform {

  transform(dt: any, ...args: any[]): unknown {
    if (args.length > 1) {
      const dt2 = new Date(dt)
      if (args[0].length>0 && args[1].length>0){ //disable future dates
        // console.log(dt2,new Date(args[0]),new Date(args[1]),((dt2 > new Date(args[0]) && dt2 < new Date(args[1]))))
        if((dt2 > new Date(args[0]) && dt2 < new Date(args[1]))) return false
        return true
      }else if (args[0].length>0 && dt2 < new Date(args[0])) //disable future dates
        return true
      else if (args[1].length>0 && dt2 > new Date(args[1]) ) //disable past dates
        return true
    }
    return false
  }

}
