import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConnectivityService } from 'src/app/services/connectivity.service';

@Component({
  selector: 'app-refresher',
  templateUrl: './app-refresher.component.html',
  styleUrls: ['./app-refresher.component.scss'],
})
export class AppRefresher implements OnInit {
  @Output() appRefresh = new EventEmitter();
  constructor(public connectivity: ConnectivityService) { }

  ngOnInit() { }

  doRefresh(event) {
    this.connectivity.CheckConnectivitySpeed();
    this.appRefresh.emit(event);
  }
}
