import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UIControl, UINewControl } from '../uicontrol';

@Component({
  selector: 'app-uidropdown',
  templateUrl: './uidropdown.component.html',
  styleUrls: ['./uidropdown.component.scss'],
})
export class UIDropdownComponent implements OnInit,OnChanges{
  @Input('dd_model') dd_model: UINewControl;
  @Output() eventHandlerEmitter = new EventEmitter();
  lang_code = ""
  toggle_tag = false;
  filter_data_arr = [];
  filterTerm: any;
  dataArrKey = 'elementDataArr'
  constructor() { }

  ngOnInit() {
    console.log(this.dd_model)
  }
  ngOnChanges(){
    // console.log(this.dd_model,"00000000000")
  }

  onSelect(item) {
    this.toggle_tag = !this.toggle_tag
    this.dd_model.elementSelectedValues = [item]
    console.log(this.dd_model)
    this.eventHandlerEmitter.emit(this.dd_model);
  }

  hideDropDown() {
    this.filterTerm = "";
    this.toggle_tag = false
  }

  toggleDropDown() {
    if (this.dd_model.isEnabled)
      this.toggle_tag = !this.toggle_tag
  }

  getImageIcon() {
    if (this.dd_model.elementSelectedValues.length > 0) {
      const tmpObj = this.dd_model.elementSelectedValues[0]
      return tmpObj[this.dd_model.elementIcon] || ""
    }
    return ""
  }

  displayLangText() {
    const txt = this.dd_model.elementPlaceHolder;
    if (this.dd_model.elementSelectedValues.length > 0) {
      const tmpObj = this.dd_model.elementSelectedValues[0]
      return tmpObj[this.dd_model.elementDataKey]
    }
    return txt  // language[txt][this.lang_code]
  }

}
