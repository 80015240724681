import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Banner } from './banner';

@Component({
  selector: 'app-uiads-display',
  templateUrl: './uiads-display.component.html',
  styleUrls: ['./uiads-display.component.scss'],
})
export class UiadsDisplayComponent implements AfterViewInit {

  @Input() banner: Banner;
  showAd = true;
    constructor() {    }

    ngAfterViewInit() {
        setTimeout(() => {
            try {
                (window['adsbygoogle'] = window['adsbygoogle'] || []).push({
                    overlays: {bottom: true}
                });
            } catch (e) {
                console.error(e);
            }
        }, 0);
    }

}
